import React from "react"
import {Redirect, Route} from "react-router-dom"
import {useSelector} from "react-redux"
import {ErrorRoute, LoginRoute} from "./index"

import {getAuthorizedUserData} from "../utils/localstorage.utils"
import {isTrue, toCamelCase} from "../utils/other.utils"


const ProtectedRoute = ({render, permissions, settingsRequired, ...rest}) => {
    const {hasPermission} = useSelector(state => state.profile)
    const settings = useSelector(state => state.settings)
    const authorizedUser = getAuthorizedUserData()

    const permitted = (permissions?.length === 0) || (permissions && permissions.some(perm => hasPermission(perm)))

    const settingsEnabled = !settingsRequired || settingsRequired.every(setting => isTrue(settings[toCamelCase(setting)]?.value))

    return (
        <Route {...rest} render={props => {
            return (authorizedUser && permitted && settingsEnabled) ?
                render(props) :
                authorizedUser ?
                    settingsEnabled && !permitted ?
                        <Redirect to={{pathname: ErrorRoute.path, state: {errorInfo: "Not allowed"}}}/>
                        : <Redirect to={{pathname: ErrorRoute.path, state: {errorInfo: "Access to this feature is closed"}}}/>
                    : <Redirect to={{pathname: LoginRoute.path}}/>
        }}/>
    )
}

export default ProtectedRoute
