import {
    GET_ROLES_REQUEST,
    GET_ROLES_SUCCESS,
    GET_ROLES_FAILURE,
    GET_NEXT_PAGE_ROLES_REQUEST,
    GET_PERMISSIONS_REQUEST,
    GET_PERMISSIONS_SUCCESS,
    GET_PERMISSIONS_FAILURE,
} from "../const"


const initialState = {
    roles: [],
    permissions: [],
    total: 0,
    tableLoading: true,
    loading: true,
}

const handlers = {
    [GET_ROLES_REQUEST]: (state) => ({...state, loading: true, roles: [], total: 0}),
    [GET_ROLES_SUCCESS]: (state, {payload: {roles}}) => ({
        ...state,
        total: roles.total,
        roles: [...state.roles, ...roles.items],
        tableLoading: false,
        loading: false,
    }),
    [GET_ROLES_FAILURE]: (state) => ({...state, tableLoading: false, loading: false}),
    
    [GET_NEXT_PAGE_ROLES_REQUEST]: (state) => ({...state, tableLoading: true}),

    [GET_PERMISSIONS_REQUEST]: (state) => ({...state, permissions: []}),
    [GET_PERMISSIONS_SUCCESS]: (state, {payload: {permissions}}) => ({
        ...state,
        permissions: permissions,
    }),
    [GET_PERMISSIONS_FAILURE]: (state) => ({...state}),

    DEFAULT: (state) => state,
}


const rolesReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default rolesReducer
