import React, {useState} from "react"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import {USEFUL_INFO} from "../../../../constants/onboarding"
import {useMediaQuery} from "react-responsive"
import {DESKTOP_WIDTH} from "../../../../constants/other"
import Popup from "reactjs-popup"
import OnboardingPopup from "../../../../components/Greetings/Onboarding/OnboardingPopup"

const UsefulInfoCard = () => {
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})
    const [showUsefulInfoPopup, setShowUsefulInfoPopup] = useState(false)
    const [showUsefulInfo, setShowUsefulInfo] = useState(false)
    const [usefulInfoStep, setUsefulInfoStep] = useState(null)

    return (
        <>
            {isDesktop ?
                <div className="profile-right-useful-info">
                    <div onClick={() => setShowUsefulInfo(!showUsefulInfo)} className="profile-right-useful-info-title">
                        <span className="t-s6">Useful info</span>
                        <KeyboardArrowDownIcon
                            className={`arrow-down-icon ${showUsefulInfo ? "expanded" : ""}`}
                            onClick={() => setShowUsefulInfo(!showUsefulInfo)}
                        />
                    </div>
                    {showUsefulInfo &&
                        <div className="profile-right-useful-info-body">
                            {Object.entries(USEFUL_INFO).map(([key, {title, step}]) => (
                                <span onClick={() => {setShowUsefulInfoPopup(true); setUsefulInfoStep(step)} } key={key} className="t-b1">{title}</span>
                            ))}
                        </div>
                    }
                </div> : <></>
            }

            <Popup open={showUsefulInfoPopup && isDesktop} onClose={() => setShowUsefulInfoPopup(false)} modal>
                {close => (
                    <OnboardingPopup
                        close={close}
                        step={usefulInfoStep}
                    />
                )}
            </Popup>
        </>
    )
}

export default UsefulInfoCard