import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import Popup from "reactjs-popup"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"
import {useMediaQuery} from "react-responsive"

import {generateCancelToken} from "../../http"

import {receiveEquipment, receiveNextPageEquipment} from "./actions/equipmentActions"
import {receiveEquipmentCategories} from "../EquipmentCategories/actions/EquipmentCategoriesActions"

import {LeftCategories, RightCategories} from "./components/EquipmentCategories"
import EquipmentTable from "./components/EquipmentTable"
import CreateUpdateEquipmentPopup from "./components/CreateUpdateEquipmentPopup"
import Protected from "../../components/Protected/Protected"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {DESKTOP_WIDTH, PAGE_SIZE} from "../../constants/other"
import {ORDERING_KEYS} from "./const"
import {PERMISSIONS} from "../../constants/permissions"


const Equipment = () => {
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const dispatch = useDispatch()

    const {
        tableLoading,
        equipment,
        total,
    } = useSelector((state) => state.equipment)

    const [cancelToken, setCancelToken] = useState(null)

    const [currentPage, setCurrentPage] = useState(1)
    const [userMessage, setUserMessage] = useState(null)

    const [searchKey, setSearchKey] = useState()
    const [leftCategory, setLeftCategory] = useState({})
    const [rightCategory, setRightCategory] = useState({})
    const [ordering, setOrdering] = useState({
        key: ORDERING_KEYS.EQUIPMENT,
        asc: true,
    })
    const [addNewEquipmentPopup, setAddNewEquipmentPopup] = useState(false)

    const getParams = (page) => ({
        ...leftCategory,
        ...rightCategory,
        page: page || currentPage,
        equipment_name_user_name: searchKey,
        size: PAGE_SIZE,
        order_by: ordering.key,
        asc: ordering.asc,
    })

    const getEquipment = (page) => {
        page = page ? page : currentPage
        const params = getParams(page)

        dispatch(receiveEquipment(
            params,
            generateCancelToken(cancelToken, setCancelToken),
        ))
        setCurrentPage(page + 1)
    }

    const getNextEquipment = () => {
        const params = getParams()

        dispatch(receiveNextPageEquipment(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        dispatch(receiveEquipmentCategories())
    }, [])

    useEffect(() => { 
        getEquipment(1)
    }, [leftCategory, rightCategory, ordering])

    useEffect(() => {
        if (searchKey !== undefined) {
            getEquipment(1)
        }
    }, [searchKey])

    return (
        <>
            <Helmet>
                <title>Equipment - Newsoft Inside</title>
            </Helmet>

            <div className="equipment">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="equipment-header">
                    <div className="equipment-header-search">
                        <SearchForm onChange={setSearchKey} debounceMs={500} />
                        <Protected permissions={[PERMISSIONS.WRITE_EQUIPMENT]}>
                            <div className="equipment-header-search-button">
                                <SmallButton onClick={() => {setAddNewEquipmentPopup(true)}}> ADD NEW EQUIPMENT </SmallButton>
                            </div>
                        </Protected>
                    </div>
                    <div className="equipment-header-categories">
                        <LeftCategories setCategory={setLeftCategory}/>
                        <Protected permissions={[PERMISSIONS.READ_EQUIPMENT]}>
                            <RightCategories category={rightCategory} setCategory={setRightCategory}/>
                        </Protected>
                    </div>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={getNextEquipment}
                    hasMore={!tableLoading && equipment.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={equipment.length}
                    useWindow={false}
                >
                    {!isDesktop && tableLoading ? <StyledCircularProgress/> :
                        <div className="equipment-content">
                            <EquipmentTable
                                equipment={equipment}
                                ordering={ordering}
                                setOrdering={setOrdering}
                                tableLoading={tableLoading}
                                refreshTable={() => getEquipment(1)}
                                setUserMessage={setUserMessage}
                            />
                        </div>
                    }
                    {isDesktop && tableLoading &&
                        <div className="equipment-content-progress"><StyledCircularProgress/></div>
                    }
                </InfiniteScroll>
            </div>

            <Popup open={addNewEquipmentPopup} closeOnDocumentClick={false} onClose={() => setAddNewEquipmentPopup(false)} modal>
                {close => (
                    <CreateUpdateEquipmentPopup
                        refreshTable={() => getEquipment(1)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>

        </>)
}

export default Equipment
