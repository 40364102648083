import React, {useEffect, useState} from "react"

import queryString from "query-string"

import {resetPasswordByEmail} from "../api"

import AlertMessage from "../../../toolkits/AlertMessage/AlertMessage"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {getAuthorizedUserData} from "../../../utils/localstorage.utils"

import {ERROR_CODE} from "../../../constants/other"
import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {LoginRoute} from "../../../routes"
import {Link} from "react-router-dom"

export const ResetPasswordForm = () => {
    const history = useCustomHistory()

    const [email, setEmail] = useState()
    const [alertMessage, setAlertMessage] = useState(null)
    const [emailWasSent, setEmailWasSent] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const isUserAuthorized = getAuthorizedUserData()

    const handleSubmit = (e) => {
        e.preventDefault()
        e.target.disabled = true

        resetPasswordByEmail(email)
            .then(() => {
                setEmailWasSent(true)
            })
            .catch(() => {
                e.target.disabled = false
                setAlertMessage({message: "Incorrect email, please try again.", error: ERROR_CODE})
            })
    }

    useEffect(() => {
        const params = queryString.parse(history.location.search)
        
        if (params.email) setEmail(params.email)
    }, [])

    return (<>
        {!emailWasSent ? <div>
            <div className="auth-wrapper-login-title">Reset password</div>
            <div>
                {alertMessage && 
                    <AlertMessage setMessage={setAlertMessage} message={alertMessage?.message} code={alertMessage?.code}/> 
                }
            </div>
            <p className="auth-wrapper-reset-pass">Enter your email <br/> so we can send you a password reset
                link</p>
            <form className="auth-wrapper-login-form" id="login-form">
                <label className="auth-wrapper-login-form-email-label t-s3" htmlFor="email-field">Email address</label>
                <input
                    className="auth-wrapper-login-form-email-field"
                    id="email-field"
                    type="email"
                    placeholder="Email address"
                    defaultValue={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <div className="popup-bottom">
                    <div className="popup-bottom-button-container">
                        <SmallButton btnType="secondary" onClick={() => history.goBack()}>Back</SmallButton>
                        <SmallButton
                            type="submit"
                            loading={isLoading}
                            disabled={!email}
                            onClick={(e) => {
                                setIsLoading(true)
                                handleSubmit(e)
                            }}
                        >Send</SmallButton>
                    </div>
                </div>
                <div className="auth-wrapper-login-form-bottom">
                    <Link
                        to={LoginRoute.path}
                        className="auth-wrapper-login-form-bottom-return-login t-b1"
                    >Return to login</Link>
                </div>
            </form>
        </div> : <div>
            <div className="auth-wrapper-login-title">Reset password</div>
            <p className="auth-wrapper-reset-pass">
                A message with a password reset link has been sent.
                <br/>
                Please check your email
            </p>
            <div className="popup-bottom auth-wrapper-return-to-login">
                <SmallButton onClick={() => history.push(LoginRoute.path)}>
                    {`Return to ${isUserAuthorized ? "profile" : "login"}`}
                </SmallButton>
            </div>
        </div>}
    </>)
}
