import "./index.scss"
import UserImage from "../../assets/images/user_photo.png"

import React, {useEffect, useState} from "react"
import Helmet from "react-helmet"
import {useDispatch, useSelector} from "react-redux"

import {generateCancelToken} from "../../http"

import {
    receiveReportsSearch,
    setSelectedUser
} from "./actions/reportsDataActions"

import Protected from "../../components/Protected/Protected"
import InfoCards from "./components/InfoCards"
import ReportTable from "./components/ReportTable"
import TrackerChart from "./components/TrackerChart"

import {mapDateRangeFilters} from "../../utils/datetime.utils"

import {getSavedReportsRange, getUserViewedAs} from "../../utils/localstorage.utils"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import CustomLink from "../../toolkits/CustomLink"

import {useCustomHistory} from "../../hooks/useCustomHistory"
import {TimeRecordsRoute, ReportsRoute, PaymentReportsRoute} from "../../routes"
import {PERMISSIONS} from "../../constants/permissions"
import Popup from "reactjs-popup"
import DayTimeRecordsPopup from "./components/DayTimeRecordsPopup"
import {useMediaQuery} from "react-responsive"
import {DESKTOP_WIDTH, FILTER_TYPES} from "../../constants/other"
import useUserProjects from "../../hooks/useUserProjects"


const Reports = () => {    
    const history = useCustomHistory()
    const dispatch = useDispatch()

    const isMyReports = history.location?.pathname === ReportsRoute.path
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const [searchCancelToken, setSearchCancelToken] = useState(null)
    const [showDayTimeRecordsPopup, setShowDayTimeRecordsPopup] = useState(false)

    const { users, selectedUser } = useSelector(state => state.reportsPage)
    const auth = useSelector((state) => state.auth)
    const {hasPermission} = useSelector((state) => state.profile)
    const userId = getUserViewedAs() ? getUserViewedAs().user_id : (isMyReports ? auth.user.uid : selectedUser?.id)

    const { projects } = useUserProjects({ userId, all: true, skip: !(isMyReports || selectedUser)})

    const [selectedProjectIds, setSelectedProjectIds] = useState([])

    const savedReportsRange = getSavedReportsRange(!isMyReports)

    const [searchKey, setSearchKey] = useState(
        selectedUser ? `${selectedUser.first_name} ${selectedUser.last_name}` : ""
    )
    const [selectedSearchValue, setSelectedSearchValue] = useState(
        selectedUser ? `${selectedUser.first_name} ${selectedUser.last_name}` : null
    )
    const [isDisabledSearch, setIsDisabledSearch] = useState(!!selectedUser)
    const [date, setDate] = useState(Object.values(FILTER_TYPES).includes(savedReportsRange) ?
        mapDateRangeFilters(savedReportsRange) : mapDateRangeFilters(FILTER_TYPES.THIS_MONTH))
    const [chosenDate, setChosenDate] = useState(null)

    const redirectTimeRecords = () => history.push(TimeRecordsRoute.path, {
        userId: userId
    })

    useEffect(() => {
        if (!isMyReports) {
            setSelectedSearchValue(selectedUser ? `${selectedUser.first_name} ${selectedUser.last_name}` : null)
        }
    }, [selectedUser])

    
    useEffect(() => {
        if (!isMyReports) {
            dispatch(
                receiveReportsSearch(searchKey, generateCancelToken(searchCancelToken, setSearchCancelToken))
            )
        }
    }, [searchKey])


    const usersList = users.map(user =>
        <div onClick={() => {
            dispatch(setSelectedUser(user))
            setSelectedSearchValue(user.first_name + " " + user.last_name)
            setIsDisabledSearch(true)
            history.location.state.user = user
        }} className="reports-users-item" key={user.id}>
            <img alt="Profile picture" src={user.image_url || UserImage} />
            <span className="t-s3">{user.first_name} {user.last_name}</span>
        </div>)
    
    return (
        <div>
            <Helmet>
                <title>Reports - Newsoft Inside</title>
            </Helmet>
            <div className="reports">
                <div className={`reports-header${!isMyReports ? "-with-search" : ""}`}>
                    {(hasPermission(PERMISSIONS.READ_REPORTS) && !isMyReports) &&
                    <>
                        <SearchForm 
                            className="reports-search"
                            defaultValue={searchKey}
                            selectedValue={selectedSearchValue}
                            onChange={(value) => !isDisabledSearch && setSearchKey(value)}
                            onClick={() => {
                                setIsDisabledSearch(false)
                                dispatch(setSelectedUser(null))
                                history.location.state.user = null
                            }}
                            debounceMs={500}
                        />
                        {(!isDisabledSearch && searchKey && usersList.length > 0) &&
                            <div className="reports-users">
                                {usersList}
                            </div>
                        }
                    </>
                    }
                    {!isMyReports &&
                        <Protected permissions={[PERMISSIONS.READ_PAYMENT_REPORTS]}>
                            <CustomLink to={{
                                pathname: PaymentReportsRoute.path,
                                state: {
                                    userId: userId
                                }
                            }}>
                                <SmallButton>payment reports</SmallButton>
                            </CustomLink>
                        </Protected>
                    }
                </div>
                {(selectedUser || isMyReports) ?
                    <>
                        <TrackerChart 
                            date={date}
                            setDate={setDate}
                            defaultDate={date}
                            user={!isMyReports}
                            userId={userId}
                            projects={projects}
                            selectedProjectIds={selectedProjectIds}
                            setSelectedProjectIds={setSelectedProjectIds}
                            setShowDayTimeRecordsPopup={setShowDayTimeRecordsPopup}
                            setChosenDate={setChosenDate}
                        />
                        <div className="reports-bottom">
                            <ReportTable
                                userId={userId}
                                date={date}
                                projects={selectedProjectIds.length !== projects.length ? selectedProjectIds : undefined}
                                redirectTimeRecords={redirectTimeRecords}
                                isMyReports={isMyReports}
                            />
                            <InfoCards
                                date={date}
                                projects={selectedProjectIds.length !== projects.length ? selectedProjectIds : undefined}
                            />
                        </div>

                    </> : 
                    <div className="reports-user-not-selected">
                        <p className="t-h1">User not selected</p>
                    </div>
                }
            </div>
            <Popup open={showDayTimeRecordsPopup && isDesktop} onClose={() => setShowDayTimeRecordsPopup(false)} modal>
                {close => (
                    <DayTimeRecordsPopup
                        close={close}
                        userId={userId}
                        date={chosenDate}
                    />)
                }
            </Popup>
        </div>

    )
}
 
export default Reports
