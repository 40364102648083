import React from "react"
import {useDispatch} from "react-redux"

import {signOut} from "../actions/authActions"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {LoginRoute} from "../../../routes"
import {clearState} from "../../../layouts/Navs/actions/navigationActions"


export const LogoutPopup = ({close}) => {
    const history = useCustomHistory()
    const dispatch = useDispatch()

    const logOut = () => {
        dispatch(signOut())
        history.push(LoginRoute.path)
        dispatch(clearState())
    }

    return (
        <CustomPopup text_title={"Do you really want to logout?"} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}>Cancel</SmallButton>
                    <SmallButton onClick={logOut}>Logout</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}
