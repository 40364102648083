import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {receiveRoles} from "../../../Roles/actions/rolesActions"
import {updateUserRoles} from "../../api"

import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../../toolkits/SmallButton/SmallButton"
import DynamicBoxList from "../../../../toolkits/DynamicBoxList"

import {ERROR_CODE, SUCCESS_CODE} from "../../../../constants/other"


const UpdateRolesPopup = ({user, setUserMessage, receiveUserInfo, close}) => {
    const dispatch = useDispatch()

    const [rolesIds, setRolesIds] = useState(user?.roles.map((x) => x.id) || [])

    const {roles, loading} = useSelector((state) => state.roles)

    
    const handleSubmit = () => {
        const data = {
            role_ids: rolesIds,
        }

        updateUserRoles(user.id, data)
            .then(() => {
                receiveUserInfo()
                close()
                setUserMessage({message: "User Roles were successfully updated", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    useEffect(() => {
        dispatch(receiveRoles())
    }, [])

    return (
        <CustomPopup text_title="Edit Roles" className="roles-popup">
            <div className="popup-content">
                <DynamicBoxList
                    defaultValues={user?.roles.map((x) => x.id) || []}
                    items={roles}
                    loading={loading}
                    onChange={setRolesIds}
                    getContent={role => role.role_name}
                />
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit} disabled={!rolesIds.length}>SAVE</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default UpdateRolesPopup
