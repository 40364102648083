import {PERMISSIONS} from "../../../../constants/permissions"
import {SalaryHistoryRoute} from "../../../../routes"
import InfoIcon from "@mui/icons-material/Pending"
import {SensitiveInfo} from "../../../../components/SensitiveInfo/SensitiveInfo"
import {SALARY_TYPES} from "../../../../constants/other"
import {isNotZero} from "../../../../utils/other.utils"
import {EmptyCard} from "../../../../toolkits/EmptyResult/EmptyCard"
import React, {useContext} from "react"
import {useSelector} from "react-redux"
import {ThemeContext} from "../../../../styles/theme-context"
import {useCustomHistory} from "../../../../hooks/useCustomHistory"
import PenIcon from "@mui/icons-material/Create"

const SalaryCard = ({salary, user}) => {
    const {hasPermission} = useSelector((state) => state.profile)
    const history = useCustomHistory()
    const theme = useContext(ThemeContext)

    return (
        <>
            <div
                className={`profile-info-card ${hasPermission(PERMISSIONS.READ_OWN_SALARY) ? "clickable" : ""} ${theme.profileCardHover}`}
                onClick={
                    () => history.push(SalaryHistoryRoute.path, {user})
                }
            >
                <div>
                    {salary
                        ? <InfoIcon className="profile-info-card-improvements clickable"/>
                        : hasPermission(PERMISSIONS.READ_SALARY) && <PenIcon className="profile-info-card-pen-icon clickable"/>
                    }
                    <h3 className="t-h3 profile-font-leading">SALARY</h3>
                    {salary ?
                        <h1 className="t-h1">
                            <SensitiveInfo value={
                                <>
                                    {`${salary.amount} ${salary.currency.iso_code}`}
                                    {salary.salary_type === SALARY_TYPES.PER_HOUR &&
                                        <small className="per-hour-text t-h4"> {salary?.salary_type}</small>}
                                    {isNotZero(salary.active_overtime_coefficient) &&
                                        <span className="t-s3 text-top">{`x${salary.active_overtime_coefficient}`}</span>}
                                </>}>
                            </SensitiveInfo>
                        </h1> : <EmptyCard>No salary</EmptyCard>}
                </div>
            </div>
        </>
    )
}

export default SalaryCard