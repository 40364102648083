import React, {useState} from "react"

import {createPublicDayOff, updatePublicDayOffById} from "../api"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import TextInput from "../../../toolkits/TextInput/TextInput"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import DeprecatedSingleDatePicker from "../../../toolkits/Calendar/DeprecatedSingleDatePicker"


const CreatePublicDayOffPopup = ({publicDayOff, refreshTable, setUserMessage, close}) => {
    const [date, setDate] = useState(publicDayOff?.date)
    const [name, setName] = useState(publicDayOff?.name)

    const handleSubmit = () => {
        const formatedDate = date?.split(".").reverse().join("-")
        
        const data = {
            date: formatedDate,
            name: name,
        }
        if (!publicDayOff) {
            createPublicDayOff(data)
                .then(() => {
                    refreshTable(formatedDate)
                    close()
                    setUserMessage({message: "Public day off was successfully created!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                })
        } else {
            updatePublicDayOffById(publicDayOff.id, data)
                .then(() => {
                    refreshTable(formatedDate)
                    close()
                    setUserMessage({message: "Public day off was successfully updated!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                })
        }
        
    }

    return (
        <CustomPopup
            className="create-update-public-day-offs-popup"
            wrapperClass="create-update-public-day-offs-popup-wrapper"
            text_title={`${!publicDayOff ? "Create" : "Edit"} National Day Off`}
        >
            <div>
                <DeprecatedSingleDatePicker
                    label="Date"
                    className="single-datepicker-field"
                    defaultValue={date}
                    setNewDate={setDate}
                    withPublicDayOffs={true}
                />
                <TextInput
                    text_label="Name"
                    placeholder=""
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit} disabled={!date || !name}>{!publicDayOff ? "CREATE" : "SAVE"}</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default CreatePublicDayOffPopup
