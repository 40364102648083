import {getAllProducts} from "../api"
import {
    GET_NEXT_PAGE_PRODUCTS_REQUEST,
    GET_PRODUCTS_FAILURE,
    GET_PRODUCTS_REQUEST,
    GET_PRODUCTS_SUCCESS,
} from "../const"


const request = () => ({type: GET_NEXT_PAGE_PRODUCTS_REQUEST})

const success = (products) => ({
    type: GET_PRODUCTS_SUCCESS,
    payload: {products},
})

const failure = () => ({type: GET_PRODUCTS_FAILURE})

const searchRequest = () => ({type: GET_PRODUCTS_REQUEST})

export const receiveNextPageProducts = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveProductsData} = await getAllProducts(params)

        dispatch(success(receiveProductsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveProducts = (params) => async (dispatch) => {
    dispatch(searchRequest())
    try {
        const {data: receiveProductsData} = await getAllProducts(params)
        dispatch(success(receiveProductsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}