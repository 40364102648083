import {useHistory} from "react-router-dom"
import {ProfileRoute} from "../routes"

export const useCustomHistory = () => {
    const history = useHistory()

    return {
        ...history,
        push: (pathname, state, activeTab) => {
            state = state || {}
            state.activeTab = activeTab || history.location.state?.activeTab || ProfileRoute.path
            history.push(pathname, state)
        }
    }
}
