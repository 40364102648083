import {
    GET_NEWCOINS_BALANCES_REQUEST,
    GET_NEWCOINS_BALANCES_SUCCESS,
    GET_NEWCOINS_BALANCES_FAILURE,
    GET_NEXT_PAGE_NEWCOINS_BALANCES_REQUEST,
} from "../const"


const initialState = {
    newcoinsBalances: [],
    total: 0,
    tableLoading: true,
    loading: true,
}

const handlers = {
    [GET_NEXT_PAGE_NEWCOINS_BALANCES_REQUEST]: (state) => ({...state, tableLoading: true}),
    [GET_NEWCOINS_BALANCES_SUCCESS]: (state, {payload: {newcoinsBalances}}) => ({
        ...state,
        total: newcoinsBalances.total,
        newcoinsBalances: [...state.newcoinsBalances, ...newcoinsBalances.items],
        tableLoading: false,
        loading: false,
    }),
    [GET_NEWCOINS_BALANCES_FAILURE]: (state) => ({...state}),
    [GET_NEWCOINS_BALANCES_REQUEST]: (state) => ({...state, loading: true, newcoinsBalances: [], total: 0}),

    DEFAULT: (state) => state,
}


const newcoinsBalancesReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default newcoinsBalancesReducer
