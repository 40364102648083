import React, {useEffect, useMemo} from "react"
import Popup from "reactjs-popup"
import {useSelector} from "react-redux"

import EditTimeRecordPopup from "../../TimeTracker/components/EditTimeRecordPopup"
import InfoPopup from "../../../components/InfoPopup"

import {formatDate, minutesToString} from "../../../utils/datetime.utils"
import {canEditTimeRecord} from "../../../utils/permission.utils"

import {
    DD_MM_YYYY,
    HOURS_TYPE_ONLY_TIME_OFF_OPTIONS,
    TIME_RECORD_STATUS,
} from "../../../constants/other"
import {PERMISSIONS} from "../../../constants/permissions"
import {usePaginatedQuery} from "../../../hooks/common/usePaginatedQuery"
import useTimeRecords from "../../../hooks/useTimeRecords"
import Table from "../../../components/Table/Table"
import DeleteTimeRecordPopup from "./DeleteTimeRecordPopup"


const TimeRecordsTable = ({
    dateFilter,
    isAllProjectsSelected,
    selectedProjectIds,
    userId,
    setUserMessage,
}) => {

    const params = useMemo(() => ({
        all: true,
        created_after: formatDate(dateFilter.created_after),
        created_before: formatDate(dateFilter.created_before),
        project_in: isAllProjectsSelected ? null : selectedProjectIds.toString()
    }), [selectedProjectIds, dateFilter, isAllProjectsSelected])

    const {
        loading,
        items,
        hasMore,
        loadNextPage,
        changeParams,
        reset,
    } = usePaginatedQuery(useTimeRecords, {defaultState: { params: { userId, ...params }}})

    useEffect(() => {
        changeParams(params)
    }, [changeParams, params])


    const auth = useSelector((state) => state.auth)
    const {hasPermission} = useSelector((state) => state.profile)
    
    const isMyProfile = auth.user.uid === userId

    const timeOffHoursTypes = HOURS_TYPE_ONLY_TIME_OFF_OPTIONS.map(option => option.value)
    
    const columns = [
        {
            field: "id",
            isKey: true,
            hidden: true
        },
        {
            field: "date",
            title: "DATE",
            get: (row) => formatDate(row.date, DD_MM_YYYY),
        },
        {
            field: "hours_type",
            title: "HOURS TYPE",
        },
        {
            field: "project",
            title: "PROJECT",
            get: (row) => row.project?.name,
        },
        {
            field: "duration",
            title: "Duration",
            get: (row) => minutesToString(row.duration),
        },
        {
            field: "note",
            title: "Note",
            custom: true,
            get: (row) => (
                <Popup trigger={<span className="cell-limited-text clickable"> {row.note} </span>} modal>
                    {close => (
                        <InfoPopup
                            title="Note"
                            value={row.note}
                            close={close}
                        />
                    )}
                </Popup>
            )
        },
        {
            field: "status",
            title: "STATUS",
            get: (row) => (
                <span
                    className={`time-records-content-status-${row.status.split(" ").join("-").toLowerCase()} t-h3`}>
                    {row.status}
                </span>
            )
        },
        {
            field: "actions",
            title: "ACTIONS",
            get: (row) => {
                if ((isMyProfile && hasPermission(PERMISSIONS.WRITE_OWN_TIME_RECORD) && canEditTimeRecord(row.date)) || hasPermission(PERMISSIONS.WRITE_TIME_RECORD)) {
                    return (
                        <>
                            {!timeOffHoursTypes.includes(row.hours_type) && row?.status !== TIME_RECORD_STATUS.IN_REVIEW &&
                                <Popup trigger={<span className="custom-scrollable-table-link">edit</span>} modal>
                                    {close => (
                                        <EditTimeRecordPopup
                                            timeRecord={row}
                                            refreshData={reset}
                                            setUserMessage={setUserMessage}
                                            close={close}
                                            userId={userId}
                                        />
                                    )}
                                </Popup>
                            }
                            {(hasPermission(PERMISSIONS.WRITE_TIME_RECORD) ||
                                    (hasPermission(PERMISSIONS.WRITE_OWN_TIME_RECORD) && isMyProfile && row?.status === TIME_RECORD_STATUS.IN_REVIEW) ||
                                    !timeOffHoursTypes.includes(row.hours_type)) &&
                                <Popup trigger={<span className="custom-scrollable-table-link">delete</span>} modal>
                                    {close => (
                                        <DeleteTimeRecordPopup
                                            timeRecord={row}
                                            refreshData={reset}
                                            setUserMessage={setUserMessage}
                                            close={close}
                                        />
                                    )}
                                </Popup>
                            }
                        </>
                    )
                }
            },
            type: "custom"
        },
    ]

    return <Table
        items={items}
        columns={columns}
        loading={loading}
        hasMore={hasMore}
        loadNextPage={loadNextPage}
        setUserMessage={setUserMessage}
    />
}

export default TimeRecordsTable
