import React from "react"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import {useDispatch, useSelector} from "react-redux"
import {receiveNotifications} from "../../Notifications/actions/dashboardNotificationsActions"
import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import Textarea from "../../../toolkits/Textarea/Textarea"
import {receiveUserOnboarding, updateOnboardingRequest} from "../../Profile/actions/onboardingActions"
import {ONBOARDING_STATUS} from "../../../constants/onboarding"


const ApproveOnboardingRequestPopup = ({onboarding_request, approve, refreshTable, setUserMessage, close}) => {
    const fullName = `${onboarding_request.user?.first_name} ${onboarding_request.user?.last_name}`
    const [reason, setReason] = React.useState("")
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth)

    const handleSubmit = () => {
        const data = {
            onboarding_status: approve ? ONBOARDING_STATUS.APPROVED : ONBOARDING_STATUS.DECLINED,
            reason: reason ? reason : null,
        }

        const promises = [
            dispatch(updateOnboardingRequest(onboarding_request.id, data)),
            dispatch(receiveNotifications()),
            dispatch(receiveUserOnboarding(auth.user.uid))
        ]

        Promise.all(promises)
            .then(() => {
                setUserMessage({message: `Onboarding request was successfully ${approve ? "approved" : "declined"}!`, code: SUCCESS_CODE })
                if (refreshTable) {
                    refreshTable()
                }
                close()
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to ${approve ? "approve" : "decline"} ${fullName} onboarding?`}
            isSubtitle isAgreePopup
        >
            {!approve &&
                <div className="onboarding-requests-popup-content-reason">
                    <Textarea
                        text_label="Reason"
                        value={reason}
                        onChange={e => setReason(e.target.value)}
                    />
                </div>}
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleSubmit}> {approve ? "APPROVE" : "DECLINE"} </SmallButton>
                </div>
            </div>

        </CustomPopup>
    )
}

export default ApproveOnboardingRequestPopup