import {
    getNewcoinsBalances,
} from "../api"
import {
    GET_NEWCOINS_BALANCES_REQUEST,
    GET_NEWCOINS_BALANCES_SUCCESS,
    GET_NEWCOINS_BALANCES_FAILURE,
    GET_NEXT_PAGE_NEWCOINS_BALANCES_REQUEST,
} from "../const"


const request = () => ({type: GET_NEXT_PAGE_NEWCOINS_BALANCES_REQUEST})

const success = (newcoinsBalances) => ({
    type: GET_NEWCOINS_BALANCES_SUCCESS,
    payload: {newcoinsBalances},
})
const failure = () => ({type: GET_NEWCOINS_BALANCES_FAILURE})

const searchRequest = () => ({type: GET_NEWCOINS_BALANCES_REQUEST})


export const receiveNextPageNewcoinsBalances = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveNewcoinsBalancesData} = await getNewcoinsBalances(params)

        dispatch(success(receiveNewcoinsBalancesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNewcoinsBalances = ({params, cancelToken}) => async (dispatch) => {
    dispatch(searchRequest())
    try {
        const {data: receiveNewcoinsBalancesData} = await getNewcoinsBalances(params, cancelToken)
        
        dispatch(success(receiveNewcoinsBalancesData))
    } catch (e) {
        dispatch(failure(e))
    }
}