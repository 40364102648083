import React from "react"
import {useDispatch, useSelector} from "react-redux"

import {receiveEquipmentCategories} from "../actions/EquipmentCategoriesActions"
import {createEquipmentCategory} from "../api"

import CreatableDDList from "../../../toolkits/CreatableDDList/CreatableDDList"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"


export const EquipmentCategoriesDDList = ({setCategory, setUserMessage, ...props}) => {
    const dispatch = useDispatch()

    const equipmentCategories = useSelector((state) => state.equipmentCategories.equipmentCategories)

    return (
        <CreatableDDList
            text_label="Category"
            placeholder=""
            listOptions={equipmentCategories.map((category) => ({value: category.id, label: category.name}))}
            onChange={category => {setCategory({value: category.value, label: category.label})}}            
            onCreateOption={(new_option) => {
                createEquipmentCategory({name: new_option})
                    .then((response) => {
                        setCategory({value: response.data.id, label: response.data.name})
                        dispatch(receiveEquipmentCategories())
                        setUserMessage({message: "Equipment category was successfully created!", code: SUCCESS_CODE})
                    })
                    .catch(error => {
                        console.log(error)
                        setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                    })
            }}
            {...props}
        />
    )
}
