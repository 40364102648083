import React from "react"
import BalanceCard from "./BalanceCard"
import SpendCard from "./SpendCard"
import PurchaseHistoryCard from "./PurchaseHistoryCard"
import {useSelector} from "react-redux"
import {PERMISSIONS} from "../../../constants/permissions"
import Protected from "../../../components/Protected/Protected"
import ClaimCoupon from "../component/ClaimCoupon"


const ShopHeader = () => {
    const user = useSelector((state) => state.profile.myInfo)

    return (
        <div className="shop-header-banner">
            <div className="shop-header-banner-left">
                <div className="shop-header-banner-left-title t-banner">Welcome to the Newsoft Shop</div>
                <div className="t-s4">Use your bonuses to unlock exclusive items and experiences. Treat yourself to the
                    rewards you deserve.
                </div>
                <ClaimCoupon />
            </div>
            <div className="shop-header-banner-right">
                <div className="shop-header-banner-right-info">
                    <BalanceCard user={user}/>
                    <SpendCard user={user}/>
                </div>
                <Protected permissions={[PERMISSIONS.READ_OWN_PURCHASE_REQUEST]}>
                    <PurchaseHistoryCard user={user}/>
                </Protected>
            </div>
        </div>
    )
}

export default ShopHeader