import {getRoles} from "../api"
import {GET_NEXT_PAGE_ROLES_REQUEST, GET_ROLES_FAILURE, GET_ROLES_REQUEST, GET_ROLES_SUCCESS,} from "../const"


const nextPageRequest = () => ({type: GET_NEXT_PAGE_ROLES_REQUEST})

const success = (roles) => ({
    type: GET_ROLES_SUCCESS,
    payload: {roles},
})
const failure = () => ({type: GET_ROLES_FAILURE})

const request = () => ({type: GET_ROLES_REQUEST})


export const receiveNextPageRoles = (params) => async (dispatch) => {
    dispatch(nextPageRequest())
    try {
        const {data: receiveRolesData} = await getRoles(params)

        dispatch(success(receiveRolesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveRoles = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveRolesData} = await getRoles(params)
        dispatch(success(receiveRolesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
