import {getUserOnboardingRequest, updateOnboardingRequestById} from "../../OnboardingRequests/api"
import {
    GET_USER_ONBOARDING_FAILURE,
    GET_USER_ONBOARDING_SUCCESS,
    GET_USER_ONBOARDING_REQUEST,
    UPDATE_ONBOARDING_REQUEST,
    SET_ONBOARDING_ANIMATION, CHECK_FIRST_ONBOARDING_STEP
} from "../const"
import {UPDATE_ONBOARDING_REQUEST_FAILURE, UPDATE_ONBOARDING_REQUEST_SUCCESS} from "../const"
import {ONBOARDING_STEPS} from "../../../constants/onboarding"

const request = () => ({ type: GET_USER_ONBOARDING_REQUEST })

const success = (userOnboarding) => ({
    type: GET_USER_ONBOARDING_SUCCESS,
    payload: {userOnboarding}
})

const failure = () => ({ type: GET_USER_ONBOARDING_FAILURE })

const updateRequest = () => ({ type: UPDATE_ONBOARDING_REQUEST })

const updateSuccess = (onboardingRequest) => ({
    type: UPDATE_ONBOARDING_REQUEST_SUCCESS,
    payload: {onboardingRequest},
})

const updateFailure = () => ({ type: UPDATE_ONBOARDING_REQUEST_FAILURE })

export const receiveUserOnboarding = (userId, params) => async (dispatch) => {
    dispatch(request())
    try {
        const receiveOnboardingRequestData = await getUserOnboardingRequest(userId, params)
        dispatch(success(receiveOnboardingRequestData.data))
    } catch (e) {
        dispatch(failure())
    }
}

export const updateOnboardingRequest = (onboarding_id, data) => async (dispatch) => {
    dispatch(updateRequest())
    try {
        const {data: onboardingRequest} = await updateOnboardingRequestById(onboarding_id, data)
        dispatch(updateSuccess(onboardingRequest))
    } catch (e) {
        dispatch(updateFailure())
    }
}

export const setOnboardingAnimation = (value) => ({
    type: SET_ONBOARDING_ANIMATION,
    payload: {value}
})

export const checkFirstOnboardingStep = () => {
    return {
        type: CHECK_FIRST_ONBOARDING_STEP,
        payload: {
            stepKey: Object.keys(ONBOARDING_STEPS)[0],
        },
    }
}