import {coinsRelatedApi} from "./base"


export const shopApi = coinsRelatedApi.injectEndpoints({
    endpoints: (builder) => ({
        createOrder: builder.mutation({
            query: (data) => ({path: "purchase-requests/", method: "POST", data}),
            invalidatesTags: ["BalanceAvailable", "BalanceSpent"]
        }),
    })
})

export const {
    useCreateOrderMutation,
} = shopApi
