import React, {useState} from "react"
import {useSelector} from "react-redux"
import Popup from "reactjs-popup"

import EarnInfoPopup from "./EarnInfoPopup"
import OvertimeInfoPopup from "./OvertimeInfoPopup"
import BonusesInfoPopup from "./BonusesInfoPopup"
import IncomeCard from "./IncomeCard"
import Protected from "../../../components/Protected/Protected"

import {formatDate} from "../../../utils/datetime.utils"


import {PERMISSIONS} from "../../../constants/permissions"
import {getUserViewedAs} from "../../../utils/localstorage.utils"
import useTimeTrackerChart from "../../../hooks/useTimeTrackerChart"
import useUserReport from "../../../hooks/useUserReport"


const InfoCards = ({ date, projects}) => {
    const {selectedUser } = useSelector(state => state.reportsPage)
    const {hasPermission} = useSelector((state) => state.profile)

    const auth = useSelector((state) => state.auth)
    const userId = getUserViewedAs() ? getUserViewedAs().user_id : selectedUser?.id ?? auth.user.uid

    const {overtimes, earn, bonuses} = useUserReport({
        userId,
        params: {
            start_date: formatDate(date.created_after),
            end_date: formatDate(date.created_before),
            ...(projects && projects.length !== 0 ? {project_in: projects.toString()} : {}),
        }
    }, { skip: !hasPermission(PERMISSIONS.READ_OWN_SALARY) })


    const {hoursReport} = useTimeTrackerChart({
        userId,
        params: {
            start_date: formatDate(date.created_after),
            end_date: formatDate(date.created_before),
            ...(projects && projects.length !== 0 ? {project_in: projects.toString()} : {}),
        }
    })

    const [showEarnInfoPopup, setShowEarnInfoPopup] = useState(false)
    const [showOvertimeInfoPopup, setShowOvertimeInfoPopup] = useState(false)
    const [showBonusesInfoPopup, setShowBonusesInfoPopup] = useState(false)
    const dateRange = {
        start_date: formatDate(date?.created_after),
        end_date: formatDate(date?.created_before)
    }
    const isWithoutOvertimeBonusesText = {
        overtime: overtimes.length,
        bonuses: bonuses.length
    }
    const isMyReports = !selectedUser || auth.user.uid === selectedUser.id

    return (
        <div className="reports-info">
            <div className="reports-info-card-big">
                <div className="reports-info-card-header">
                    <span className="t-h3">clocked hours</span>
                </div>
                <span className="t-h1 reports-info-main-text">{hoursReport.current}/{hoursReport.total}</span>
            </div>

            <Protected permissions={isMyReports ? [PERMISSIONS.READ_OWN_SALARY] : [PERMISSIONS.READ_SALARY]}>
                <IncomeCard
                    income={earn}
                    title={"Earn"}
                    showZeroIncome={true}
                    withPlus={false}
                    setInfoPopup={setShowEarnInfoPopup}
                    isWithoutOvertimeBonusesText={isWithoutOvertimeBonusesText}
                />
                {overtimes.length ?              
                    <IncomeCard
                        income={overtimes}
                        title={"Overtimes"}
                        showZeroIncome={false}
                        withPlus={true}
                        setInfoPopup={setShowOvertimeInfoPopup}
                    /> : <></>}
                {bonuses.length ?
                    <IncomeCard
                        income={bonuses}
                        title={"Bonuses"}
                        showZeroIncome={false}
                        withPlus={true}
                        setInfoPopup={setShowBonusesInfoPopup}
                    />
                    : <></>}
            </Protected>

            <Popup open={showEarnInfoPopup} closeOnDocumentClick onClose={() => setShowEarnInfoPopup(false)} modal>
                {close => <EarnInfoPopup close={close} userId={userId} dateRange={dateRange} projects={projects}/>}
            </Popup>

            <Popup open={showOvertimeInfoPopup} closeOnDocumentClick onClose={() => setShowOvertimeInfoPopup(false)} modal>
                {close => <OvertimeInfoPopup close={close} userId={userId} dateRange={dateRange} projects={projects}/>}
            </Popup>
            
            <Popup open={showBonusesInfoPopup} closeOnDocumentClick onClose={() => setShowBonusesInfoPopup(false)} modal>
                {close => <BonusesInfoPopup close={close} userId={userId} dateRange={dateRange}/>}
            </Popup>
        </div>
    )
}

export default InfoCards
