import {
    GET_EQUIPMENT_CATEGORIES_REQUEST,
    GET_EQUIPMENT_CATEGORIES_SUCCESS,
    GET_EQUIPMENT_CATEGORIES_FAILURE,
    GET_NEXT_PAGE_EQUIPMENT_CATEGORIES_REQUEST,
} from "../const"


const initialState = {
    equipmentCategories: [],
    total: 0,
    tableLoading: false,
}
  
const handlers = {
    [GET_EQUIPMENT_CATEGORIES_REQUEST]: (state) => ({...state, tableLoading: true, equipmentCategories: [], total: 0}),
    [GET_EQUIPMENT_CATEGORIES_SUCCESS]: (state, {payload: {equipmentCategories}}) => ({
        ...state,
        equipmentCategories: [...state.equipmentCategories, ...equipmentCategories.items],
        total: equipmentCategories.total,
        tableLoading: false,
    }),
    [GET_EQUIPMENT_CATEGORIES_FAILURE]: (state) => ({...state, tableLoading: false}),
    
    [GET_NEXT_PAGE_EQUIPMENT_CATEGORIES_REQUEST]: (state) => ({...state}),
    
    DEFAULT: (state) => state,
}

const equipmentCategoriesReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
  
export default equipmentCategoriesReducer
