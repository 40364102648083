import React, {useEffect, useRef, useState} from "react"
import {useDispatch} from "react-redux"

import {updatePerformanceReviewById} from "../api"
import {receiveNotifications} from "../../Notifications/actions/dashboardNotificationsActions"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import {SUCCESS_CODE, ERROR_CODE, MOBILE_WIDTH} from "../../../constants/other"
import RichTextEditor from "../../../toolkits/RichTextEditor"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"
import { DD_MM_YYYY } from "../../../constants/other"
import { useMediaQuery } from "react-responsive"
import { formatDate } from "../../../utils/datetime.utils"


const EditPerformanceReviewPopup = ({performanceReview, refreshTable, setUserMessage, close}) => {
    const dispatch = useDispatch()

    const [improvements, setImprovements] = useState(performanceReview.improvements)
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})
    const buttonBlock = useRef()

    const handleSubmit = () => {        
        const data = {
            improvements: improvements || "",
        }
        updatePerformanceReviewById(performanceReview.id, data)
            .then(() => {
                refreshTable()
                dispatch(receiveNotifications())
                setUserMessage({message: "Performance review was successfully edited!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    useEffect(() => {
        buttonBlock.current.scrollIntoView({ behavior: "smooth" })
    }, [improvements])


    return (
        <FlexiblePopup noStyle={true}>
            <div className="modify-performance-review-popup">
                <div className="modify-performance-review-popup-title">
                    <span className={`${isMobile ? "t-s1" : "t-h2"}`}> Edit Performance Review</span>
                    <div className="modify-performance-review-popup-title-date">
                        <span className="t-s2">{`(${performanceReview.created_at ? formatDate(performanceReview.created_at, DD_MM_YYYY).concat("-") : ""}${formatDate(performanceReview.date, DD_MM_YYYY)})`}</span>
                    </div>
                </div>
                <div className="modify-performance-review-popup-content">
                    <RichTextEditor
                        title="Goals"
                        label="Write up goals of the review here..."
                        value={performanceReview.improvements}
                        setValue={setImprovements}
                    />
                    <div className="popup-bottom" ref={buttonBlock}>
                        <div className="popup-bottom-button-container">
                            <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                            <SmallButton type="submit" onClick={handleSubmit}> SAVE </SmallButton>
                        </div>
                    </div>
                </div>
            </div>
        </FlexiblePopup>
    )
}

export default EditPerformanceReviewPopup
