import React from "react"

import {deletePublicDayOffById} from "../api"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"


const DeletePublicDayOffPopup = ({publicDayOff, refreshTable, setUserMessage, close}) => {
    const handleSubmit = () => {
        deletePublicDayOffById(publicDayOff.id)
            .then(() => {
                refreshTable(publicDayOff.date)
                setUserMessage({message: "Public day off was successfully deleted!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to delete ${publicDayOff.name} day off?`} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeletePublicDayOffPopup
