import React, { useEffect, useState } from "react"
import FlexiblePopup from "../../../../toolkits/FlexiblePopup"
import { formatDate } from "../../../../utils/datetime.utils"
import { DD_MM_YYYY, MOBILE_WIDTH } from "../../../../constants/other"
import CloseIcon from "@mui/icons-material/Close"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import { useMediaQuery } from "react-responsive"
import { useDispatch, useSelector } from "react-redux"
import StyledCircularProgress from "../../../../toolkits/CircularProgress/CircularProgress"
import CustomLink from "../../../../toolkits/CustomLink"
import SmallButton from "../../../../toolkits/SmallButton/SmallButton"
import { PerformanceReviewsRoute } from "../../../../routes"
import { PERMISSIONS } from "../../../../constants/permissions"
import { receiveUsersPerformanceReviews } from "../../../PerformanceReviews/actions/performanceReviewsActions"
import { displayHTML } from "../../../../utils/other.utils"

const PerformanceReviewInfoPopup = ({ reviewId, close, userId }) => {
    const isMobile = useMediaQuery({ maxWidth: MOBILE_WIDTH })
    const dispatch = useDispatch()
    const [currentReviewIndex, setCurrentReviewIndex] = useState(0)
    const [currentPerformanceReview, setCurrentPerformanceReview] = useState()
    const { hasPermission } = useSelector((state) => state.profile)
    const { tableLoading, performanceReviews } = useSelector((state) => state.performanceReviews)

    useEffect(() => {
        dispatch(receiveUsersPerformanceReviews(userId))
    }, [userId])

    useEffect(() => {
        if (!tableLoading && performanceReviews.length > 0) {
            const sortedReviews = performanceReviews.sort((a, b) => new Date(a.date) - new Date(b.date))
            setCurrentReviewIndex(reviewId ? sortedReviews.findIndex(review => review.id === reviewId) : sortedReviews.length - 1)
        }
    }, [tableLoading, performanceReviews, reviewId])

    useEffect(() => {
        setCurrentPerformanceReview(performanceReviews[currentReviewIndex])
    }, [currentReviewIndex, performanceReviews])

    const handlePrevReview = () => {
        if (currentReviewIndex > 0) {
            setCurrentReviewIndex(currentReviewIndex - 1)
        }
    }

    const handleNextReview = () => {
        if (currentReviewIndex < performanceReviews.length - 1) {
            setCurrentReviewIndex(currentReviewIndex + 1)
        }
    }

    return (
        <FlexiblePopup noStyle={true}>
            <div className="profile-perf-review">
                <div className="profile-perf-review-content">
                    <div className="profile-perf-review-content-title">
                        <div className="profile-perf-review-content-title-close">
                            <CloseIcon onClick={close} />
                        </div>
                        <div className="profile-perf-review-content-title-head">
                            <KeyboardArrowLeftIcon onClick={handlePrevReview} style={{ visibility: `${currentReviewIndex > 0 ? "visible" : "hidden"}` }} />
                            <span className={`${isMobile ? "t-s1" : "t-h2"}`}>Performance Review {!tableLoading ? `#${currentReviewIndex + 1}` : ""}</span>
                            <KeyboardArrowRightIcon onClick={handleNextReview} style={{ visibility: `${currentReviewIndex < (performanceReviews.length - 1) ? "visible" : "hidden"}` }} />
                        </div>
                    </div>
                    {tableLoading ? <StyledCircularProgress /> :
                        <>
                            <div className="profile-perf-review-content-date">
                                <span className={`${isMobile ? "t-b2" : "t-s2"}`}>{`(${currentPerformanceReview?.created_at ? formatDate(currentPerformanceReview?.created_at, DD_MM_YYYY).concat("-") : ""}${formatDate(currentPerformanceReview?.date, DD_MM_YYYY)})`}</span>
                            </div>

                            {(currentPerformanceReview?.improvements || currentPerformanceReview?.comment) ?
                                <>{currentPerformanceReview?.improvements &&
                                <div className="profile-perf-review-content-goals">
                                    <span className="profile-perf-review-content-goals-title t-s1">Goals</span>
                                    <div className="profile-perf-review-content-goals-content" dangerouslySetInnerHTML={displayHTML(currentPerformanceReview?.improvements)} />
                                </div>}

                                {currentPerformanceReview?.comment && <div className="profile-perf-review-content-summary">
                                    <span className="profile-perf-review-content-summary-title t-s1">Summary</span>
                                    <div className="profile-perf-review-content-summary-content" dangerouslySetInnerHTML={displayHTML(currentPerformanceReview?.comment)} />
                                </div>}</> : 
                                <div className="profile-perf-review-content-placeholder">
                                    <span className="t-s2">No goals</span>
                                </div>
                            }
                        </>
                    }
                </div>
                {hasPermission(PERMISSIONS.READ_PERFORMANCE_REVIEW) && !tableLoading && <div className="popup-bottom">
                    <CustomLink to={{
                        pathname: PerformanceReviewsRoute.path,
                        state: {
                            userId: userId,
                        }
                    }}>
                        <SmallButton btnType="secondary"> See all </SmallButton>
                    </CustomLink>
                </div>}
            </div>
        </FlexiblePopup>
    )
}

export default PerformanceReviewInfoPopup
