import http from "../../http"
import {API_V1_URL} from "../../constants/other"


const EQUIPMENT_CATEGORIES_URL = `${API_V1_URL}/equipment-categories/`


export const getEquipmentCategories = (params) => http.get(EQUIPMENT_CATEGORIES_URL, {params})

export const getEquipmentCategoryById = (category_id) => http.get(`${EQUIPMENT_CATEGORIES_URL}${category_id}`)

export const createEquipmentCategory = (payload) => http.post(EQUIPMENT_CATEGORIES_URL, {data: payload})

export const updateEquipmentCategoryById = (category_id, data) => http.put(`${EQUIPMENT_CATEGORIES_URL}${category_id}`, {data: data})

export const deleteEquipmentCategoryById = (category_id) => http.delete(`${EQUIPMENT_CATEGORIES_URL}${category_id}`)
