import {getEquipmentCategories} from "../api"

import {
    GET_EQUIPMENT_CATEGORIES_REQUEST,
    GET_EQUIPMENT_CATEGORIES_SUCCESS,
    GET_EQUIPMENT_CATEGORIES_FAILURE,
    GET_NEXT_PAGE_EQUIPMENT_CATEGORIES_REQUEST,
} from "../const"


const request = () => ({type: GET_EQUIPMENT_CATEGORIES_REQUEST})

const nextPageRequest = () => ({type: GET_NEXT_PAGE_EQUIPMENT_CATEGORIES_REQUEST})

const success = (equipmentCategories) => ({
    type: GET_EQUIPMENT_CATEGORIES_SUCCESS,
    payload: {equipmentCategories},
})

const failure = () => ({type: GET_EQUIPMENT_CATEGORIES_FAILURE})


export const receiveEquipmentCategories = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveEquipmentCategoriesData} = await getEquipmentCategories(params)

        dispatch(success(receiveEquipmentCategoriesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageEquipmentCategories = (params) => async (dispatch) => {
    dispatch(nextPageRequest())
    try {
        const {data: receiveNextPageEquipmentCategoriesData} = await getEquipmentCategories(params)
        dispatch(success(receiveNextPageEquipmentCategoriesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
