import {getPublicDayOffs, getPublicDayOffsUniqueYears} from "../api"

import {
    GET_PUBLIC_DAY_OFFS_REQUEST,
    GET_PUBLIC_DAY_OFFS_SUCCESS,
    GET_PUBLIC_DAY_OFFS_FAILURE,
    GET_PUBLIC_DAY_OFFS_UNIQUE_YEARS_REQUEST,
    GET_PUBLIC_DAY_OFFS_UNIQUE_YEARS_SUCCESS,
    GET_PUBLIC_DAY_OFFS_UNIQUE_YEARS_FAILURE,
} from "../const"


const request = () => ({type: GET_PUBLIC_DAY_OFFS_REQUEST})

const success = (publicDayOffs) => ({
    type: GET_PUBLIC_DAY_OFFS_SUCCESS,
    payload: {publicDayOffs},
})

const failure = () => ({type: GET_PUBLIC_DAY_OFFS_FAILURE})


const requestYears = () => ({type: GET_PUBLIC_DAY_OFFS_UNIQUE_YEARS_REQUEST})

const successYears = (uniqueYears) => ({
    type: GET_PUBLIC_DAY_OFFS_UNIQUE_YEARS_SUCCESS,
    payload: {uniqueYears},
})

const failureYears = () => ({type: GET_PUBLIC_DAY_OFFS_UNIQUE_YEARS_FAILURE})


export const receivePublicDayOffs = (params, cancelToken) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receivePublicDayOffsData} = await getPublicDayOffs(params, cancelToken)
        
        dispatch(success(receivePublicDayOffsData))
    } catch (e) {
        dispatch(failure(e))
    }
}

export const receivePublicDayOffsUniqueYears = () => async (dispatch) => {
    dispatch(requestYears())
    try {
        const {data: receiveUniqueYears} = await getPublicDayOffsUniqueYears()

        dispatch(successYears(receiveUniqueYears))
    } catch (e) {
        console.log(e)
        dispatch(failureYears())
    }
}
