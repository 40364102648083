import {API_V1_URL} from "../../constants/other"
import http from "../../http"

const PUBLIC_DAY_OFFS_URL = `${API_V1_URL}/public-day-offs`


export const getPublicDayOffs = (params, cancelToken) => http.get(`${PUBLIC_DAY_OFFS_URL}/`, {params}, {}, cancelToken)

export const getPublicDayOffsUniqueYears = () => http.get(`${PUBLIC_DAY_OFFS_URL}/years/unique`)

export const createPublicDayOff = (data) => http.post(`${PUBLIC_DAY_OFFS_URL}/`, {data})

export const updatePublicDayOffById = (publicDayOffId, data) => http.put(`${PUBLIC_DAY_OFFS_URL}/${publicDayOffId}`, {data})

export const deletePublicDayOffById = (publicDayOffId) => http.delete(`${PUBLIC_DAY_OFFS_URL}/${publicDayOffId}`)
