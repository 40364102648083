import {
    GET_ONBOARDING_REQUESTS_FAILURE,
    GET_ONBOARDING_REQUESTS_REQUEST,
    GET_ONBOARDING_REQUESTS_SUCCESS,
    GET_NEXT_PAGE_ONBOARDING_REQUESTS_REQUEST,
} from "../const"


const initialState = {
    onboardingRequests: [],
    total: 0,
    tableLoading: false,
}

const handlers = {
    [GET_ONBOARDING_REQUESTS_REQUEST]: (state) => ({ ...state, tableLoading: true, onboardingRequests: [], total: 0 }),
    [GET_ONBOARDING_REQUESTS_SUCCESS]: (state, { payload: {onboardingRequests} }) => ({
        ...state,
        onboardingRequests: [...state.onboardingRequests, ...onboardingRequests.items],
        total: onboardingRequests.total,
        tableLoading: false,
    }),
    [GET_ONBOARDING_REQUESTS_FAILURE]: (state) => ({ ...state, tableLoading: false }),
    [GET_NEXT_PAGE_ONBOARDING_REQUESTS_REQUEST]: (state) => ({ ...state }),

    DEFAULT: (state) => state,
}


const onboardingRequestsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default onboardingRequestsReducer