import http from "../../http"
import {API_V1_URL} from "../../constants/other"


const ONBOARDINGS_URL = `${API_V1_URL}/onboardings/`


export const getOnboardingRequests = (params) => http.get(`${ONBOARDINGS_URL}`, {params})

export const getUserOnboardingRequest = (userId) => http.get(`${ONBOARDINGS_URL}${userId}`)

export const updateOnboardingRequestById = (onboardingRequestId, data) =>
    http.put(`${ONBOARDINGS_URL}${onboardingRequestId}`, {data})