import {MOBILE_WIDTH} from "../../../../constants/other"
import Protected from "../../../../components/Protected/Protected"
import InfoIcon from "@mui/icons-material/Info"
import ExtendedCheckbox from "../../../../toolkits/ExtendedCheckbox"
import Popup from "reactjs-popup"
import SubmitOnboardingPopup from "../Popups/SubmitOnboardingPopup"
import ApproveOnboardingRequestPopup from "../../../OnboardingRequests/components/ApproveOnboardingRequestPopup"
import React, {useEffect, useState} from "react"
import {useMediaQuery} from "react-responsive"
import {useDispatch, useSelector} from "react-redux"
import {setOnboardingAnimation, updateOnboardingRequest} from "../../actions/onboardingActions"
import {ONBOARDING_STATUS, ONBOARDING_STEPS} from "../../../../constants/onboarding"
import {PERMISSIONS} from "../../../../constants/permissions"
import {getOnboardingPopupSeen} from "../../../../utils/localstorage.utils"


const OnboardingCard = ({onboarding, setOnboarding, isMyProfile, setUserMessage, setShowWelcomePopup}) => {
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})
    const {hasPermission} = useSelector((state) => state.profile)
    const dispatch = useDispatch()

    const [showSubmitOnboardingPopup, setShowSubmitOnboardingPopup] = useState(false)
    const showOnboardingAnimation = useSelector((state) => state.profile.showOnboardingAnimation)
    const onboardingPopupSeen = getOnboardingPopupSeen() === "true"

    const handleCheckboxChange = (stepKey) => {
        setOnboarding((prevOnboarding) => {
            const updatedOnboarding = {
                ...prevOnboarding,
                steps: {
                    ...prevOnboarding.steps,
                    [stepKey]: {
                        ...prevOnboarding.steps[stepKey],
                        checked: !prevOnboarding.steps[stepKey].checked,
                    },
                },
            }

            dispatch(updateOnboardingRequest(onboarding.id, updatedOnboarding))

            return updatedOnboarding
        })
    }

    const handleSubmitOnboarding = () => {
        hasPermission(PERMISSIONS.WRITE_OWN_ONBOARDING) &&
        setShowSubmitOnboardingPopup(true)
    }

    useEffect(() => {
        if (showOnboardingAnimation) {
            setTimeout(() => dispatch(setOnboardingAnimation(false)), 2000)
        }
    }, [showOnboardingAnimation])

    useEffect(() => {
        if (onboardingPopupSeen && !onboarding?.steps[Object.keys(ONBOARDING_STEPS)[0]]?.checked) {
            handleCheckboxChange(Object.keys(ONBOARDING_STEPS)[0])
        }
    }, [onboardingPopupSeen])

    return (
        <Protected permissions={isMyProfile ? [PERMISSIONS.READ_OWN_ONBOARDING] : [PERMISSIONS.READ_ONBOARDING]}>
            <div className={`profile-right-onboarding ${showOnboardingAnimation ? "highlight-animation" : ""}`}>
                <div className="profile-right-onboarding-header">
                    <span className="t-h3 profile-font-leading">ONBOARDING</span>
                    {isMyProfile && onboarding?.onboarding_status !== ONBOARDING_STATUS.IN_REVIEW && hasPermission(PERMISSIONS.WRITE_OWN_ONBOARDING) && <InfoIcon className="profile-right-onboarding-info-icon"/>}
                </div>
                <div className="profile-right-onboarding-content">
                    {Object.entries(ONBOARDING_STEPS).map(([key, { title, description }]) => (
                        <div key={key} className="profile-right-onboarding-content-step">
                            <ExtendedCheckbox
                                key={key}
                                onChange={() => handleCheckboxChange(key)}
                                checked={onboarding?.steps[key]?.checked}
                                openInNew={title === ONBOARDING_STEPS.WELCOME.title}
                                setShowWelcomePopup={setShowWelcomePopup}
                                label={title}
                                description={description}
                                locked={onboarding?.onboarding_status === ONBOARDING_STATUS.IN_REVIEW || !isMyProfile || !hasPermission(PERMISSIONS.WRITE_OWN_ONBOARDING)}
                                onboarding={onboarding}
                            />
                        </div>
                    ))}
                </div>
                {isMyProfile ?
                    (onboarding?.onboarding_status === ONBOARDING_STATUS.IN_PROGRESS || onboarding?.onboarding_status === ONBOARDING_STATUS.DECLINED
                        ?
                        <>
                            {(hasPermission(PERMISSIONS.WRITE_OWN_ONBOARDING) &&
                            <button onClick={handleSubmitOnboarding} className="profile-right-onboarding-button t-h3">Submit</button>)}

                            <Popup open={showSubmitOnboardingPopup} onClose={() => setShowSubmitOnboardingPopup(false)} closeOnDocumentClick modal>
                                {close => (
                                    <SubmitOnboardingPopup
                                        setUserMessage={setUserMessage}
                                        onboarding={onboarding}
                                        close={close}
                                    />
                                )}
                            </Popup>
                        </>
                        :
                        <span className={`t-b3 profile-right-onboarding-in-review-tag${isMobile ? "-mobile" : ""}`}>in review</span>)
                    :
                    onboarding?.onboarding_status === ONBOARDING_STATUS.IN_REVIEW ?
                        <Protected permissions={[PERMISSIONS.WRITE_ONBOARDING]}>
                            <div className="profile-right-onboarding-manager-actions t-b2">
                                <Popup trigger={<span className="custom-scrollable-table-link"> decline </span>} modal>
                                    {close => (
                                        <ApproveOnboardingRequestPopup
                                            onboarding_request={onboarding}
                                            setUserMessage={setUserMessage}
                                            approve={false}
                                            close={close}
                                        />
                                    )}
                                </Popup>
                                <Popup trigger={<span className="custom-scrollable-table-link"> approve </span>} modal>
                                    {close => (
                                        <ApproveOnboardingRequestPopup
                                            onboarding_request={onboarding}
                                            setUserMessage={setUserMessage}
                                            approve={true}
                                            close={close}
                                        />
                                    )}
                                </Popup>
                            </div>
                        </Protected>
                        :
                        null
                }
            </div>
        </Protected>
    )
}

export default OnboardingCard