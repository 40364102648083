import React, {useContext, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import Protected from "../../components/Protected/Protected"
import NavContainer from "../../components/containers/NavigationContainer"

import {isNotZero, isTrue} from "../../utils/other.utils"

import {
    CACHED_IMAGES,
    MOBILE_WIDTH,
    TABLET_WIDTH,
} from "../../constants/other"
import {DashboardRoute, EmployeesRoute, ProfileRoute, SettingsRoute, TimeTrackerRoute, ReportsRoute, ShopRoute} from "../../routes"
import CustomLink from "../../toolkits/CustomLink"
import {useCustomHistory} from "../../hooks/useCustomHistory"
import PersonRoundedIcon from "@mui/icons-material/PersonRounded"
import SupervisorAccountRoundedIcon from "@mui/icons-material/SupervisorAccountRounded"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import WatchLaterRoundedIcon from "@mui/icons-material/WatchLaterRounded"
import PollRoundedIcon from "@mui/icons-material/PollRounded"
import ViewModuleRoundedIcon from "@mui/icons-material/ViewModuleRounded"
import Slider from "../../toolkits/Slider/Slider"
import {useMediaQuery} from "react-responsive"
import {getAuthorizedUserData, saveAuthorizedUserData} from "../../utils/localstorage.utils"
import {receiveLocalStorage} from "../../pages/Auth/actions/localStorageActions"
import {updateUserById} from "../../pages/Employees/api"
import ToggleOffIcon from "@mui/icons-material/ToggleOff"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import {DASHBOARD_PERMISSIONS, PERMISSIONS} from "../../constants/permissions"
import CloseIcon from "@mui/icons-material/Close"
import { ThemeContext } from "../../styles/theme-context"


const Menu = ({expanded, setExpanded, activeNavs}) => {
    const history = useCustomHistory()
    const {hasPermission} = useSelector((state) => state.profile)
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})
    const isTablet = useMediaQuery({maxWidth: TABLET_WIDTH})
    const {privateMode} = getAuthorizedUserData()

    const auth = useSelector((state) => state.auth)
    const shopEnabled = useSelector((state) => state.settings.shopEnabled)

    const dispatch = useDispatch()
    const theme = useContext(ThemeContext)

    const [activeTab, setActiveTab] = useState([
        ProfileRoute.path,
        EmployeesRoute.path,
        ReportsRoute.path,
        TimeTrackerRoute.path,
        DashboardRoute.path,
        SettingsRoute.path,
        ShopRoute.path
    ].includes(history.location.state?.activeTab || history.location.pathname) ?
        history.location.state?.activeTab ||history.location.pathname : null
    )

    const onPrivateModeChanged = (checked) => {
        saveAuthorizedUserData({
            privateMode: checked,
        })
        dispatch(receiveLocalStorage())
        updateUserById(auth.user.uid, {private_mode: checked})
    }

    const showDashboardItem = () => DASHBOARD_PERMISSIONS.some((permission) => hasPermission(permission))
    const showShopItem = () => isTrue(shopEnabled.value) && hasPermission(PERMISSIONS.READ_SHOP)

    const NavHeader = () => {
        return (
            <div className="navs-header">
                <CustomLink to={{pathname: ProfileRoute.path}}>
                    {theme.navsExtraLogo}
                    <img src={CACHED_IMAGES.LogoNS} className="ns-logo" alt="NS logo"/></CustomLink>
                {expanded &&
                    <CloseIcon
                        onClick={() => setExpanded(false)}
                        style={{cursor: "pointer", color: "white"}}
                    />
                }
            </div>
        )
    }

    const MenuItem = ({ route, icon, notifications, iconId }) => {
        let isSelectedItem = route.path === activeTab

        if (!isSelectedItem && !activeTab) {
            isSelectedItem = history.location.state?.activeTab === route.path
        }

        return (
            <CustomLink
                to={{ pathname: route.path }}
                activeTab={route.path}
                onClick={() => setActiveTab(route.path)}
                className={`navs-content-menu-item ${isSelectedItem ? "selected-item-mobile" : ""}`}
            >
                <div className={`navs-content-menu-item-img-wrapper ${isSelectedItem ? "selected-item" : ""}`}>
                    <div className="navs-img-container" id={iconId}>
                        {/* Render the passed icon component */}
                        {icon}
                        <Protected permissions={[PERMISSIONS.READ_DASHBOARD_NOTIFICATION]}>
                            {notifications ?
                                <span className="navs-img-container notification"></span>
                                : null
                            }
                        </Protected>
                    </div>
                    {expanded && <span className="navs-content-menu-item-title t-s3">{route.name}</span>}
                </div>
            </CustomLink>
        )
    }


    const NavContent = () => {
        const {
            total_count,
        } = useSelector((state) => state.dashboardNotifications)

        return (
            <div className="navs-content">
                {/* PROFILE */}
                <MenuItem route={ProfileRoute} icon={<PersonRoundedIcon className="navs-content-menu-item-img-wrapper-custom-color"/>}/>

                {/* EMPLOYEES */}
                <Protected permissions={[PERMISSIONS.READ_USER]}>
                    <MenuItem route={EmployeesRoute} icon={<SupervisorAccountRoundedIcon className="navs-content-menu-item-img-wrapper-custom-color"/>} iconId="navs-employees-icon"/>
                </Protected>

                {/* TIME TRACKER */}
                <Protected permissions={[PERMISSIONS.READ_OWN_TIME_RECORD, PERMISSIONS.WRITE_OWN_TIME_OFF_REQUEST]}>
                    <MenuItem route={TimeTrackerRoute} icon={<WatchLaterRoundedIcon className="navs-content-menu-item-img-wrapper-custom-color"/>} iconId="navs-time-tracker-icon"/>
                </Protected>

                {/* REPORTS */ }
                <Protected permissions={[PERMISSIONS.READ_OWN_REPORTS]}>
                    <MenuItem route={ReportsRoute} icon={<PollRoundedIcon className="navs-content-menu-item-img-wrapper-custom-color"/>} iconId="navs-reports-icon"/>
                </Protected>

                {/* DASHBOARD */}
                {showDashboardItem() &&
                    <MenuItem route={DashboardRoute} notifications={isNotZero(total_count)} icon={<ViewModuleRoundedIcon className="navs-content-menu-item-img-wrapper-custom-color"/>}/>
                }

                {/* SHOP */}
                {showShopItem() &&
                    <MenuItem route={ShopRoute} icon={<ShoppingCartIcon className="navs-content-menu-item-img-wrapper-custom-color"/>}/>
                }
            </div>
        )
    }

    const NavFooter = () => {
        let isSelectedItem = SettingsRoute.path === history.location.pathname
        if(isSelectedItem && activeTab !== SettingsRoute.path){
            setActiveTab(SettingsRoute.path)
        }

        return (
            <div className="navs-footer">
                {(isMobile || isTablet) ? (
                    <>

                        <div className="navs-content-menu-item-private-mode-wrapper">
                            <div className="navs-img-container">
                                <div className="navs-footer-private-mode-mobile-icon" onClick={() => onPrivateModeChanged(!privateMode)}>
                                    {privateMode ? theme.privateModeOn : theme.privateModeOff}
                                </div>
                            </div>
                            {expanded && <span className="navs-content-menu-item-title-private-mode t-s3">PRIVATE MODE</span>}
                            <Slider
                                onChange={(e) => onPrivateModeChanged(e.target.checked)}
                                checked={privateMode || false} className={isMobile ? "navs-footer-private-mode-mobile-slider" : "navs-footer-private-mode-tablet-slider"}/>
                        </div>
                    </>
                ) : (
                    <div className="private-mode-div">
                        <div onClick={() => onPrivateModeChanged(!privateMode)}>
                            {privateMode ? theme.privateModeOn : theme.privateModeOff}
                        </div>
                        <div onClick={() => onPrivateModeChanged(!privateMode)}>
                            {privateMode ? <ToggleOnIcon className="navs-footer-private-mode-slider"/> : <ToggleOffIcon className="navs-footer-private-mode-slider-inactive"/>}
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <NavContainer activeNavs={activeNavs}>
            <NavHeader/>
            <NavContent/>
            <NavFooter/>
        </NavContainer>
    )
}

export default Menu
