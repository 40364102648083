import React from "react"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"
import {useDeactivateCouponMutation} from "../../../api/coupons"


const DeleteCouponPopup = ({coupon, setUserMessage, refreshTable, close}) => {
    const [deactivateCoupon, {isLoading}] = useDeactivateCouponMutation()

    const handleSubmit = () => {

        deactivateCoupon(coupon.id)
            .unwrap()
            .then(() => {
                refreshTable()
                setUserMessage({message: `Coupon ${coupon.code} was successfully deactivated!`, code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to deactivate the "${coupon.code}" coupon?`} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton disabled={isLoading} btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton loading={isLoading} onClick={handleSubmit}> DEACTIVATE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteCouponPopup
