import React, {useState} from "react"
import Select from "react-select"
import {useSelector} from "react-redux"


const ALL_CATEGORIES = "All categories"

export const LeftCategories = ({setCategory}) => {
    const [isFree, setIsFree] = useState(false)

    const handleClick = (equipmentIsFree) => {
        setIsFree(equipmentIsFree)
        if (equipmentIsFree) setCategory({free: true})
        setCategory(
            equipmentIsFree ? {free: true} : {}
        )
    }

    return (
        <div className="equipment-header-categories-box">
            <button type="button" onClick={() => handleClick(false)}
                className={`table-category ${!isFree ? "table-category-selected t-s3" : "t-s4"}`}>
                All
            </button>
            <button type="button" onClick={() => handleClick(true)}
                className={`table-category ${isFree ? "table-category-selected t-s3" : "t-s4"}`}>
                Unassign
            </button>
        </div>
    )
}

export const RightCategories = ({category, setCategory}) => {
    const equipmentCategories = useSelector((state) => state.equipmentCategories.equipmentCategories)

    return (
        <Select
            isSearchable={false}
            components={{
                IndicatorSeparator: () => null
            }}
            options={[ALL_CATEGORIES, ...equipmentCategories.map((value) => value.name)].map((category) => ({value: category, label: category}))}
            defaultValue={category?.category ? {value: category.category, label: category.category} : {value: ALL_CATEGORIES, label: ALL_CATEGORIES}}
            onChange={(typeCategory) => setCategory(typeCategory.value !== ALL_CATEGORIES ? {category: typeCategory.value} : {})}
            classNamePrefix="custom-select t-s1 equipment-category-react-select"
        />
    )
}
