import React, {useState} from "react"
import {Helmet} from "react-helmet"
import AuditLogsList from "./components/AuditLogsList"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import SingleDatePicker from "../../toolkits/Calendar/SingleDatePicker"


const AuditLogs = () => {
    const [userMessage, setUserMessage] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    return (
        <>
            <Helmet>
                <title>Audit Logs - Newsoft Inside</title>
            </Helmet>

            <div style={{ display: "flex", paddingTop: "1rem", gap: "1rem", paddingLeft: "1.75rem" }}>
                <SingleDatePicker
                    label="From"
                    defaultValue={startDate}
                    className="single-datepicker-field"
                    onChange={setStartDate}
                    minutes={true}
                    calendarPosition="bottom-left"
                />

                <SingleDatePicker
                    label="To"
                    defaultValue={endDate}
                    className="single-datepicker-field"
                    onChange={setEndDate}
                    minutes={true}
                    calendarPosition="bottom-left"
                />
            </div>

            {userMessage && <AlertMessage setMessage={setUserMessage} {...userMessage}/> }

            <AuditLogsList startDate={startDate} endDate={endDate} setUserMessage={setUserMessage}/>

        </>)
}

export default AuditLogs
