import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import Popup from "reactjs-popup"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import {receiveEquipmentCategories, receiveNextPageEquipmentCategories} from "./actions/EquipmentCategoriesActions"

import Protected from "../../components/Protected/Protected"
import EquipmentCategoriesTable from "./components/EquipmentCategoriesTable"
import CreateUpdateCategoryPopup from "./components/CreateUpdateCategoryPopup"

import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {PAGE_SIZE} from "../../constants/other"
import {PERMISSIONS} from "../../constants/permissions"


const EquipmentCategories = () => {
    const dispatch = useDispatch()

    const {
        tableLoading,
        equipmentCategories,
        total,
    } = useSelector((state) => state.equipmentCategories)
    
    const [currentPage, setCurrentPage] = useState(1)
    const [userMessage, setUserMessage] = useState(null)

    const [searchKey, setSearchKey] = useState("")
    const [addNewCategoryPopup, setAddNewCategoryPopup] = useState(false)

    const getParams = (page) => ({
        page: page || currentPage,
        name: searchKey,
        size: PAGE_SIZE,
    })

    const getEquipmentCategories = (page) => {
        const params = getParams(page)

        dispatch(receiveEquipmentCategories(params))
        setCurrentPage((page || currentPage) + 1)
    }

    const getNextEquipmentCategories = () => {
        const params = getParams()

        dispatch(receiveNextPageEquipmentCategories(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => { 
        getEquipmentCategories(1)
    }, [searchKey])

    return (
        <>
            <Helmet>
                <title>Equipment Categories - Newsoft Inside</title>
            </Helmet>

            <div className="equipment-categories">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="equipment-categories-header">
                    <SearchForm onChange={setSearchKey} debounceMs={500} />
                    <Protected permissions={[PERMISSIONS.WRITE_EQUIPMENT]}>
                        <div className="equipment-categories-header-button">
                            <SmallButton onClick={() => setAddNewCategoryPopup(true)}> ADD NEW CATEGORY </SmallButton>
                        </div>
                    </Protected>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={getNextEquipmentCategories}
                    hasMore={!tableLoading && equipmentCategories.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={equipmentCategories.length}
                    useWindow={false}
                >
                    {tableLoading ? <StyledCircularProgress/> :
                        <div className="equipment-categories-content">
                            <EquipmentCategoriesTable
                                equipmentCategories={equipmentCategories}
                                refreshTable={() => getEquipmentCategories(1)}
                                setUserMessage={setUserMessage}
                            />
                        </div>
                    }
                </InfiniteScroll>
            </div>

            <Popup open={addNewCategoryPopup} closeOnDocumentClick={false} onClose={() => setAddNewCategoryPopup(false)} modal>
                {close => (
                    <CreateUpdateCategoryPopup
                        refreshTable={() => getEquipmentCategories(1)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>)
}

export default EquipmentCategories
