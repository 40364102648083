import React, {useState} from "react"
import {EMPLOYEES_FILTERS} from "../../../constants/other"


export const EmployeesFilters = ({setFind}) => {
    const [typeFilter, setTypeFilter] = useState(EMPLOYEES_FILTERS.UNARCHIVE)

    const handleClick = (newFilter) => {
        setTypeFilter(newFilter)
        setFind(newFilter)
    }

    return (
        <div  className="employees-header-right-filters">
            {Object.values(EMPLOYEES_FILTERS).map((filter) => {
                return (
                    <button key={filter} type="button" onClick={() => handleClick(filter)}
                        className={`employees-header-right-filters-filter ${filter === typeFilter ? "employees-selected-option t-s3" : "t-s4"}`}>
                        {filter}
                    </button>
                )
            })}
        </div>
    )   
}
