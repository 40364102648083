import React, {useState} from "react"
import SingleOptionCard from "./SingleOptionCard"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import {PRODUCT_OPTION_TYPE} from "../../../constants/other"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import {getColorOptionValues} from "../../../utils/other.utils"

const OptionsCard = ({isEdit, options, setOptions, setUserMessage}) => {
    const [showOptionsCard, setShowOptionsCard] = useState(!isEdit)

    const findFirstAvailablePosition = (options) => {
        const positions = options.map(obj => obj.position)
        for (let i = 1; i <= 5; i++) {
            if (!positions.includes(i)) {
                return i
            }
        }
        return options.length + 1
    }

    const handleAddOption = () => {
        const newOption = {
            position: findFirstAvailablePosition(options),
            name: "",
            type: PRODUCT_OPTION_TYPE.TEXT,
            values: [""],
            details_name: "",
            details_image_url: ""
        }
        setOptions([...options, newOption])
    }

    const handleOptionChange = (position, field, value) => {
        const newOptions = options.map((option) => {
            if (option.position === position) {
                option[field] = value
                return option
            }
            return option
        })

        setOptions(newOptions)
    }

    const handleOptionRemove = (position) => {
        const newOptions = options.filter(option => option.position !== position)
        setOptions(newOptions)
    }

    const handleOptionValueRemove = (position, valueIndex) => {
        const newOptions = options.map(option => {
            if (option.position === position) {
                return ({ ...option, values: option.values.filter((_, index) => index !== valueIndex) })
            }
            return option
        })
        setOptions(newOptions)
    }

    return (
        <div className="create-product-left-options">
            <div className="create-product-left-options-title t-s6">Options</div>
            <KeyboardArrowDownIcon
                className={`arrow-down-icon ${showOptionsCard ? "expanded" : ""}`}
                onClick={() => setShowOptionsCard(!showOptionsCard)}
            />
            {showOptionsCard ?
                <>
                    {options
                        .sort((a, b) => a.position - b.position)
                        .map((option) => (
                            <SingleOptionCard
                                key={option.position}
                                option={option}
                                handleOptionChange={handleOptionChange}
                                handleOptionRemove={handleOptionRemove}
                                handleOptionValueRemove={handleOptionValueRemove}
                                setUserMessage={setUserMessage}
                            />
                        ))
                    }
                    {options.length <= 4 &&
                        <SmallButton onClick={handleAddOption} btnType="secondary">
                            Add {options.length > 0 ? "New" : "Product"} Option
                        </SmallButton>
                    }
                </> :
                <>
                    {options.length > 0 &&
                    <div className="create-product-left-options-folded t-b2">
                        {options
                            .filter(option => option.name && option.values.some(value => value))
                            .map(option => `${option.name}(${option.values.filter(value => value).map(value => getColorOptionValues(value).text).join(", ")})`)
                            .join(", ")}
                    </div>}
                </>
            }
        </div>
    )
}

export default OptionsCard
