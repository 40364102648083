import "../../layouts/Navs/NavsLayout.scss"
import "./index.scss"

import React, {useMemo, useState} from "react"
import {useSelector} from "react-redux"
import {Helmet} from "react-helmet"

import moment from "moment"

import TimeRecords from "./components/TimeRecords"
import InfoCards from "./components/InfoCards"
import {Loading} from "../../components/Loading/Loading"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"

import {endOfMonth, formatDate, startOfMonth} from "../../utils/datetime.utils"

import {DAYS_PER_WEEK, TIME_RECORD_STATUS, WORKING_HOURS_STRING} from "../../constants/other"
import useTimeRecords from "../../hooks/useTimeRecords"
import useTimeRecordDetails from "../../hooks/useTimeRecordDetails"
import useCalendarData from "../../hooks/useCalendarData"
import useTimeOffsUsageInfo from "../../hooks/useTimeOffsUsageInfo"
import useCurrentUserId from "../../hooks/useCurrentUserId"

const STATUSES = [TIME_RECORD_STATUS.APPROVED, TIME_RECORD_STATUS.IN_REVIEW, TIME_RECORD_STATUS.LOGGED].join(",")


const TimeTracker = () => {
    const userId = useCurrentUserId()
    const user = useSelector((state) => state.profile.myInfo)

    const [selectedDate, setSelectedDate] = useState(new Date())

    const timeRecordsParams = useMemo(() => ({
        created_at: formatDate(selectedDate),
        status_in: STATUSES,
        hours_type_in: WORKING_HOURS_STRING
    }), [selectedDate])

    const { timeRecords } = useTimeRecords({ userId, ...timeRecordsParams })

    const timeRecordDetailsParams = useMemo(() => ({
        date_: formatDate(selectedDate),
    }), [selectedDate])

    const {
        isLoading: timeRecordDetailsLoading,
        data: {
            day,
            week,
            month,
            overtimes,
            unpaid_day_off
        }
    } = useTimeRecordDetails({userId, params: timeRecordDetailsParams})


    const calendarDataParams = useMemo(() => ({
        start_date: formatDate(moment(startOfMonth(selectedDate)).add(-DAYS_PER_WEEK, "days")),
        end_date: formatDate(moment(endOfMonth(selectedDate)).add(DAYS_PER_WEEK, "days")),
    }), [selectedDate])
    useCalendarData({ userId, params: calendarDataParams })

    const { timeOffsUsageInfo, isLoading } = useTimeOffsUsageInfo({ userId, params: {year: new Date().getFullYear(), declined: true}})

    const [timeOffTabActive, setTimeOffTabActive] = useState(false)

    const [userMessage, setUserMessage] = useState(null)

    return isLoading ? <Loading/> : (
        <div>
            <Helmet>
                <title>TimeTracker - Newsoft Inside</title>
            </Helmet>
            <div className="time-tracker">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>}
                </div>
                <div className="time-tracker-content t-b3">
                    <TimeRecords
                        setUserMessage={setUserMessage}
                        onDateChanged={setSelectedDate}
                        loadingTable={isLoading}
                        timeRecords={timeRecords}
                        timeOffRequests={timeOffsUsageInfo.time_offs}
                        userId={userId}
                        timeOffTabActive={timeOffTabActive}
                        setTimeOffTabActive={setTimeOffTabActive}
                    />
                    {!timeRecordDetailsLoading && (
                        <InfoCards
                            day={day}
                            week={week}
                            month={month}
                            overtimes={overtimes}
                            unpaid_day_off={unpaid_day_off}
                            user={user}
                            timeOffTabActive={timeOffTabActive}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}


export default TimeTracker

