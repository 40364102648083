import EyeImage from "@mui/icons-material/RemoveRedEyeRounded"
import ClosedEyeImage from "@mui/icons-material/VisibilityOffRounded"

import React, {useState} from "react"
import {useSelector} from "react-redux"

import {updateUserById} from "../../Employees/api"

import AlertMessage from "../../../toolkits/AlertMessage/AlertMessage"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {ERROR_CODE} from "../../../constants/other"
import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {ProfileRoute} from "../../../routes"


export const ChangePasswordForm = () => {
    const history = useCustomHistory()

    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [passwordShown, setPasswordShown] = useState(false)
    const [passwordConfirmShown, setPasswordConfirmShown] = useState(false)
    const [alertMessage, setAlertMessage] = useState(null)
    const [requestedChange, setRequestedChange] = useState(false)

    const auth = useSelector((state) => state.auth)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            setAlertMessage({message: "Passwords must match", error: ERROR_CODE})
            return
        }
        setRequestedChange(true)
        updateUserById(auth.user.uid, {password})
            .then(() => {
                history.push(ProfileRoute.path)
            })
            .catch((error) => {
                setAlertMessage({message: error.response.data.message, error: ERROR_CODE})
            })
            .finally(() => {
                setRequestedChange(false)
            })
    }

    return (
        <div className="auth-wrapper-login">
            <div>
                {alertMessage &&
                    <AlertMessage setMessage={setAlertMessage} message={alertMessage?.message} code={alertMessage?.error}/>
                }
            </div>
            <div className="auth-wrapper-login-title">Welcome to Newsoft Inside</div>
            <form className="auth-wrapper-login-form" onSubmit={handleSubmit} autoComplete="off">
                <p className="auth-wrapper-reset-pass">
                    Please create new password for your account
                </p>
                <div className="auth-wrapper-login-form-password">
                    <label className="auth-wrapper-login-form-password-label t-s3" htmlFor="password-field">New
                        password</label>
                    <input
                        className="auth-wrapper-login-form-password-field"
                        type={passwordShown ? "text" : "password"}
                        placeholder="Password"
                        onChange={e => setPassword(e.target.value)}
                    />
                    <div className="auth-wrapper-login-form-password-visible" onClick={() => setPasswordShown(!passwordShown)}>
                        {passwordShown ?
                            <EyeImage className="auth-wrapper-login-form-password-eye-icon" alt="visible image"/> :
                            <ClosedEyeImage className="auth-wrapper-login-form-password-eye-icon" alt="invisible image"/>}
                    </div>
                </div>

                <div className="auth-wrapper-login-form-password">
                    <label className="auth-wrapper-login-form-password-label t-s3" htmlFor="password-field">Confirm new
                        password</label>
                    <input
                        className="auth-wrapper-login-form-password-field"
                        type={passwordConfirmShown ? "text" : "password"}
                        placeholder="Password"
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    <div className="auth-wrapper-login-form-password-visible" onClick={() => setPasswordConfirmShown(!passwordConfirmShown)}>
                        {passwordConfirmShown ?
                            <EyeImage className="auth-wrapper-login-form-password-eye-icon" alt="visible image"/> :
                            <ClosedEyeImage className="auth-wrapper-login-form-password-eye-icon" alt="invisible image"/>}
                    </div>
                </div>
                <div className="popup-bottom">
                    <div className="popup-bottom-button-container">
                        <SmallButton
                            btnType="secondary"
                            onClick={() => history.push("/")}
                        >Cancel</SmallButton>
                        <SmallButton
                            type="submit"
                            loading={requestedChange}
                            disabled={!password || password !== confirmPassword || requestedChange}
                            onClick={handleSubmit}
                        >Submit</SmallButton>
                    </div>
                </div>
            </form>
        </div>
    )
}
