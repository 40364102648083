import {
    GET_COUPONS_REQUEST,
    GET_COUPONS_SUCCESS,
    GET_COUPONS_FAILURE,
    GET_NEXT_PAGE_COUPONS_REQUEST,
} from "../const"


const initialState = {
    coupons: [],
    total: 0,
    tableLoading: false,
}
  
const handlers = {
    [GET_COUPONS_REQUEST]: (state) => ({...state, tableLoading: true, coupons: [], total: 0}),
    [GET_COUPONS_SUCCESS]: (state, {payload: {coupons}}) => ({
        ...state,
        coupons: [...state.coupons, ...coupons.items],
        total: coupons.total,
        tableLoading: false,
    }),
    [GET_COUPONS_FAILURE]: (state) => ({...state, tableLoading: false}),
    
    [GET_NEXT_PAGE_COUPONS_REQUEST]: (state) => ({...state}),
    
    DEFAULT: (state) => state,
}

const couponsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
  
export default couponsReducer
