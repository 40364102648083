import "./index.scss"

import React, {useState} from "react"
import Popup from "reactjs-popup"
import {Helmet} from "react-helmet"
import InfiniteScroll from "react-infinite-scroll-component"

import Protected from "../../components/Protected/Protected"
import ProjectsTable from "./components/ProjectsTable"
import CreateEditProjectPopup from "./components/CreateEditProjectPopup"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import SmallButton from "../../toolkits/SmallButton/SmallButton"

import {PERMISSIONS} from "../../constants/permissions"
import {useGetProjectsQuery} from "../../api/projects"
import {useTableData} from "../../hooks/common/useTableData"


const Projects = () => {
    const {items, params, changeParams, loading, hasMore, loadNextPage, reset } = useTableData(useGetProjectsQuery, "projectsTable")

    const onSearchChange = (value) =>  changeParams({name: value})

    const [userMessage, setUserMessage] = useState(null)
    const [addNewProjectPopup, setAddNewProjectPopup] = useState(false)

    return (
        <>
            <Helmet>
                <title> Projects - Newsoft Inside </title>
            </Helmet>
            <div className="projects">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message}
                            code={userMessage.code}/>
                    }
                </div>
                <div className="projects-header">
                    <div className="projects-header-search">
                        <SearchForm defaultValue={params?.name || ""} onChange={onSearchChange} debounceMs={500}/>
                    </div>
                    <Protected permissions={[PERMISSIONS.WRITE_PROJECT]}>
                        <div className="projects-header-button">
                            <SmallButton onClick={() => setAddNewProjectPopup(true)}> ADD NEW PROJECT </SmallButton>
                        </div>
                    </Protected>
                </div>
                <InfiniteScroll
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={items.length}
                >
                    {items.length > 0 && (<div className="projects-content">
                        <ProjectsTable
                            projects={items}
                            onSubmit={reset}
                            setUserMessage={setUserMessage}
                        />
                    </div>)}
                    {loading && <StyledCircularProgress/>}
                </InfiniteScroll>
            </div>

            <Popup open={addNewProjectPopup} closeOnDocumentClick={false} onClose={() => setAddNewProjectPopup(false)} modal>
                {close => (
                    <CreateEditProjectPopup
                        onSubmit={reset}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>

        </>
    )
}

export default Projects
