import React, {useEffect, useState} from "react"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import {Helmet} from "react-helmet"
import {useDispatch, useSelector} from "react-redux"
import {PAGE_SIZE, PURCHASE_REQUEST_STATUS} from "../../constants/other"
import InfiniteScroll from "react-infinite-scroller"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import "./index.scss"
import FilterPanel from "../../toolkits/FilterPanel"
import PersonalPurchaseHistoryTable from "./components/PersonalPurchaseHistoryTable"
import {receiveNextPageUserPurchases, receiveUserPurchases} from "./actions/personalPurchaseHistoryActions"
import {useParams} from "react-router-dom"

const PersonalPurchaseHistory = () => {
    const userId = useParams().id
    const dispatch = useDispatch()
    const [userMessage, setUserMessage] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [statusFilter, setStatusFilter] = useState(PURCHASE_REQUEST_STATUS.ALL)

    const {
        purchases,
        total,
        tableLoading
    } = useSelector((state) => state.personalPurchaseHistory)

    const getUserPurchases = (page) => {
        const params = {
            page: page,
            size: PAGE_SIZE,
            user_id: userId,
            status: statusFilter === PURCHASE_REQUEST_STATUS.ALL ? null : statusFilter
        }

        dispatch(receiveUserPurchases(params))
        setCurrentPage(page + 1)
    }

    const getNextUserPurchases = () => {
        const params = {
            page: currentPage,
            size: PAGE_SIZE,
            user_id: userId,
            status: statusFilter === PURCHASE_REQUEST_STATUS.ALL ? null : statusFilter
        }

        dispatch(receiveNextPageUserPurchases(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        getUserPurchases(1)
    }, [statusFilter])

    return (
        <>
            <Helmet>
                <title>Purchase History - Newsoft Inside</title>
            </Helmet>
            <div className="personal-purchases">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="personal-purchases-header">
                    <div className="personal-purchases-header-search">
                        <FilterPanel
                            title={"Type"}
                            options={PURCHASE_REQUEST_STATUS}
                            selectedOption={statusFilter}
                            setOptionFilter={setStatusFilter}
                        />
                    </div>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={getNextUserPurchases}
                    hasMore={!tableLoading && purchases.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    datalength={purchases.length}
                >
                    {tableLoading ? <StyledCircularProgress/> :
                        <div className="personal-purchases-content">
                            <PersonalPurchaseHistoryTable
                                purchases={purchases}
                                setUserMessage={setUserMessage}
                                refreshTable={() => getUserPurchases(1)}
                            />
                        </div>
                    }
                </InfiniteScroll>
            </div>
        </>
    )
}

export default PersonalPurchaseHistory