import React from "react"
import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../../toolkits/SmallButton/SmallButton"
import {ERROR_CODE, SUCCESS_CODE} from "../../../../constants/other"
import {updateOnboardingRequest} from "../../actions/onboardingActions"
import {useDispatch} from "react-redux"
import {ONBOARDING_STATUS} from "../../../../constants/onboarding"


const SubmitOnboardingPopup = ({onboarding, close, setUserMessage}) => {
    const dispatch = useDispatch()

    const handleSubmit = () => {
        const data = {
            steps: onboarding.steps,
            onboarding_status: ONBOARDING_STATUS.IN_REVIEW,
        }

        try {
            dispatch(updateOnboardingRequest(onboarding.id, data))
            setUserMessage({message: "Onboarding was successfully submitted!", code: SUCCESS_CODE})
        } catch (error) {
            setUserMessage({message: error.response.data.message, code: ERROR_CODE})
        }
    }

    return (
        <CustomPopup text_title="Do you want to submit onboarding changes and send to your manager?"
            isSubtitle isAgreePopup >
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleSubmit}> SUBMIT </SmallButton>
                </div>
            </div>

        </CustomPopup>
    )
}

export default SubmitOnboardingPopup