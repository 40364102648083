import {getCoupons} from "../api"

import {
    GET_COUPONS_REQUEST,
    GET_COUPONS_SUCCESS,
    GET_COUPONS_FAILURE,
    GET_NEXT_PAGE_COUPONS_REQUEST,
} from "../const"


const request = () => ({type: GET_COUPONS_REQUEST})

const nextPageRequest = () => ({type: GET_NEXT_PAGE_COUPONS_REQUEST})

const success = (coupons) => ({
    type: GET_COUPONS_SUCCESS,
    payload: {coupons},
})

const failure = () => ({type: GET_COUPONS_FAILURE})


export const receiveCoupons = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveCouponsData} = await getCoupons(params)

        dispatch(success(receiveCouponsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageCoupons = (params) => async (dispatch) => {
    dispatch(nextPageRequest())
    try {
        const {data: receiveNextPageCouponsData} = await getCoupons(params)
        dispatch(success(receiveNextPageCouponsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
