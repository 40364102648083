import {
    GET_NEXT_PAGE_PERFORMANCE_REVIEWS_REQUEST,
    GET_PERFORMANCE_REVIEWS_FAILURE,
    GET_PERFORMANCE_REVIEWS_REQUEST,
    GET_PERFORMANCE_REVIEWS_SUCCESS,
} from "../const"


const initialState = {
    performanceReviews: [],
    total: 0,
    tableLoading: true,
}

const handlers = {
    [GET_PERFORMANCE_REVIEWS_REQUEST]: (state) => ({...state, tableLoading: true, performanceReviews: [], total: 0}),
    [GET_PERFORMANCE_REVIEWS_SUCCESS]: (state, {payload: {performanceReviews}}) => ({
        ...state,
        performanceReviews: [...state.performanceReviews, ...performanceReviews.items],
        total: performanceReviews.total,
        tableLoading: false,
    }),
    [GET_PERFORMANCE_REVIEWS_FAILURE]: (state) => ({...state, tableLoading: false}),

    [GET_NEXT_PAGE_PERFORMANCE_REVIEWS_REQUEST]: (state) => ({...state}),

    DEFAULT: (state) => state,
}

const performanceReviewsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default performanceReviewsReducer
