import {getPermissions} from "../api"
import {
    GET_PERMISSIONS_REQUEST,
    GET_PERMISSIONS_SUCCESS,
    GET_PERMISSIONS_FAILURE,
} from "../const"


const request = () => ({type: GET_PERMISSIONS_REQUEST})

const success = (permissions) => ({
    type: GET_PERMISSIONS_SUCCESS,
    payload: {permissions},
})
const failure = () => ({type: GET_PERMISSIONS_FAILURE})


export const receivePermissions = () => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receivePermissionsData} = await getPermissions()
        dispatch(success(receivePermissionsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
