import React from "react"
import IconButton from "@mui/material/IconButton"
import CloseButtonIcon from "@mui/icons-material/Close"


const TableSidePanel = ({ onClose, children }) => {
    return (
        <div className="ns-table-sidepanel">
            <div className="ns-table-sidepanel-header">
                <IconButton onClick={onClose} disableRipple >
                    <CloseButtonIcon/>
                </IconButton>
            </div>
            <div className="ns-table-sidepanel-content">
                {children}
            </div>
        </div>

    )
}

export default TableSidePanel