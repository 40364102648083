import React, {useState} from "react"
import InfoIcon from "@mui/icons-material/Info"
import {getColorByHoursType} from "../../../utils/other.utils"
import {changeDateFormat} from "../../../utils/datetime.utils"
import TimeOffUsageInfoPopup from "../../TimeOffBalances/components/TimeOffUsageInfoPopup"
import Popup from "reactjs-popup"


const InfoCards = ({day, week, month, overtimes, unpaid_day_off, user, timeOffTabActive=false}) => {
    const [showTimeOffUsagePopup, setShowTimeOffUsagePopup] = useState(false)

    const InfoCard = ({title, value}) => (
        <div className="time-tracker-content-info-card">
            <span className="t-h3">{title}</span>
            <h1 className="t-h1">{value}</h1>
        </div>
    )

    return (
        <div className="time-tracker-content-info">
            {timeOffTabActive ?
                <>
                    <div className="time-tracker-content-info-card profile-info-card-custom-hover" onClick={() => setShowTimeOffUsagePopup(true)}>
                        <InfoIcon className="time-tracker-content-info-card-time-off-icon clickable"/>
                        <h3 className="t-h3 profile-font-leading">TIME OFF LEFT</h3>
                        <h1 className="t-h1"> {`${user.time_off_left} day${user.time_off_left !== 1 ? "s": ""}`} </h1>

                        {user?.next_time_off_request &&
                            <div className="time-tracker-content-info-card-time-off-wrapper">
                                <li style={{backgroundColor: getColorByHoursType(user.next_time_off_request.type, user.next_time_off_request.approved)}} className="dot-types">
                                </li>
                                <span className="t-s4 time-tracker-content-info-card-time-off-period">
                                    {`${changeDateFormat(user.next_time_off_request.start_date, "YYYY-MM-DD", "DD.MM")}${
                                        user.next_time_off_request.start_date !== user.next_time_off_request.end_date
                                            ? `-${changeDateFormat(user.next_time_off_request.end_date, "YYYY-MM-DD", "DD.MM")}`
                                            : ""
                                    }`} {user.next_time_off_request.count > 1 ? ` (${user.next_time_off_request.count} days)` : "(1 day)"}
                                </span>
                                <span
                                    className={`t-s4 time-tracker-content-info-card-time-off-${
                                        user.next_time_off_request.approved === null
                                            ? "in-review"
                                            : (user.next_time_off_request.approved === true)
                                                ? "approved"
                                                : "declined"
                                    }`}
                                >
                                    {user.next_time_off_request.approved === null
                                        ? "In review"
                                        : user.next_time_off_request.approved === true
                                            ? "Approved"
                                            : "Declined"
                                    }
                                </span>
                            </div>
                        }
                    </div>
                    <InfoCard title="UNPAID DAY OFF" value={`${unpaid_day_off} day${unpaid_day_off > 1 ? "s" : ""}`}/>
                    <Popup open={showTimeOffUsagePopup} onClose={() => setShowTimeOffUsagePopup(false)} modal>
                        {close => (
                            <TimeOffUsageInfoPopup
                                year={new Date().getFullYear()}
                                employee={user}
                                close={close}
                            />
                        )}
                    </Popup>
                </>
                :
                <>
                    <InfoCard title="DAY TOTAL" value={`${day.current}/${day.total}`}/>
                    <InfoCard title="WEEK TOTAL" value={`${week.current}/${week.total}`}/>
                    <InfoCard title="MONTH TOTAL" value={`${month.current}/${month.total}`}/>
                    <InfoCard title="MONTH OVERTIMES" value={overtimes}/>
                </>}
        </div>
    )
}

export default InfoCards
