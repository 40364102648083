import {
    GET_PURCHASE_REQUESTS_REQUEST,
    GET_PURCHASE_REQUESTS_SUCCESS,
    GET_PURCHASE_REQUESTS_FAILURE,
    GET_NEXT_PAGE_PURCHASE_REQUESTS_REQUEST
} from "../const"
import {getPurchaseRequests} from "../api"

const request = () => ({ type: GET_NEXT_PAGE_PURCHASE_REQUESTS_REQUEST })

const searchRequest = () => ({ type: GET_PURCHASE_REQUESTS_REQUEST })

const success = (purchaseRequests) => ({
    type: GET_PURCHASE_REQUESTS_SUCCESS,
    payload: {purchaseRequests},
})

const failure = () => ({ type: GET_PURCHASE_REQUESTS_FAILURE })


export const receivePurchaseRequests = (params) => async (dispatch) => {
    dispatch(searchRequest())
    try {
        const {data: receivePurchaseRequestsData} = await getPurchaseRequests(params)

        dispatch(success(receivePurchaseRequestsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPagePurchaseRequests = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receivePurchaseRequestsData} = await getPurchaseRequests(params)

        dispatch(success(receivePurchaseRequestsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}