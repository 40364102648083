import {createSlice} from "@reduxjs/toolkit"


export const shopSlice = createSlice({
    name: "shop",
    initialState: {
        confettiFired: false,
        confettiDisabled: false,
    },
    reducers: {
        setConfettiFired: (state) => {
            state.confettiFired = true
        },
        disableConfetti: (state) => {
            state.confettiDisabled = true
        },
    },
    selectors: {
        getConfettiFired: (state) => state.confettiFired,
        getConfettiDisabled: (state) => state.confettiDisabled,
    }
})

export const { setConfettiFired, disableConfetti } = shopSlice.actions
export const { getConfettiFired, getConfettiDisabled } = shopSlice.selectors
