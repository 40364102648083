import React from "react"
import Popup from "reactjs-popup"

import DeletePublicDayOffPopup from "./DeletePublicDayOffPopup"
import CreateEditPublicDayOffPopup from "./CreateEditPublicDayOffPopup"

import InfoPopup from "../../../components/InfoPopup"

import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"

import {formatDate} from "../../../utils/datetime.utils"
import {isDateInFuture} from "../../../utils/datetime.utils"
import Protected from "../../../components/Protected/Protected"
import { EmptyResult } from "../../../toolkits/EmptyResult/EmptyResult"
import {PERMISSIONS} from "../../../constants/permissions"
import {DD_MM_YYYY} from "../../../constants/other"

const PublicDayOffsTable = ({
    publicDayOffs,
    refreshTable,
    setUserMessage,
}) => {
    const publicDayOffsList = publicDayOffs.map((dayOff, i) => {
        const popupParams = {
            publicDayOff: dayOff,
            setUserMessage: setUserMessage,
            refreshTable: refreshTable,
        }
        return (
            <tr key={`${i} ${dayOff.id}`} className="custom-scrollable-table-row">
                <td>{formatDate(dayOff.date, DD_MM_YYYY)}</td>
                <td>
                    <Popup trigger={<span className="cell-limited-text clickable"> {dayOff.name} </span>} modal>
                        {close => (
                            <InfoPopup
                                title="National Day Off"
                                value={dayOff.name}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                <td>
                    {isDateInFuture(dayOff.date) && 
                        <Protected permissions={[PERMISSIONS.WRITE_CALENDAR_EVENTS]}>
                            <>
                                <Popup trigger={<span className="custom-scrollable-table-link">edit</span>} modal>
                                    {close => (
                                        <CreateEditPublicDayOffPopup
                                            {...popupParams}
                                            close={close}
                                        />
                                    )}
                                </Popup>
                                <Popup trigger={<span className="custom-scrollable-table-link">delete</span>} modal>
                                    {close => (
                                        <DeletePublicDayOffPopup
                                            {...popupParams}
                                            close={close}
                                        />
                                    )}
                                </Popup>
                            </>
                        </Protected>
                    }
                </td>
            </tr>
        )})

    return (
        <CustomScrollableTable>
            <table className="public-day-offs-content-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span">DATE</span></th>
                        <th><span className="custom-scrollable-table-head-span">NAME</span></th>
                        <th><span className="custom-scrollable-table-head-span">ACTIONS</span></th>
                    </tr>
                </thead>
                <tbody>
                    {publicDayOffs.length > 0 ? publicDayOffsList : <EmptyResult/>}
                </tbody>
            </table> 
        </CustomScrollableTable>
    )
}

export default PublicDayOffsTable
