import React, {useContext, useState} from "react"
import {PERMISSIONS} from "../../../../constants/permissions"
import Protected from "../../../../components/Protected/Protected"
import PenIcon from "@mui/icons-material/Create"
import {formatDate} from "../../../../utils/datetime.utils"
import {DD_MM_YYYY} from "../../../../constants/other"
import {EmptyCard} from "../../../../toolkits/EmptyResult/EmptyCard"
import {useSelector} from "react-redux"
import {ThemeContext} from "../../../../styles/theme-context"
import Popup from "reactjs-popup"
import SetUserLastDatePopup from "../Popups/SetUserLastDatePopup"

const LastDayCard = ({user, isMyProfile, setUserMessage, receiveUserInfo}) => {
    const [showSetUserLastDatePopup, setShowSetUserLastDatePopup] = useState(false)
    const {hasPermission} = useSelector((state) => state.profile)
    const theme = useContext(ThemeContext)

    return (
        <>
            {hasPermission(PERMISSIONS.WRITE_ARCHIVED_USER) || user.last_date ?
                <div onClick={() => setShowSetUserLastDatePopup(true)} className={`profile-info-card ${hasPermission(PERMISSIONS.WRITE_USER) ? `clickable ${theme.profileCardHover}` : ""}`}>
                    <Protected permissions={[PERMISSIONS.WRITE_ARCHIVED_USER]} accessForAdmin>
                        <PenIcon onClick={() => setShowSetUserLastDatePopup(true)} className="profile-info-card-pen-icon clickable"/>
                        {/* <span className="t-b3 profile-info-card-changed">Changed by: Some Name (24.12.2020)</span> */}
                    </Protected>

                    <h3 className="t-h3 profile-font-leading">LAST DAY</h3>
                    <h1 className="t-h1">
                        { user.last_date ? formatDate(user.last_date, DD_MM_YYYY) : <EmptyCard>No date</EmptyCard>}
                    </h1>
                </div> : <></>}

            <Popup open={showSetUserLastDatePopup} closeOnDocumentClick onClose={() => setShowSetUserLastDatePopup(false)} modal>
                <SetUserLastDatePopup
                    close={setShowSetUserLastDatePopup}
                    day={user.last_date}
                    userId={user.id}
                    setUserMessage={setUserMessage}
                    receiveUserInfo={() => receiveUserInfo(user.id, isMyProfile, true)}
                />
            </Popup>
        </>
    )
}

export default LastDayCard