import React, {useContext, useState} from "react"
import {PERMISSIONS} from "../../../../constants/permissions"
import Protected from "../../../../components/Protected/Protected"
import PenIcon from "@mui/icons-material/Create"
import {useSelector} from "react-redux"
import {ThemeContext} from "../../../../styles/theme-context"
import Popup from "reactjs-popup"
import EnglishLevelPopup from "../Popups/EnglishLevelPopup"
import {EmptyCard} from "../../../../toolkits/EmptyResult/EmptyCard"

const EnglishLevelCard = ({user, isMyProfile, setUserMessage, receiveUserInfo}) => {
    const [editEnglish, setEditEnglish] = useState(false)
    const {hasPermission} = useSelector((state) => state.profile)
    const theme = useContext(ThemeContext)

    return (
        <>
            <div onClick={() => hasPermission(PERMISSIONS.WRITE_USER) && setEditEnglish(true)} className={`profile-info-card ${hasPermission(PERMISSIONS.WRITE_USER) ? `clickable ${theme.profileCardHover}` : ""}`}>
                <Protected permissions={[PERMISSIONS.WRITE_USER]} accessForAdmin>
                    <PenIcon onClick={() => setEditEnglish(true)} className="profile-info-card-pen-icon clickable"/>
                </Protected>

                <h3 className="t-h3 profile-font-leading">ENGLISH LEVEL</h3>
                <h1 className="t-h1"> {user.english_level ? user.english_level : <EmptyCard>No level</EmptyCard>} </h1>
            </div>

            <Popup open={editEnglish} closeOnDocumentClick onClose={() => setEditEnglish(false)} modal>
                <EnglishLevelPopup
                    close={setEditEnglish}
                    currentLevel={user.english_level}
                    receiveUserInfo={() => receiveUserInfo(user.id, isMyProfile, true)}
                    setUserMessage={setUserMessage}
                    userId={user.id}
                />
            </Popup>
        </>
    )
}

export default EnglishLevelCard