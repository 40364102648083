import rootReducer from "./reducer"
import {configureStore} from "@reduxjs/toolkit"
import {productsApi} from "../api/products"
import {equipmentApi} from "../api/equipment"
import {projectsApi} from "../api/projects"
import {clientsApi} from "../api/clients"
import {employeePositionsApi} from "../api/employeePositions"
import {coinsRelatedApi, salaryRelatedApi, timeRelatedApi} from "../api/base"
import {auditLogsApi} from "../api/auditLogs"
import {usersApi} from "../api/users"
import {rolesApi} from "../api/roles"

/**
 * @desc Store that lets you read state, dispatch actions, and subscribe to changes.
 * @const
 * @type {object}
 */
const store = configureStore(
    {
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: true,
                immutableCheck: false,
                serializableCheck: false,
            }).concat([
                auditLogsApi.middleware,
                productsApi.middleware,
                equipmentApi.middleware,
                coinsRelatedApi.middleware,
                timeRelatedApi.middleware,
                usersApi.middleware,
                salaryRelatedApi.middleware,
                projectsApi.middleware,
                clientsApi.middleware,
                rolesApi.middleware,
                employeePositionsApi.middleware,
            ]),
    }

)

window.store = store

export default store
