import {API_V1_URL} from "../../constants/other"
import http from "../../http"

const USERS_URL = `${API_V1_URL}/users/`
const PERFORMANCE_REVIEWS_URL = `${API_V1_URL}/performance-reviews/`


export const getUserPerformanceReviews = (user_id, params) => http.get(`${USERS_URL}${user_id}/performance-reviews`, {params})

export const getAllPerformanceReviews = (params) => http.get(PERFORMANCE_REVIEWS_URL, {params})

export const createPerformanceReview = (data) => http.post(PERFORMANCE_REVIEWS_URL, {data})

export const updatePerformanceReviewById = (performanceReviewId, data) => http.put(`${PERFORMANCE_REVIEWS_URL}${performanceReviewId}`, {data})

export const deletePerformanceReviewById = (performanceReviewId) => http.delete(`${PERFORMANCE_REVIEWS_URL}${performanceReviewId}`)
