import React from "react"
import {KeyboardArrowRight} from "@mui/icons-material"
import {PurchaseRequestsRoute} from "../../../routes" // 4
import {useCustomHistory} from "../../../hooks/useCustomHistory"

const PurchaseHistoryCard = ({user}) => {
    const history = useCustomHistory()

    return (
        <div onClick={() => history.push(`/purchase-history/${user.id}`, {title: PurchaseRequestsRoute.name})} className="purchase-history-card">
            <span className="t-s6">Purchase History</span>
            <KeyboardArrowRight />
        </div>
    )
}

export default PurchaseHistoryCard