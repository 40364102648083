import "./index.scss"

import React, {useEffect, useMemo, useState} from "react"

import Popup from "reactjs-popup"
import Helmet from "react-helmet"
import {useDispatch, useSelector} from "react-redux"

import {generateCancelToken} from "../../http"

import {receivePublicDayOffs, receivePublicDayOffsUniqueYears} from "./actions/publicDayOffsActions"

import Protected from "../../components/Protected/Protected"
import PublicDayOffsTable from "./components/PublicDayOffsTable"
import CreateEditPublicDayOffPopup from "./components/CreateEditPublicDayOffPopup"

import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import DDListYear from "../../toolkits/DDListYear"

import {endOfMonth, formatDate, startOfMonth} from "../../utils/datetime.utils"


import {PERMISSIONS} from "../../constants/permissions"
import useCalendarData from "../../hooks/useCalendarData"


const PublicDayOffs = () => {  
    const dispatch = useDispatch()

    const [date, setDate] = useState(new Date())
    const [userMessage, setUserMessage] = useState(null)
    const [searchKey, setSearchKey] = useState()
    const [showCreatePublicDayOffPopup, setShowCreatePublicDayOffPopup] = useState(false)
    const [year, setYear] = useState({value: new Date().getFullYear(), label: new Date().getFullYear()})

    const [cancelToken, setCancelToken] = useState(null)

    const auth = useSelector((state) => state.auth)
    const {
        publicDayOffs,
        uniqueYears,
        tableLoading,
    } = useSelector((state) => state.publicDayOffs)

    const calendarDataParams = useMemo(() => {
        const validDate = date instanceof Date && !isNaN(date) ? date : new Date(date) 
        return {
            start_date: formatDate(startOfMonth(validDate)),
            end_date: formatDate(endOfMonth(validDate)),
        }
    }, [date])
    

    useCalendarData({ userId: auth.user.uid, params: calendarDataParams })


    const getPublicDayOffs = (newYear) => {
        const params = {
            name: searchKey,
            year: newYear ? newYear.value : year.value,
        }
        dispatch(
            receivePublicDayOffs(params, generateCancelToken(cancelToken, setCancelToken))
        )
    }

    const refreshTable = (date) => {
        setDate(date ? new Date(date) : new Date())
        getPublicDayOffs()
        dispatch(receivePublicDayOffsUniqueYears())
    }

    useEffect(() => {
        refreshTable()
    }, [])

    useEffect(() => {
        if (searchKey !== undefined) {
            getPublicDayOffs()
        }
    }, [searchKey])

    return (
        <>
            <Helmet>
                <title> National Days Off - Newsoft Inside </title>
            </Helmet>
            <div className="public-day-offs">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="public-day-offs-header">
                    <div className="public-day-offs-header-search">
                        <SearchForm onChange={setSearchKey} debounceMs={500} />
                    </div>
                    <Protected permissions={[PERMISSIONS.WRITE_CALENDAR_EVENTS]}>
                        <div className="public-day-offs-header-button">
                            <SmallButton onClick={() => setShowCreatePublicDayOffPopup(true)}> add new national day off </SmallButton>
                        </div>
                    </Protected>
                </div>
                <div className="public-day-offs-select">
                    <div className="public-day-offs-select-content">
                        <DDListYear
                            defaultValue={year}
                            options={uniqueYears.sort((a, b) => b.year - a.year).map((x) => ({value: x.year, label: x.year}))}
                            onChange={(newYear) => {
                                setYear(newYear)
                                getPublicDayOffs(newYear)
                            }}
                        />
                    </div>
                </div>
                <Protected permissions={[PERMISSIONS.READ_CALENDAR_EVENTS]}>
                    {tableLoading ? <StyledCircularProgress/> :
                        <div className="public-day-offs-content">
                            <PublicDayOffsTable
                                publicDayOffs={publicDayOffs}
                                refreshTable={refreshTable}
                                setUserMessage={setUserMessage}
                            />
                        </div>
                    }
                </Protected>
            </div>

            <Popup open={showCreatePublicDayOffPopup} closeOnDocumentClick={false} onClose={() => setShowCreatePublicDayOffPopup(false)} modal>
                {close => (
                    <CreateEditPublicDayOffPopup
                        refreshTable={refreshTable}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>
    )
}

export default PublicDayOffs
