import {
    GET_EMPLOYEES_FAILURE,
    GET_EMPLOYEES_REQUEST,
    GET_EMPLOYEES_SUCCESS,
    GET_NEXT_PAGE_EMPLOYEES_REQUEST
} from "../const"

/**
 * @desc Initial state of employees reducer.
 * @const
 * @type {object}
 * @default
 */
const initialState = {
    employees: [],
    total: 0,
    loading: true,
    tableLoading: true,
}

/**
 * @desc Handlers of employees reducer for changing state.
 * @const
 * @type {object}
 */
const handlers = {
    [GET_NEXT_PAGE_EMPLOYEES_REQUEST]: (state) => ({...state, tableLoading: true}),
    
    [GET_EMPLOYEES_REQUEST]: (state) => ({...state, loading: true, employees: [], total: 0}),
    [GET_EMPLOYEES_SUCCESS]: (state, {payload: {employees}}) => ({
        ...state,
        total: employees.total,
        employees: [...state.employees, ...employees.items],
        tableLoading: false,
        loading: false,
    }),
    [GET_EMPLOYEES_FAILURE]: (state) => ({...state, tableLoading: false}),
    
    DEFAULT: (state) => state,
}

/**
 * @desc [Reducer] Function for changing employees global state.
 * Pass state, action
 * @param {object} state - object with current info about employees data
 * @param {object} action - instructions for changing employees global state
 * @return {object} - updated object with current info about employees
 */
const employeesReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default employeesReducer
  