import React, { useEffect, useState } from "react"

import {COUPON_ERROR_MESSAGES, INVALID_COUPON_MESSAGE, SUCCESS_CODE} from "../../constants/other"
import { useLocation, useHistory} from "react-router-dom"
import { Loading } from "../../components/Loading/Loading"
import { CACHED_IMAGES, ERROR_CODE } from "../../constants/other"
import NewcoinsReceivedPopup from "../../components/Greetings/Newcoins/NewcoinsReceivedPopup"
import { ShopRoute } from "../../routes"

import "./index.scss"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import {useClaimCouponMutation} from "../../api/coupons"

const ClaimCoupon = () => {
    const [claimCoupon] = useClaimCouponMutation()
    const [loading, setLoading] = useState(true)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get("code")
    const history = useHistory()
    const [coupon, setCoupon] = useState()
    const [statusCode, setStatusCode] = useState()
    const [message, setMessage] = useState()

    useEffect(() => {
        if (!code) {
            setStatusCode(ERROR_CODE)
            setMessage(INVALID_COUPON_MESSAGE)
        } else {
            handleSubmit()
        }
        setTimeout(function() {
            setLoading(false)
        }, 1000)
    }, [])

    const handleSubmit = () => {
        claimCoupon({code: code})
            .unwrap()
            .then((response) => {
                setCoupon(response.data)
                setStatusCode(SUCCESS_CODE)
                setMessage("Success!")
            })
            .catch((error) => {
                setStatusCode(ERROR_CODE)
                setMessage(COUPON_ERROR_MESSAGES[error.message] || "Something went wrong!")
            })
    }

    if (loading) {
        return (
            <div className="coupon-claim">
                <Loading />
            </div>
        )
    }

    return (
        <div className="coupon-claim">
            {statusCode === SUCCESS_CODE ? (
                <div className="coupon-claim-success">
                    <NewcoinsReceivedPopup
                        amount={coupon.coins_reward}
                        reason={coupon.message}
                        buttonText="SHOP NOW"
                        close={() => history.push(ShopRoute.path)}
                    />
                </div>
            ) : (
                <div className="coupon-claim-error">
                    <img src={CACHED_IMAGES.LogoNS} id="newsoft-logo" alt="newsoft logo"/>
                    <p className="t-h2">{message}</p>
                    <div className="coupon-claim-error-return-button">
                        <SmallButton
                            type="submit"
                            btnType="secondary"
                            onClick={() => history.push("/")}
                        >Go To Profile</SmallButton>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ClaimCoupon
