import CustomLink from "../../../../toolkits/CustomLink"
import {SalaryHistoryRoute} from "../../../../routes"
import {PERMISSIONS} from "../../../../constants/permissions"
import {SensitiveInfo} from "../../../../components/SensitiveInfo/SensitiveInfo"
import React, {useContext} from "react"
import {useSelector} from "react-redux"
import {ThemeContext} from "../../../../styles/theme-context"
import {EmptyCard} from "../../../../toolkits/EmptyResult/EmptyCard"
import InfoIcon from "@mui/icons-material/Pending"
import PenIcon from "@mui/icons-material/Create"

const BonusesCard = ({bonuses, user}) => {
    const {hasPermission} = useSelector((state) => state.profile)
    const theme = useContext(ThemeContext)

    return (
        <CustomLink
            className="t-s3"
            to={{
                pathname: SalaryHistoryRoute.path,
                state: {
                    user,
                    tab: "Bonuses",
                }
            }}>
            <div className={`profile-info-card ${hasPermission(PERMISSIONS.READ_BONUS) ? `clickable  ${theme.profileCardHover}` : ""}`}>
                <div>
                    <h3 className="t-h3 profile-font-leading">BONUSES</h3>

                    <div className="profile-info-card-links">
                        {bonuses.length > 0
                            ? <InfoIcon className="profile-info-card-improvements clickable"/>
                            : hasPermission(PERMISSIONS.READ_BONUS) && <PenIcon className="profile-info-card-pen-icon clickable"/>
                        }
                    </div>

                    <h1 className="t-h1">
                        <SensitiveInfo className="sensitive-info">
                            {bonuses.length > 0 ?
                                bonuses.map((bonus, index) =>
                                    <div key={index} className={bonuses.length > 1 ? "profile-info-bonuses-row t-s1" : ""}>
                                    +{bonus.amount} {bonus.currency}
                                    </div>
                                ) : <EmptyCard>No bonus</EmptyCard>}
                        </SensitiveInfo>
                    </h1>
                </div>
            </div>
        </CustomLink>
    )
}

export default BonusesCard