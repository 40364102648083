import React from "react"

import {useDeleteEmployeePositionMutation} from "../../../api/employeePositions"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"


const DeletePositionPopup = ({position, setUserMessage, onSubmit, close}) => {
    const [deleteEmployeePosition] = useDeleteEmployeePositionMutation()

    const handleSubmit = () => {

        deleteEmployeePosition(position.id)
            .unwrap()
            .then(() => {
                onSubmit()
                close()
                setUserMessage({message: "Employee Position was successfully deleted!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to delete "${position.name}"?`} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeletePositionPopup
