import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import Popup from "reactjs-popup"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import {receiveCoupons, receiveNextPageCoupons} from "./actions/CouponsActions"

import Protected from "../../components/Protected/Protected"
import CouponsTable from "./components/CouponsTable"
import CreateUpdateCouponPopup from "./components/CreateUpdateCouponPopup"

import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {COUPON_STATUSES, PAGE_SIZE} from "../../constants/other"
import {PERMISSIONS} from "../../constants/permissions"
import FilterPanel from "../../toolkits/FilterPanel"


const Coupons = () => {
    const dispatch = useDispatch()

    const {
        tableLoading,
        coupons,
        total,
    } = useSelector((state) => state.coupons)
    
    const [currentPage, setCurrentPage] = useState(1)
    const [userMessage, setUserMessage] = useState(null)
    const couponStatuses = ["All", COUPON_STATUSES.ACTIVE.label, COUPON_STATUSES.CLAIMED.label, COUPON_STATUSES.DEACTIVATED.label]
    const [statusFilter, setStatusFilter] = useState(couponStatuses[0])
    const [searchKey, setSearchKey] = useState()
    const [addNewCouponPopup, setAddNewCouponPopup] = useState(false)

    const getParams = (page) => ({
        page: page || currentPage,
        query: searchKey,
        size: PAGE_SIZE,
        statuses: statusFilter === "All" ? null : statusFilter.toUpperCase()
    })

    const getCoupons = (page) => {
        const params = getParams(page)

        dispatch(receiveCoupons(params))
        setCurrentPage((page || currentPage) + 1)
    }

    const getNextCoupons = () => {
        const params = getParams()

        dispatch(receiveNextPageCoupons(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => { 
        getCoupons(1)
    }, [statusFilter])

    useEffect(() => {
        if (searchKey !== undefined) {
            getCoupons(1)
        }
    }, [searchKey])

    return (
        <>
            <Helmet>
                <title>Coupons - Newsoft Inside</title>
            </Helmet>

            <div className="coupons">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="coupons-header">
                    <SearchForm onChange={setSearchKey} debounceMs={500} />
                    <Protected permissions={[PERMISSIONS.WRITE_COUPONS]}>
                        <div className="coupons-header-button">
                            <SmallButton onClick={() => setAddNewCouponPopup(true)}> ADD NEW COUPON </SmallButton>
                        </div>
                    </Protected>
                </div>
                <div className="coupons-filter">
                    <FilterPanel
                        title={"Type"}
                        options={couponStatuses}
                        selectedOption={statusFilter}
                        setOptionFilter={setStatusFilter}
                    />
                </div>
                <InfiniteScroll
                    pageStart={0}
                    next={getNextCoupons}
                    hasMore={!tableLoading && coupons.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={coupons.length}
                    useWindow={false}
                >
                    {tableLoading ? <StyledCircularProgress/> :
                        <div className="coupons-content">
                            <CouponsTable
                                coupons={coupons}
                                refreshTable={() => getCoupons(1)}
                                setUserMessage={setUserMessage}
                            />
                        </div>
                    }
                </InfiniteScroll>
            </div>

            <Popup open={addNewCouponPopup} closeOnDocumentClick={false} onClose={() => setAddNewCouponPopup(false)} modal>
                {close => (
                    <CreateUpdateCouponPopup
                        refreshTable={() => getCoupons(1)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>

        </>)
}

export default Coupons
