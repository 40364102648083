import React from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"

import { INFO_ALERT, SUCCESS_CODE, MIN_ALERT_DURATION } from "../../constants/other"

// size = "inherit" | "small | "medium" | "large"
const CopyButton = ({value, setUserMessage, type, children, size="inherit" }) => {
    return (
        <CopyToClipboard text={value} onCopy={() =>
            setUserMessage?.({message: `${type} copied!`, code: SUCCESS_CODE, type: INFO_ALERT, duration: MIN_ALERT_DURATION})}>
            {children || <span className="clickable copy-icon"><ContentCopyIcon fontSize={size} /></span>}
        </CopyToClipboard>
    )
}

export default CopyButton
