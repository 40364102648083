export const GET_EQUIPMENT_REQUEST = "GET_EQUIPMENT_REQUEST"
export const GET_EQUIPMENT_SUCCESS = "GET_EQUIPMENT_SUCCESS"
export const GET_EQUIPMENT_FAILURE = "GET_EQUIPMENT_FAILURE"
export const GET_NEXT_PAGE_EQUIPMENT_REQUEST = "GET_NEXT_PAGE_EQUIPMENT_REQUEST"

export const ORDERING_KEYS = {
    EQUIPMENT: "equipment",
    CATEGORY: "category",
    USER: "user",
}
