import {getAllPerformanceReviews, getUserPerformanceReviews} from "../api"

import {
    GET_PERFORMANCE_REVIEWS_FAILURE,
    GET_PERFORMANCE_REVIEWS_REQUEST,
    GET_PERFORMANCE_REVIEWS_SUCCESS,
    GET_NEXT_PAGE_PERFORMANCE_REVIEWS_REQUEST,
} from "../const"


const request = () => ({type: GET_NEXT_PAGE_PERFORMANCE_REVIEWS_REQUEST})

const searchRequest = () => ({type: GET_PERFORMANCE_REVIEWS_REQUEST})

const success = (performanceReviews) => ({
    type: GET_PERFORMANCE_REVIEWS_SUCCESS,
    payload: {performanceReviews},
})

const failure = () => ({type: GET_PERFORMANCE_REVIEWS_FAILURE})


export const receivePerformanceReviews = (params) => async (dispatch) => {
    dispatch(searchRequest())
    try {
        const {data: receivePerformanceReviewsData} = await getAllPerformanceReviews(params)

        dispatch(success(receivePerformanceReviewsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveUsersPerformanceReviews = (user_id, params) => async (dispatch) => {
    dispatch(searchRequest())
    try {
        const {data: receivePerformanceReviewsData} = await getUserPerformanceReviews(user_id, params)
        dispatch(success(receivePerformanceReviewsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPagePerformanceReviews = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receivePerformanceReviewsData} = await getAllPerformanceReviews(params)
        dispatch(success(receivePerformanceReviewsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageUsersPerformanceReviews = (user_id, params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receivePerformanceReviewsData} = await getUserPerformanceReviews(user_id, params)
        dispatch(success(receivePerformanceReviewsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
