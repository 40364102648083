import React from "react"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

const BuyProductPopup = ({product, amount, selectedProductVariant, handleBuy, close}) => {
    return (
        <CustomPopup
            text_title={
                `Do you want to buy ${amount > 1 ?  `${amount} ` : ""}${product.name} ${selectedProductVariant.formatted_options} 
                 with total price ${amount * product.raw_price}?`}
            isSubtitle
            isAgreePopup
        >
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={() => handleBuy(close)}> BUY </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default BuyProductPopup