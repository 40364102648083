import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import Popup from "reactjs-popup"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroller"

import {receiveNextPageRoles, receiveRoles} from "./actions/rolesActions"
import {receivePermissions} from "./actions/permissionsActions"

import Protected from "../../components/Protected/Protected"
import RolesTable from "./components/RolesTable"
import CreateEditRolePopup from "./components/CreateEditRolePopup"

import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {PAGE_SIZE} from "../../constants/other"
import {PERMISSIONS} from "../../constants/permissions"


const Roles = () => {
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1)

    const {
        roles,
        loading,
        total,
        tableLoading
    } = useSelector((state) => state.roles)

    const [userMessage, setUserMessage] = useState(null)
    const [showCreateRolePopup, setShowCreateRolePopup] = useState(false)


    const getRoles = (page) => {
        page = page ? page : currentPage
        const params = {
            size: PAGE_SIZE,
            page,
        }

        dispatch(receiveRoles(params))
        setCurrentPage(page + 1)
    }

    const getNextRoles = () => {
        const params = {
            size: PAGE_SIZE,
            page: currentPage,
        }

        dispatch(receiveNextPageRoles(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        getRoles(1)
        dispatch(receivePermissions())
    }, [])

    return (
        <>
            <Helmet>
                <title> Roles - Newsoft Inside </title>
            </Helmet>
            <div className="roles">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="roles-header">
                    <Protected permissions={[PERMISSIONS.WRITE_ROLE]}>
                        <div className="roles-header-button">
                            <SmallButton onClick={() => setShowCreateRolePopup(true)}> add new role </SmallButton>
                        </div>
                    </Protected>
                </div>

                <InfiniteScroll
                    pageStart={0}
                    loadMore={getNextRoles}
                    hasMore={!tableLoading && roles.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    datalength={roles.length}
                >
                    {loading ? <StyledCircularProgress/> :
                        <div className="roles-content">
                            <RolesTable
                                data={roles}
                                refreshTable={() => getRoles(1)}
                                setUserMessage={setUserMessage}
                            />
                        </div>
                    }
                </InfiniteScroll>
            </div>

            <Popup open={showCreateRolePopup} closeOnDocumentClick={false} onClose={() => setShowCreateRolePopup(false)} modal>
                {close => (
                    <CreateEditRolePopup
                        create={true}
                        refreshTable={() => getRoles(1)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>
    )
}

export default Roles
