import {
    GET_PUBLIC_DAY_OFFS_REQUEST,
    GET_PUBLIC_DAY_OFFS_SUCCESS,
    GET_PUBLIC_DAY_OFFS_FAILURE,
    GET_PUBLIC_DAY_OFFS_UNIQUE_YEARS_REQUEST,
    GET_PUBLIC_DAY_OFFS_UNIQUE_YEARS_SUCCESS,
    GET_PUBLIC_DAY_OFFS_UNIQUE_YEARS_FAILURE,
} from "../const"

const defaultYears = [{year: new Date().getFullYear()}, ]

const initialState = {
    publicDayOffs: [],
    uniqueYears: defaultYears,
    tableLoading: true,
}

const handlers = {
    [GET_PUBLIC_DAY_OFFS_REQUEST]: (state) => ({...state, tableLoading: true, publicDayOffs: []}),
    [GET_PUBLIC_DAY_OFFS_SUCCESS]: (state, {payload: {publicDayOffs}}) => ({
        ...state,
        publicDayOffs,
        tableLoading: false,
    }),
    [GET_PUBLIC_DAY_OFFS_FAILURE]: (state) => ({...state}),

    [GET_PUBLIC_DAY_OFFS_UNIQUE_YEARS_REQUEST]: (state) => ({...state, uniqueYears: defaultYears}),
    [GET_PUBLIC_DAY_OFFS_UNIQUE_YEARS_SUCCESS]: (state, {payload: {uniqueYears}}) => ({
        ...state,
        uniqueYears,
    }),
    [GET_PUBLIC_DAY_OFFS_UNIQUE_YEARS_FAILURE]: (state) => ({...state}),

    DEFAULT: (state) => state,
}

const publicDayOffsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default publicDayOffsReducer
