import {createApi} from "@reduxjs/toolkit/query/react"
import {baseQuery, DEFAULT_REFETCH_ON_MOUNT_INTERVAL} from "./base"


export const rolesApi = createApi({
    reducerPath: "rolesApi",
    baseQuery,
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    tagTypes: ["Roles"],
    endpoints: (builder) => ({
        createRole: builder.mutation({
            query: (data) => ({path: "roles/", method: "POST", data}),
            invalidatesTags: ["Roles"]
        }),
        updateRole: builder.mutation({
            query: ({id, data}) => ({path: `roles/${id}`, method: "PUT", data}),
            invalidatesTags: ["Roles"]
        }),
        deleteRole: builder.mutation({
            query: (id) => ({path: `roles/${id}`, method: "DELETE"}),
            invalidatesTags: ["Roles"]
        }),

    })
})

export const {
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
} = rolesApi
