import "./styles/common.scss"
import "./styles/index.scss"

import React, {useEffect, useState} from "react"
import {BrowserRouter as Router} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import ReactGA from "react-ga"

import Routes from "./routes/Routes"

import {firebaseGetUser} from "./pages/Auth/actions/authActions"

import {getAuthorizedUserData} from "./utils/localstorage.utils"
import {cacheImages} from "./utils/other.utils"
import { ANALYTICS_TRACKER_ID, WEBSITE_THEMES } from "./constants/other"
import { ThemeContext, themes } from "./styles/theme-context"
import {Loading} from "./components/Loading/Loading"


ReactGA.initialize(ANALYTICS_TRACKER_ID)

const App = () => {
    const dispatch = useDispatch()
    
    const [loading, setLoading] = useState(true)

    const user = useSelector((state) => state.auth.user)
    const {websiteTheme} = useSelector((state) => state.settings)
    const isUserAuthorized = getAuthorizedUserData()
    const shouldRenderRoutes = (isUserAuthorized && user) || !isUserAuthorized

    useEffect(() => {
        dispatch(firebaseGetUser())
        cacheImages(setLoading)
    }, [])

    return (!loading && shouldRenderRoutes) ? (
        <ThemeContext.Provider value={themes[websiteTheme.value] ? themes[websiteTheme.value] : themes[WEBSITE_THEMES.STANDARD]}>
            <Router>
                <Routes/>
            </Router>
        </ThemeContext.Provider>
    ) : <div className="loading-div"><Loading /></div>
}

export default App
