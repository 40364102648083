import React from "react"

import CreatableDDList from "../../../toolkits/CreatableDDList/CreatableDDList"

import useClients from "../../../hooks/useClients"
import {useCreateClientMutation} from "../../../api/clients"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"

export const ClientsDDList = ({setClient, setUserMessage, ...props}) => {
    const {clients} = useClients({})
    const [createClient] = useCreateClientMutation()
    return (
        <CreatableDDList
            text_label="Client"
            placeholder=""
            listOptions={clients.map((client) => ({value: client.id, label: client.name}))}
            onChange={client => {setClient({...client})}}
            onCreateOption={(new_option) => {
                const data = {name: new_option}

                createClient(data)
                    .unwrap()
                    .then((response) => {
                        setClient({value: response.data.id, label: response.data.name})
                        setUserMessage({message: "Client was successfully created!", code: SUCCESS_CODE})
                    })
                    .catch(error => {
                        console.log(error)
                        setUserMessage({message: error.message, code: ERROR_CODE})
                    })
            }}
            {...props}
        />
    )
}

export default ClientsDDList
