import React, {useState} from "react"

import {useCreateEmployeePositionMutation, useUpdateEmployeePositionMutation} from "../../../api/employeePositions"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import TextInput from "../../../toolkits/TextInput/TextInput"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"


const CreateUpdatePositionPopup = ({position, setUserMessage, onSubmit, close}) => {
    const [name, setName] = useState(position ? position.name : "")

    const [createEmployeePosition] = useCreateEmployeePositionMutation()
    const [updateEmployeePosition] = useUpdateEmployeePositionMutation()

    const handleSubmit = () => {
        const data = {
            name,
        }

        if (position) {
            updateEmployeePosition({id: position.id, data})
                .unwrap()
                .then(() => {
                    onSubmit()
                    close()
                    setUserMessage({
                        message: "Employee Position was successfully updated!",
                        code: SUCCESS_CODE
                    })
                })
                .catch(error => {
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        } else {
            createEmployeePosition(data)
                .unwrap()
                .then(() => {
                    onSubmit()
                    close()
                    setUserMessage({message: "Employee Position was successfully created!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        }
    }

    return (
        <CustomPopup text_title={`${position ? "Edit" : "Create"} Position`}>
            <TextInput
                text_label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!name}
                    >{position ? "SAVE" : "CREATE"}</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default CreateUpdatePositionPopup
