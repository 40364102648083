import {
    GET_EQUIPMENT_REQUEST,
    GET_EQUIPMENT_SUCCESS,
    GET_EQUIPMENT_FAILURE,
    GET_NEXT_PAGE_EQUIPMENT_REQUEST,
} from "../const"


const initialState = {
    equipment: [],
    total: 0,
    tableLoading: false,
}
  
const handlers = {
    [GET_EQUIPMENT_REQUEST]: (state) => ({...state, tableLoading: true, equipment: [], total: 0}),
    [GET_EQUIPMENT_SUCCESS]: (state, {payload: {equipment}}) => ({
        ...state,
        equipment: [...state.equipment, ...equipment.items],
        total: equipment.total,
        tableLoading: false,
    }),
    [GET_EQUIPMENT_FAILURE]: (state) => ({...state}),
    
    [GET_NEXT_PAGE_EQUIPMENT_REQUEST]: (state) => ({...state}),
    
    DEFAULT: (state) => state,
}

const equipmentReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
  
export default equipmentReducer
