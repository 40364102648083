import {coinsRelatedApi} from "./base"


export const coinsTransactionsApi = coinsRelatedApi.injectEndpoints({
    endpoints: (builder) => ({
        createCoinsTransaction: builder.mutation({
            query: (data) => ({path: "newcoins-transactions/", method: "POST", data}),
            invalidatesTags: ["CoinsTransactions"]
        }),
    })
})

export const {
    useCreateCoinsTransactionMutation,
} = coinsTransactionsApi
