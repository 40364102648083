import "./index.scss"
import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroller"
import {generateCancelToken} from "../../http"
import {
    receiveNewcoinsBalances,
    receiveNextPageNewcoinsBalances
} from "./actions/newcoinsBalancesActions"
import NewcoinsBalanceTable from "./components/NewcoinsBalanceTable"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import {PAGE_SIZE} from "../../constants/other"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import GiveTakeNewcoinsPopup from "./components/GiveTakeNewcoinsPopup"
import Popup from "reactjs-popup"
import EditAutoGivenSettingsPopup from "./components/EditAutoGivenSettingsPopup"


const NewcoinsBalance = () => {
    const dispatch = useDispatch()

    const {
        loading,
        tableLoading,
        newcoinsBalances,
        total
    } = useSelector((state) => state.newcoinsBalances)

    const [cancelToken, setCancelToken] = useState(null)
    const [userMessage, setUserMessage] = useState(null)
    const [giveNewcoinsPopup, setGiveNewcoinsPopup] = React.useState(false)
    const [selectedUsers, setSelectedUsers] = React.useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [searchKey, setSearchKey] = useState()

    const getNewcoinsBalances = (page) => {
        page = page ? page : currentPage
        const params = {
            size: PAGE_SIZE,
            page,
            user_name: searchKey,
        }

        dispatch(receiveNewcoinsBalances({
            params,
            cancelToken: generateCancelToken(cancelToken, setCancelToken),
        }))
        setCurrentPage(page + 1)
    }

    const getNextNewcoinsBalances = () => {
        const params = {
            size: PAGE_SIZE,
            page: currentPage,
            username: searchKey,
        }

        dispatch(receiveNextPageNewcoinsBalances(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        getNewcoinsBalances(1)
    }, [])

    useEffect(() => {
        if (searchKey !== undefined) {
            getNewcoinsBalances(1)
        }
    }, [searchKey])

    return (
        <>
            <Helmet>
                <title>Newcoins Balance - Newsoft Inside</title>
            </Helmet>
            <div className="newcoins-balance">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="newcoins-balance-header">
                    <SearchForm onChange={setSearchKey} debounceMs={500} />
                    <div>
                        <Popup trigger={<span className="newcoins-balance-header-settings-label t-b1">Auto-given settings</span>} modal>
                            {close =>
                                <EditAutoGivenSettingsPopup setUserMessage={setUserMessage} close={close}/>
                            }
                        </Popup>
                        <SmallButton disabled={selectedUsers?.length === 0} onClick={() => setGiveNewcoinsPopup(true)}>Give Newcoins</SmallButton>
                    </div>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={getNextNewcoinsBalances}
                    hasMore={!tableLoading && newcoinsBalances.length < total}
                    loader={<StyledCircularProgress key="circular-progress"/>}
                    datalength={newcoinsBalances.length}
                >
                    {loading ? <StyledCircularProgress/> : <div className="newcoins-balance-content">
                        <NewcoinsBalanceTable
                            data={newcoinsBalances}
                            selectedUsers={selectedUsers}
                            setSelectedUsers={setSelectedUsers}
                        />
                    </div>}
                </InfiniteScroll>
            </div>
            <Popup open={giveNewcoinsPopup} onClose={() => setGiveNewcoinsPopup(false)}>
                {close => (
                    <GiveTakeNewcoinsPopup
                        give={true}
                        selectedUsers={selectedUsers}
                        refreshTable={() => getNewcoinsBalances(1)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                )}
            </Popup>
        </>
    )
}

export default NewcoinsBalance
