import React from "react"
import {Redirect, Route, Switch} from "react-router-dom"

import {ErrorRoute, routes} from "./index"
import ProtectedRoute from "./ProtectedRoute"

const renderComponent = (Layout, Component, props) => {
    if (Layout !== null) {
        return (
            <Layout>
                <Component {...props} />
            </Layout>
        )
    } else {
        return (
            <Component {...props} />
        )
    }
}

const routeWithSubRoutes = (routes) =>
    routes.map(({children, path, layout: Layout, component: Component, protectedRoute, permissions, settingsRequired}, index) => {
        return children ? (
            // Route item with children
            children.map(({path, layout: Layout, component: Component, protectedRoute}, index) => (
                protectedRoute ?
                    <ProtectedRoute key={index} path={path} exact render={props => renderComponent(Layout, Component, props)}/> :
                    <Route key={index} path={path} exact render={props => renderComponent(Layout, Component, props)}/>
            ))) : (
            // Route item without children
            protectedRoute ?
                <ProtectedRoute
                    permissions={permissions}
                    settingsRequired={settingsRequired}
                    key={index}
                    path={path}
                    exact
                    render={props => renderComponent(Layout, Component, props)}
                /> :
                <Route key={index} path={path} exact render={props => renderComponent(Layout, Component, props)}/>
        )
    })
const Routes = () => (
    <Switch>
        {routeWithSubRoutes(routes)},
        <Redirect to={{pathname: ErrorRoute.path, state: {errorInfo: "404 error"}}}/>
    </Switch>
)

export default Routes
