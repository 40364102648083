import {
    GET_PURCHASE_REQUESTS_SUCCESS,
    GET_PURCHASE_REQUESTS_REQUEST,
    GET_PURCHASE_REQUESTS_FAILURE,
    GET_NEXT_PAGE_PURCHASE_REQUESTS_REQUEST
} from "../const"

const initialState = {
    purchaseRequests: [],
    total: 0,
    tableLoading: true,
    loading: true,
}

const handlers = {
    [GET_PURCHASE_REQUESTS_REQUEST]: (state) => ({ ...state, tableLoading: true, purchaseRequests: [], total: 0 }),
    [GET_PURCHASE_REQUESTS_SUCCESS]: (state, { payload: {purchaseRequests} }) => ({
        ...state,
        purchaseRequests: [...state.purchaseRequests, ...purchaseRequests.items],
        total: purchaseRequests.total,
        tableLoading: false,
        loading: false,
    }),
    [GET_PURCHASE_REQUESTS_FAILURE]: (state) => ({ ...state, tableLoading: false, loading: false }),

    [GET_NEXT_PAGE_PURCHASE_REQUESTS_REQUEST]: (state) => ({ ...state, tableLoading: true }),

    DEFAULT: (state) => state,
}

const purchaseRequestsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default purchaseRequestsReducer