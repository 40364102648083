import React from "react"
import CopyButton from "../../Miscellaneous/CopyButton"


const EllipsisText = ({ value, copy=false, setUserMessage }) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{textOverflow: "ellipsis", overflow: "hidden"}}>
                <span>{value}</span>
            </div>
            {copy ? (<CopyButton value={value} setUserMessage={setUserMessage} type={"Value"}/>) : null}
            
        </div>

    )
}

export default EllipsisText