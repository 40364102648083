import EyeImage from "@mui/icons-material/RemoveRedEyeRounded"
import ClosedEyeImage from "@mui/icons-material/VisibilityOffRounded"
import GoogleIcon from "../../../assets/images/GoogleIcon.svg"


import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"

import {signIn, signInWithGoogle} from "../actions/authActions"

import AlertMessage from "../../../toolkits/AlertMessage/AlertMessage"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {isAdmin} from "../../../utils/permission.utils"

import {ERROR_CODE} from "../../../constants/other"
import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {DashboardRoute, ProfileRoute} from "../../../routes"


export const LoginForm = ({setShowChangePassword}) => {
    const history = useCustomHistory()
    const dispatch = useDispatch()

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [passwordShown, setPasswordShown] = useState(false)

    const [alertMessage, setAlertMessage] = useState(null)
    const errorMessage = useSelector((state) => state.auth.loginError)
    const isFirstLogin = useSelector((state) => state.auth.claims?.is_first_login)

    const [loginState, setLoginState] = useState(
        {
            googleLoading: false, googleDisabled: false,
            passwordLoading: false, passwordDisabled: false,
        })


    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoginState((prevState) => ({
            ...prevState,
            passwordLoading: true,
            googleDisabled: true,
        }))

        try {
            await dispatch(signIn({email: email, password: password}))
        } catch {
            setLoginState({
                ...loginState,
                passwordLoading: false,
                googleDisabled: false,
            })
        }
    }

    const handleGoogleLogin = async (e) => {
        setLoginState({
            ...loginState,
            googleLoading: true,
            passwordDisabled: true,
        })
        e.preventDefault()
        try {
            await dispatch(signInWithGoogle())
        } catch {
            setLoginState({
                ...loginState,
                googleLoading: false,
                passwordDisabled: false,
            })
            return
        }
    }

    useEffect(() => {
        if (errorMessage === "OK") {
            if (isFirstLogin) {
                setShowChangePassword(isFirstLogin)
            } else {
                history.push(isAdmin() ? DashboardRoute.path : ProfileRoute.path, {}, ProfileRoute.path)
            }
        }
        setAlertMessage(
            errorMessage && errorMessage !== "OK" ? {message: errorMessage, error: ERROR_CODE} : null
        )
    }, [errorMessage])

    return (
        <div className="auth-wrapper-login">
            <div className="auth-wrapper-login-title">Login to Newsoft Inside</div>
            <div>
                {alertMessage &&
                    <AlertMessage setMessage={setAlertMessage} message={alertMessage?.message} code={alertMessage?.error}/>
                }
            </div>
            <form className="auth-wrapper-login-form" onSubmit={handleSubmit} autoComplete="on">
                <div className="popup-bottom auth-wrapper-login-form-google">
                    <SmallButton
                        id="signInWithGoogle"
                        disabled={loginState.googleDisabled}
                        loading={loginState.googleLoading}
                        onClick={handleGoogleLogin}
                    >
                        <span>
                            <img src={GoogleIcon} className="google-icon" alt="Google Icon"/>
                            Login with Google
                        </span>
                    </SmallButton>
                </div>
                <div className="auth-wrapper-login-form-divider-text">
                    <div className="line"></div>
                    <span>or login with your email</span>
                    <div className="line"></div>
                </div>
                <label className="auth-wrapper-login-form-email-label t-s3" htmlFor="email-field">Email address</label>
                <input autoComplete="email" className="auth-wrapper-login-form-email-field" id="emailId" type="email" placeholder="Email address" onChange={e => setEmail(e.target.value)}/>
                <div className="auth-wrapper-login-form-password">
                    <label className="auth-wrapper-login-form-password-label t-s3" htmlFor="password-field">Password</label>
                    <input
                        autoComplete="current-password" className="auth-wrapper-login-form-password-field"
                        type={passwordShown ? "text" : "password"}
                        placeholder="Password"
                        onChange={e => setPassword(e.target.value)}
                    />
                    <div
                        className="auth-wrapper-login-form-password-visible"
                        onClick={() => setPasswordShown(!passwordShown)}>
                        {passwordShown ?
                            <EyeImage className="auth-wrapper-login-form-password-eye-icon" alt="visible image"/> :
                            <ClosedEyeImage className="auth-wrapper-login-form-password-eye-icon" alt="invisible image"/>}
                    </div>
                </div>
                <div className="auth-wrapper-login-form-actions">
                    <Link
                        to={"/reset-password"}
                        className="auth-wrapper-login-form-actions-forgot-password t-b1"
                    >Forgot password</Link>
                </div>
                <div className="popup-bottom auth-wrapper-login-form-submit">
                    <SmallButton
                        id="signIn"
                        type="submit"
                        btnType="secondary"
                        disabled={loginState.passwordDisabled}
                        loading={loginState.passwordLoading}
                        extendLoadingStyles={{color: "#F57F17"}}
                    >Login</SmallButton>
                </div>
            </form>
        </div>
    )
}
