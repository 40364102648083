import React from "react"
import Popup from "reactjs-popup"
import {useSelector} from "react-redux"

import Protected from "../../../components/Protected/Protected"
import EmployeesBoxPopup from "../../../components/EmployeesBoxPopup"
import AssignProjectPopup from "./AssignProjectPopup"
import CreateEditProjectPopup from "./CreateEditProjectPopup"
import DeleteProjectPopup from "./DeleteProjectPopup"
import UnassignProjectPopup from "./UnassignProjectPopup"

import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"
import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"


import {PERMISSIONS} from "../../../constants/permissions"


const ProjectsTable = ({
    projects,
    onSubmit,
    setUserMessage,
}) => {
    const {hasPermission} = useSelector((state) => state.profile)
    
    const projectsList = projects.map((project, i) => {
        const popupParams = {
            project: project,
            onSubmit: onSubmit,
            setUserMessage: setUserMessage,
        }

        const tableActions = (
            <>
                <Protected permissions={[PERMISSIONS.ASSIGN_PROJECT]}>
                    {project.team.length > 0 &&
                        <Popup trigger={<span className="custom-scrollable-table-link">unassign</span>} modal>
                            {close => (
                                <UnassignProjectPopup
                                    {...popupParams}
                                    close={close}
                                />
                            )}
                        </Popup>
                    }

                    <Popup trigger={<span className="custom-scrollable-table-link">assign</span>} modal>
                        {close => (
                            <AssignProjectPopup
                                {...popupParams}
                                close={close}
                            />
                        )}
                    </Popup>
                </Protected>

                <Protected permissions={[PERMISSIONS.WRITE_PROJECT]}>
                    <Popup trigger={<span className="custom-scrollable-table-link">edit</span>} modal>
                        {close => (
                            <CreateEditProjectPopup
                                {...popupParams}
                                close={close}
                            />
                        )}
                    </Popup>

                    <Popup trigger={<span className="custom-scrollable-table-link">delete</span>} modal>
                        {close => (
                            <DeleteProjectPopup
                                {...popupParams}
                                close={close}
                            />
                        )}
                    </Popup>
                </Protected>
            </>
        )

        return (
            <tr key={`${i} ${project.id}`}
                className="custom-scrollable-table-row projects-content-table-row">
                <td className="projects-content-table-first-column">
                    <span className="t-s1">{project.name}</span>
                </td>
                <td className="projects-content-table-team-column">
                    <Popup trigger={
                        <span className="cell-limited-text clickable">{
                            project.team.map(user =>`${user.first_name} ${user.last_name}`).join(", ")
                                ?? "No assigned employee"
                        }</span>
                    } modal>
                        {close => (
                            <EmployeesBoxPopup
                                title="Project team"
                                label="Assigned employees"
                                employees={project.team}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                <td className={`projects-content-table-last-column${!hasPermission(PERMISSIONS.ASSIGN_PROJECT) ? "-ext" : ""}`}>
                    {tableActions}
                </td>
            </tr>
        )
    })

    return (
        <CustomScrollableTable>
            <table className="projects-content-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span-first">NAME</span></th>
                        <th><span className="custom-scrollable-table-head-span projects-content-table-team-column">TEAM</span></th>
                        <th><span className="custom-scrollable-table-head-span">ACTIONS</span></th>
                    </tr>
                </thead>
                <tbody>
                    {projectsList.length > 0 ? projectsList : <EmptyResult/>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default ProjectsTable
