import "./index.scss"

import React, {useState} from "react"
import {Helmet} from "react-helmet"

import SalaryHistory from "./components/SalaryHistory"
import BonusHistory from "./components/BonusHistory"
import Protected from "../../components/Protected/Protected"


import {PERMISSIONS} from "../../constants/permissions"
import NewcoinsHistory from "./components/NewcoinsHistory"
import SettingsEnabled from "../../components/SettingsEnabled/SettingsEnabled"
import {PAYMENTS_TABS, SETTINGS} from "../../constants/other"


const Page = ({location: {state}}) => {
    const tabs = [PAYMENTS_TABS.SALARIES, PAYMENTS_TABS.BONUSES, PAYMENTS_TABS.NEWCOINS]
    const [selectedTab, setSelectedTab] = useState(state?.tab ? state.tab : PAYMENTS_TABS.SALARIES)

    const handleClick = (Tab) => {
        setSelectedTab(Tab)
    }

    const renderTabContent = (selectedTab) => {
        switch (selectedTab) {
        case PAYMENTS_TABS.SALARIES:
            return <SalaryHistory location={state}/>
        case PAYMENTS_TABS.BONUSES:
            return <BonusHistory location={state}/>
        case PAYMENTS_TABS.NEWCOINS:
            return <NewcoinsHistory location={state}/>
        }
    }

    return (
        <>
            <Helmet>
                <title>Salary history - Newsoft Inside</title>
            </Helmet>
            <Protected permissions={[
                PERMISSIONS.READ_BONUS,
                PERMISSIONS.READ_OWN_BONUS,
                PERMISSIONS.READ_SALARY,
                PERMISSIONS.READ_OWN_SALARY,
                PERMISSIONS.READ_NEWCOINS,
            ]}>
                <div className="payments-tab">{
                    tabs.map((tab, i) => {
                        return (
                            <Protected
                                key={`${tab.id} ${i} ${Math.random()}`}
                                permissions={
                                    tab === PAYMENTS_TABS.SALARIES ? [PERMISSIONS.READ_SALARY, PERMISSIONS.READ_OWN_SALARY] :
                                        tab === PAYMENTS_TABS.BONUSES ? [PERMISSIONS.READ_BONUS, PERMISSIONS.READ_OWN_BONUS] :
                                            [PERMISSIONS.READ_NEWCOINS]
                                }
                            >
                                <SettingsEnabled settingsNames={tab === PAYMENTS_TABS.NEWCOINS ? [SETTINGS.NEWCOINS_ENABLED] : []}>
                                    <button
                                        type="button"
                                        onClick={() => handleClick(tab)}
                                        className={`payments-tab-btn${selectedTab === tab ? "-selected" : ""}`}
                                    >
                                        {tab}
                                    </button>
                                </SettingsEnabled>
                            </Protected>
                        )
                    })}
                </div>
            </Protected>
            <div className="payments-barrier"/>
            {renderTabContent(selectedTab)}
        </>)
}

export default Page
