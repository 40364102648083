import React, {useEffect, useState} from "react"
import TextInput from "../../../toolkits/TextInput/TextInput"
import TitleIcon from "@mui/icons-material/Title"
import PaletteIcon from "@mui/icons-material/Palette"
import {DEFAULT_COLOR_PICKER_COLOR, PRODUCT_OPTION_TYPE} from "../../../constants/other"
import Popup from "reactjs-popup"
import AddOptionDetailsPopup from "./AddOptionDetailsPopup"
import CancelIcon from "@mui/icons-material/Cancel"
import ColorPicker from "../../../toolkits/ColorPicker"
import {getColorOptionValues} from "../../../utils/other.utils"

const SingleOptionCard = ({option, handleOptionChange, handleOptionRemove, handleOptionValueRemove, setUserMessage}) => {
    const [hasOptionDetails, setHasOptionDetails] = useState(option.details_name && option.details_image_url)
    const [showRemove, setShowRemove] = useState(false)
    const [hoveredValueIndex, setHoveredValueIndex] = useState(null)
    const [colors, setColors] = useState([])

    const handleValueChange = (index, value, color) => {
        let newValue = value
        if (option.type === PRODUCT_OPTION_TYPE.COLOR) {
            newValue = value + "|" + (color ? color : colors[index])
        }
        const newValues = option.values.map((val, i) => i === index ? newValue : val)
        handleOptionChange(option.position, "values", newValues)
    }

    const handleColorChange = (index, color) => {
        setColors(prevColors => {
            const newColors = prevColors.map((col, i) => i === index ? color : col)
            handleValueChange(index, getColorOptionValues(option.values[index]).text, color)
            return newColors
        })
    }

    const handleTypeChange = (newType) => {
        handleOptionChange(option.position, "type", newType)
    }

    const handleAddValue = () => {
        handleOptionChange(option.position, "values", [...option.values, ""])
        setColors(prevState => [...prevState, DEFAULT_COLOR_PICKER_COLOR])
    }

    useEffect(() => {
        if (option.details_name && option.details_image_url) {
            setHasOptionDetails(true)
        } else {
            setHasOptionDetails(false)
        }
    }, [option.details_name, option.details_image_url])

    useEffect(() => {
        if (colors.length === 0) {
            setColors(option.values.map(value => getColorOptionValues(value).color))
        }
    }, [option.values])

    return (
        <div className="create-product-left-options-inputs">
            <div onMouseLeave={() => setShowRemove(false)} className="create-product-left-options-inputs-name">
                <TextInput
                    text_label="Name"
                    placeholder="F.e. 'Size' or 'Color'"
                    value={option.name}
                    onChange={(e) => handleOptionChange(option.position, "name", e.target.value)}
                    onMouseOver={() => setShowRemove(true)}
                    style={{paddingRight: "4.5rem"}}
                />
                {showRemove &&
                    <CancelIcon onClick={() => handleOptionRemove(option.position)} className="create-product-left-options-inputs-remove-name"/>}
                <div className="create-product-left-options-inputs-type">
                    <TitleIcon className={`${option.type === PRODUCT_OPTION_TYPE.TEXT ? "active" : ""}`} onClick={() => handleTypeChange(PRODUCT_OPTION_TYPE.TEXT)} />
                    <PaletteIcon className={`${option.type === PRODUCT_OPTION_TYPE.COLOR ? "active" : ""}`} onClick={() => handleTypeChange(PRODUCT_OPTION_TYPE.COLOR)} />
                </div>
                <Popup
                    trigger={
                        <span className={`t-b4 create-product-left-options-inputs-value-label ${hasOptionDetails ? "active" : "inactive"}`}>
                            {hasOptionDetails ? option.details_name : "add option details"}
                        </span>}
                    closeOnDocumentClick={false}
                    modal>
                    {close => (
                        <AddOptionDetailsPopup
                            option={option}
                            handleOptionChange={handleOptionChange}
                            close={close}
                            setUserMessage={setUserMessage}
                        />)}
                </Popup>
            </div>
            <div className="create-product-left-options-inputs-value">
                {option.values.map((value, index) => {
                    const displayValue = getColorOptionValues(value).text
                    return(
                        <div onMouseLeave={() => setHoveredValueIndex(null)} className="create-product-left-options-inputs-value-item" key={index}>
                            <TextInput
                                text_label={index == 0 ? "Value" : ""}
                                placeholder="F.e. 'S' or 'White'"
                                value={displayValue}
                                onChange={(e) => handleValueChange(index, e.target.value)}
                                onMouseOver={() => setHoveredValueIndex(index)}
                            />
                            {option.type === PRODUCT_OPTION_TYPE.COLOR &&
                                <ColorPicker
                                    color={colors[index]}
                                    setColor={(color) => handleColorChange(index, color)}
                                    className="create-product-left-options-inputs-value-color"
                                />
                            }
                            {hoveredValueIndex === index && option.values.length > 1 &&
                                <CancelIcon
                                    onClick={() => handleOptionValueRemove(option.position, index)}
                                    className="create-product-left-options-inputs-remove-value"
                                />
                            }
                        </div>)
                })
                }
                <span className="t-b4 create-product-left-options-inputs-value-label" onClick={handleAddValue}>+Value</span>
            </div>
        </div>
    )
}

export default SingleOptionCard