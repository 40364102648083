import {Link, useHistory} from "react-router-dom"
import React from "react"
import {ProfileRoute} from "../../routes"


const CustomLink = ({children, to, activeTab, ...props}) => {
    const history = useHistory()

    return (
        <Link to={{
            ...to,
            state: {
                ...(to.state || {}),
                activeTab: activeTab || history.location.state?.activeTab || ProfileRoute.path,
            }
        }}
        {...props}>
            {children}
        </Link>
    )
}

export default CustomLink
