import React from "react"
import {Helmet} from "react-helmet"
import NSLogoImage from "../../assets/images/logo_newsoft.svg"
import SignatureOleh from "../../assets/images/signature_oleh.png"
import SignatureVova from "../../assets/images/signature_vova.png"

import "../../layouts/Navs/NavsLayout.scss"
import "./Targets.css"

const Targets = () => {
    return (
        <div>
            <Helmet>
                <title>Targets - Newsoft Inside</title>
            </Helmet>
            <div className="data">
                <div className="target-card">
                    <div className="mission-block">
                        <div className="title">
                            <img src={NSLogoImage} alt="newsoft logo"/>
                            <span className="title-text">Newsoft mission</span>
                            <img src={NSLogoImage} alt="newsoft logo"/>
                        </div>
                    </div>
                </div>
                <div className="target-card">
                    <p className="mission-block-title">Місія. Що хочемо зробити?</p>
                    <ol>
                        <li>
                            <span>Creating value.</span> Ми хочемо створювати велику цінність для всіх з ким взаємодіємо
                            (клієнти,
                            працівники, партнери).
                        </li>
                        <li>
                            <span>Win-Win cooperation.</span> Ми хочемо вирішувати будь які питання так щоб кожен був у
                            виграші(клієнти,
                            працівники, партнери). Ми хочемо щоб кожен хто взаємодіє з нами бачив як він виграє від
                            цього.
                            Також ми думаємо що це єдиний правильний підхід для побудови довгих та успішних відносин.
                        </li>
                        <li>
                            <span>Бізнес з моральними принципами.</span>
                        </li>
                        <li>
                            <span>Оборот понад 1 мілярд ($).</span> Спланувати що робити через багато років неможливо,
                            все сильно
                            змінюється, так само важко визначити, пояснити та оцінити рівень компанії який ми хочемо
                            досягти
                            без конкретних цифр. Тому ми можемо сказати, що наша глобальна ціль це компанія з оборотом
                            більше одного мільярда доларів. Це дозволить нам реалізовувати та впливати на те що хочемо у
                            світових масштабах.
                        </li>
                        <li>
                            <span>Business TOP100 of everything.</span> Ціль є досить абстрактною та полягає у тому, щоб
                            потрапляти у
                            ТОП100 за будь якими показниками. Це може стосуватись як умов праці так і
                            продажів/маркетингу.
                            ТОП100 є досить справедливим та об єктивним рівнем оцінки показників які не залежать від
                            часу та
                            трендів.
                        </li>
                    </ol>
                </div>
                <div className="target-card">
                    <div className="reason-block">
                        <p className="reason-block-title">Чому можемо зробити?</p>
                        <ol>
                            <li>
                                <span>Різниця в деталях.</span> Для того, щоб бути найкращим потрібно робити найкраще
                                окремі завдання.
                                Тому в нас має бути особливий і продуктивний підхід до будь чого. Ми маємо дивитись і
                                розуміти чим
                                ми робимо це краще за інших.
                            </li>
                            <li>
                                <span>Наші сильні сторони.</span> Працездатність, дисципліна, проактивність, віра у свій
                                успіх, бажання
                                постійного вдосконалення, команда.
                            </li>
                            <li>
                                <span>Абстрактний конкурент.</span> При будь якій дії в тебе завжди є конкурент який
                                робить те саме і
                                знаходиться там де ти. Той хто виконує завдання піднімається вверх, а хто не виконує
                                опускається
                                вниз. Потрібно розуміти що на вершині буде той хто виконає найбільшу кількість завдань.
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="target-card">
                    <div className="three-years-targets-block">
                        <p className="three-years-targets-block-title">Цілі на 3 роки (2021, 2022, 2023)</p>
                        <ol>
                            <li>
                                <span>Команда.</span> Побудувати команду людей які розділяють наші цілі, пріорітети,
                                сповнені енергії та
                                мотивації разом рухатись вперед.
                            </li>
                            <li>
                                <span>Кількість людей:</span> 70+
                            </li>
                            <li>
                                <span>Партнери(клієнти):</span> Працювати з крутими клієнтами та втілювати в життя
                                проекти світового
                                маштабу. Створювати цінність для цих клієнтів та обмінюватись досвідом.
                            </li>
                            <li>
                                <span>Environment:</span> Покращення умов праці, атмосфери та рівня корпоративів,
                                забезпечення хорошою
                                технікою, спортзал в офісі.
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="target-card">
                    <div className="current-year-targets-block">
                        <p className="current-year-targets-block-title">Цілі на 2021 рік</p>
                        <ol>
                            <li>
                                <span>Команда.</span> Не втрачати таланти. Рости якісно. Найняти HR/Office manager і
                                Sales Manager.
                            </li>
                            <li>
                                <span>Кількість людей:</span> 30+
                            </li>
                            <li>
                                <span>Партнери(клієнти):</span> Робити максимум для тих клієнтів які є зараз і системно
                                шукати нових. Ціль 2+
                                успішні sales дії в місяць.
                            </li>
                            <li>
                                <span>Сайт:</span> Запустити нову версію.
                            </li>
                            <li>
                                <span>Newsoft Inside.</span> Запустити.
                            </li>
                            <li>
                                <span>Офіс.</span> Максимально розширити офіс і постійно вдосконалювати.
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="footer">
                    <div className="row co-founders">
                        <div className="col-lg-6 co-founder-1">
                            <p>Oleh Roshka, Co-founder <img src={SignatureOleh} alt="sign"/></p>
                        </div>
                        <div className="col-lg-6 co-founder-2">
                            <p>Volodymyr Tkach, Co-founder <img src={SignatureVova} alt="sign"/></p>
                        </div>
                    </div>
                    <p className="powered-by">Powered by Newsoft</p>
                </div>
            </div>
        </div>
    )
}

export default Targets