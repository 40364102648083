import {getUsers} from "../api"
import {
    GET_EMPLOYEES_FAILURE,
    GET_EMPLOYEES_REQUEST,
    GET_EMPLOYEES_SUCCESS,
    GET_NEXT_PAGE_EMPLOYEES_REQUEST,
} from "../const"


const request = () => ({type: GET_NEXT_PAGE_EMPLOYEES_REQUEST})

const success = (employees) => ({
    type: GET_EMPLOYEES_SUCCESS,
    payload: {employees},
})
const failure = () => ({type: GET_EMPLOYEES_FAILURE})

const searchRequest = () => ({type: GET_EMPLOYEES_REQUEST})


export const receiveNextPageEmployees = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveEmployeesData} = await getUsers(params)

        dispatch(success(receiveEmployeesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveEmployees = (params, cancelToken) => async (dispatch) => {
    dispatch(searchRequest())
    try {
        const {data: receiveEmployeesData} = await getUsers(params, cancelToken)

        dispatch(success(receiveEmployeesData))
    } catch (e) {
        dispatch(failure(e))
    }
}
