import {
    GENERATE_RANDOM_SMILE,
    GET_CURRENT_USER_FAILURE,
    GET_CURRENT_USER_REQUEST,
    GET_CURRENT_USER_SUCCESS,
    GET_ME_FAILURE,
    GET_ME_REQUEST,
    GET_ME_SUCCESS,
    GET_USER_PROJECTS,
    GET_USER_ALL_PROJECTS,
    GET_USER_ONBOARDING_REQUEST,
    GET_USER_ONBOARDING_SUCCESS,
    GET_USER_ONBOARDING_FAILURE,
    UPDATE_ONBOARDING_REQUEST_SUCCESS,
    UPDATE_ONBOARDING_REQUEST_FAILURE,
    UPDATE_ONBOARDING_REQUEST,
    SET_ONBOARDING_ANIMATION,
    CHECK_FIRST_ONBOARDING_STEP,
} from "../const"
import {NUMBER_OF_SMILES, SMILES} from "../../../constants/other"


const initialState = {
    myInfo: null,
    currentUser: null,
    userEquipment: [],
    userOnboarding: null,
    projects: [],
    allProjects: [],
    privateModeSmile: SMILES[Math.floor(Math.random()*SMILES.length)].repeat(NUMBER_OF_SMILES),
    showOnboardingAnimation: false,
    newcoins: 0,
    newcoinsSpent: 0,
    hasPermission: () => {}
}

const handlers = {
    [GET_ME_REQUEST]: (state) => ({...state}),
    [GET_ME_SUCCESS]: (state, {payload: {myInfo}}) => ({
        ...state,
        myInfo,
        hasPermission: (permission) => {
            const permission_names = myInfo.permissions.map((permission) => permission.permission_name)
            return permission_names.includes(permission) || null
        }
    }),
    [GET_ME_FAILURE]: (state) => ({...state}),

    [GET_CURRENT_USER_REQUEST]: (state) => ({...state}),
    [GET_CURRENT_USER_SUCCESS]: (state, {payload: {currentUser}}) => ({
        ...state,
        currentUser
    }),
    [GET_CURRENT_USER_FAILURE]: (state) => ({...state}),

    [GET_USER_ONBOARDING_REQUEST]: (state) => ({...state}),

    [GET_USER_ONBOARDING_SUCCESS]: (state, {payload: {userOnboarding}}) => ({
        ...state,
        userOnboarding: userOnboarding
    }),

    [GET_USER_ONBOARDING_FAILURE]: (state) => ({...state}),

    [UPDATE_ONBOARDING_REQUEST]: (state) => ({...state}),

    [UPDATE_ONBOARDING_REQUEST_SUCCESS]: (state, {payload: {onboardingRequest}}) => ({
        ...state,
        userOnboarding: onboardingRequest
    }),

    [UPDATE_ONBOARDING_REQUEST_FAILURE]: (state) => ({...state}),

    [SET_ONBOARDING_ANIMATION]: (state, {payload: {value}}) => ({
        ...state,
        showOnboardingAnimation: value
    }),

    [CHECK_FIRST_ONBOARDING_STEP]: (state, {payload: {stepKey}}) => ({
        ...state,
        userOnboarding: {
            ...state.userOnboarding,
            steps: {
                ...state.userOnboarding.steps,
                [stepKey]: {
                    ...state.userOnboarding.steps[stepKey],
                    checked: true,
                },
            },
        }
    }),

    [GET_USER_PROJECTS]: (state, {payload: {receiveProjectsData}}) => ({
        ...state,
        projects: receiveProjectsData
    }),
    [GET_USER_ALL_PROJECTS]: (state, {payload: {receiveAllProjectsData}}) => ({
        ...state,
        allProjects: receiveAllProjectsData
    }),
    [GENERATE_RANDOM_SMILE]: (state, {payload: {privateModeSmile}}) => ({
        ...state,
        privateModeSmile
    }),
    DEFAULT: (state) => state,
}

const profileReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default profileReducer
