import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import {useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import Protected from "../../components/Protected/Protected"
import TimeOffRequestsTable from "./components/TimeOffRequestsTable"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {STATUS_FILTERS} from "../../constants/other"
import {useCustomHistory} from "../../hooks/useCustomHistory"
import {PERMISSIONS} from "../../constants/permissions"
import {usePaginatedQuery} from "../../hooks/common/usePaginatedQuery"
import {useGetTimeOffRequestsQuery, useGetUserTimeOffRequestsQuery} from "../../api/timeOffRequests"
import useCurrentUserId from "../../hooks/useCurrentUserId"
import FilterPanel from "../../toolkits/FilterPanel"


const TimeOffRequests = () => {
    const history = useCustomHistory()
    const userId = useCurrentUserId()

    const {hasPermission} = useSelector((state) => state.profile)

    const [searchKey, setSearchKey] = useState(history.location.state?.username ? history.location.state.username : "")
    const [userMessage, setUserMessage] = useState(null)
    const [statusFilter, setStatusFilter] = useState(STATUS_FILTERS.ALL)

    const readAllAccess = hasPermission(PERMISSIONS.READ_TIME_OFF_REQUEST)

    const {
        items: timeOffRequests,
        reset: timeOffRequestsReset,
        isLoading: timeOffRequestsLoading,
        loadNextPage: timeOffRequestsLoadNextPage,
        hasMore: timeOffRequestsHasMore,
        changeParams: timeOffRequestsChangeParams,
    } = usePaginatedQuery(useGetTimeOffRequestsQuery, { defaultParams: {username: searchKey}, skip: !readAllAccess })

    const {
        items: userTimeOffRequests,
        reset: userTimeOffRequestsReset,
        loadNextPage: userTimeOffRequestsLoadNextPage,
        isLoading: userTimeOffRequestsLoading,
        hasMore: userTimeOffRequestsHasMore,
        changeParams: userTimeOffRequestsChangeParams,
    } = usePaginatedQuery(useGetUserTimeOffRequestsQuery, { defaultParams: {username: searchKey, userId }, skip: readAllAccess })

    const reset = readAllAccess ? timeOffRequestsReset : userTimeOffRequestsReset
    const loadNextPage = readAllAccess ? timeOffRequestsLoadNextPage : userTimeOffRequestsLoadNextPage
    const isLoading = readAllAccess ? timeOffRequestsLoading : userTimeOffRequestsLoading
    const hasMore = readAllAccess ? timeOffRequestsHasMore : userTimeOffRequestsHasMore
    const items = readAllAccess ? timeOffRequests : userTimeOffRequests
    const changeParams = readAllAccess ? timeOffRequestsChangeParams : userTimeOffRequestsChangeParams

    useEffect(() => {
        reset()
        changeParams?.({
            username: searchKey ? searchKey : undefined,
            ...(statusFilter !== STATUS_FILTERS.ALL && { status: statusFilter })
        })
    }, [searchKey, statusFilter])

    return (
        <>
            <Helmet>
                <title>Time Off requests - Newsoft Inside</title>
            </Helmet>
            <div className="time-off-requests">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className={`time-off-requests-header${!hasPermission(PERMISSIONS.READ_TIME_OFF_REQUEST) ? "-user" : ""}`}>
                    <Protected permissions={[PERMISSIONS.READ_TIME_OFF_REQUEST]}>
                        <SearchForm onChange={setSearchKey} debounceMs={500} />
                    </Protected>
                    <FilterPanel
                        title="Status"
                        options={Object.values(STATUS_FILTERS)}
                        selectedOption={statusFilter}
                        setOptionFilter={setStatusFilter}
                    />
                </div>

                <InfiniteScroll
                    pageStart={0}
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={items.length}
                    useWindow={false}
                >
                    {items.length > 0 && (<div className="time-off-requests-content">
                        <TimeOffRequestsTable
                            statusFilter={statusFilter}
                            timeOffRequests={items}
                            refreshTable={reset}
                            setUserMessage={setUserMessage}
                        />
                    </div>)}
                    {isLoading && <StyledCircularProgress/>}
                </InfiniteScroll>
            </div>
        </>
    )
}

export default TimeOffRequests
