import "./index.scss"

import React, {useCallback, useContext, useMemo, useState} from "react"
import {EmptyResult} from "../../toolkits/EmptyResult/EmptyResult"
import {ThemeContext} from "../../styles/theme-context"
import DateTimeColumn from "./components/DateTimeColumn"
import InfiniteScroll from "react-infinite-scroll-component"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import EllipsisText from "./components/EllipsisText"
import SidePanel from "./components/SidePanel"


const getTableWrapperClass = ({ sidePanelOpen }) => {
    const classes = ["ns-table-wrapper"]
    if (sidePanelOpen) {
        classes.push("ns-table-wrapper-row-selected")
    }
    return classes.join(" ")
}

const getTableClass = ({ variant }) => {
    const classes = ["ns-table"]
    if (variant === "small") {
        classes.push("ns-table-small")
    }
    return classes.join(" ")
}

const getRowClass = ({ selectable, selected, variant }) => {
    const classes = ["custom-scrollable-table-row", "ns-table-row"]
    if (selectable) {
        classes.push("ns-table-row-selectable")
    }

    if (selected) {
        classes.push("ns-table-row-selected")
    }

    if (variant === "small") {
        classes.push("ns-table-row-small")
    }

    return classes.join(" ")
}

const Cell = ({ item, column, setUserMessage }) => {
    const value = column.get ? column.get(item) : item[column.field]

    if (column.type === "datetime") {
        return <DateTimeColumn date={value}/>
    } else if (column.type === "copy") {
        return <EllipsisText value={value} copy={true} setUserMessage={setUserMessage}/>
    } else if (column.type === "custom") {
        return value
    }

    return <EllipsisText value={value}/>
}

// For SidePanelComponent to work properly the parent component should have some static height (100vh for example) and overflow: auto
const Table = ({
    items,
    columns,
    loadNextPage,
    loading,
    hasMore,
    setUserMessage,
    SidePanelComponent,
    onRowClick,
    variant="normal", // normal | small
    endMessage,
}) => {
    const theme = useContext(ThemeContext)

    const [selectedRow, setSelectedRow] = useState(null)

    const keyField = useMemo(() => columns.find((column) => column.isKey)?.field, [columns])

    if (!keyField) {
        console.error("Key field is missing")
    }

    const handleRowClick = useCallback((row) => {
        if (onRowClick) {
            onRowClick(row)
        } else if (SidePanelComponent) {
            if (row[keyField] === selectedRow?.[keyField]) {
                setSelectedRow(null)
            } else {
                setSelectedRow(row)
            }
        } else {
            return null
        }
    }, [onRowClick, SidePanelComponent, selectedRow, setSelectedRow, keyField])

    const body = useMemo(() => {
        if (items.length === 0 && !loading) {
            return <EmptyResult/>
        }

        const selectable = SidePanelComponent || onRowClick

        return (
            <tbody>
                {items.map((item) => (
                    <tr
                        key={item[keyField]}
                        className={getRowClass({ selectable, selected: item[keyField] === selectedRow?.[keyField], variant })}
                        onClick={() => handleRowClick(item)}
                    >
                        {columns.filter((column) => !column.hidden).map((column, i) => (
                            <td className={i === 0 ? "ns-table-first-column" : undefined} key={column.field}>
                                <Cell column={column} item={item} setUserMessage={setUserMessage}/>
                            </td>
                        ))}
                    </tr>))}
            </tbody>
        )
    }, [items, loading, setUserMessage, SidePanelComponent, onRowClick, selectedRow, keyField, variant])

    const header = useMemo(() => (
        <thead>
            <tr>
                {columns.filter((column) => !column.hidden).map((column) => (
                    <th key={column.field}>
                        <span className="custom-scrollable-table-head-span">{column.title}</span>
                    </th>
                ))}
            </tr>
        </thead>
    ), [columns])

    const sidePanelOpen = selectedRow && SidePanelComponent

    const tableId = `ns-table-${columns.map((column) => (column.field?.[0] || "") + (column.title?.[0] || "")).join("")}`

    return (
        <div className="ns-table-container" id={tableId}>
            <div className={getTableWrapperClass({sidePanelOpen})}>
                <InfiniteScroll
                    pageStart={0}
                    next={loadNextPage}
                    hasMore={hasMore}
                    scrollableTarget={tableId}
                    endMessage={ endMessage ? (
                        <p style={{ textAlign: "center" }}>
                            <b>{endMessage}</b>
                        </p>
                    ) : undefined}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={items.length}
                >
                    <div className={`custom-scrollable-table ${theme.tableClassName}`}>
                        <table className={getTableClass({variant })}>
                            {header}
                            {body}
                        </table>
                    </div>
                    {loading ? <CircularProgress key="circular-progress"/> : null}
                </InfiniteScroll>
            </div>
            {sidePanelOpen ? (
                <SidePanel onClose={() => setSelectedRow(null)}>
                    <SidePanelComponent row={selectedRow}/>
                </SidePanel>
            ) : null}
        </div>
    )
}

export default Table