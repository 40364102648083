import React from "react"
import {useSelector} from "react-redux"

import UserImage from "../../../assets/images/user_photo.png"

import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"
import {useCustomHistory} from "../../../hooks/useCustomHistory"
import {ProfileRoute} from "../../../routes"
import Checkbox from "../../../toolkits/Checkbox/Checkbox"
import Newcoin from "../../../toolkits/Newcoin"


const NewcoinsBalanceTable = ({data, selectedUsers, setSelectedUsers}) => {
    const history = useCustomHistory()
    const auth = useSelector((state) => state.auth)

    const profileClick = (id) => id === auth.user.uid ? history.push(ProfileRoute.path) : history.push(`/profile/${id}`)

    const handleCheckboxChange = (user) => {
        const isSelected = selectedUsers.some(selectedUser => selectedUser.id === user.id)
        if (isSelected) {
            setSelectedUsers(selectedUsers.filter(selectedUser => selectedUser.id !== user.id))
        } else {
            setSelectedUsers([...selectedUsers, {
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                image_url: user.image_url
            }])
        }
    }

    const isUserSelected = (userId) => {
        return selectedUsers.some(selectedUser => selectedUser.id === userId)
    }

    const selectAllToggle = () => {
        if (selectedUsers.length === data.length) {
            setSelectedUsers([])
        } else {
            setSelectedUsers(data.map(user => ({
                id: user.user.id,
                first_name: user.user.first_name,
                last_name: user.user.last_name,
                image_url: user.user.image_url
            })))
        }
    }

    const employeesList = data.map((balance) => {
        const user = balance.user
        return (
            <tr key={user.image_url} className="newcoins-balance-content-table-row custom-scrollable-table-row-user">
                <td>
                    <Checkbox
                        className="newcoins-balance-content-table-checkbox"
                        checked={isUserSelected(user.id)}
                        onClick={() => handleCheckboxChange(user)}
                    />
                </td>
                <td>
                    <div className="custom-scrollable-table-balance">
                        <span className="custom-scrollable-table-employee-image clickable" onClick={() => profileClick(balance.user.id)}>
                            <img src={balance.user.image_url ? balance.user.image_url : UserImage} alt="employee"/>
                        </span>
                        <span className="custom-scrollable-table-employee-name clickable" onClick={() => profileClick(balance.user.id)}>
                            {balance.user.first_name} {balance.user.last_name}
                        </span>
                    </div>
                </td>
                <td><span className="newcoins-balance-content-table-available-span">{balance.available} <Newcoin type="active"/></span></td>
                <td><span className="newcoins-balance-content-table-spent-span">{Math.abs(balance.spent)} <Newcoin type="withdrawn"/></span></td>
            </tr>
        )
    })

    return (
        <CustomScrollableTable>
            <table className="newcoins-balance-content-table">
                <thead>
                    <tr>
                        <th><Checkbox className="newcoins-balance-content-table-checkbox" checked={selectedUsers.length === data.length} onClick={selectAllToggle} /></th>
                        <th><span className="custom-scrollable-table-head-span-first">EMPLOYEE</span></th>
                        <th><span className="custom-scrollable-table-head-span">AVAILABLE</span></th>
                        <th><span className="custom-scrollable-table-head-span">SPENT</span></th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? employeesList : <EmptyResult/>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default NewcoinsBalanceTable
