import "./index.scss"
import "../../layouts/Navs/NavsLayout.scss"
import "../Roles/index.scss"

import React, {useEffect, useMemo, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import Popup from "reactjs-popup"
import {Helmet} from "react-helmet"
import Protected from "../../components/Protected/Protected"
import {Loading} from "../../components/Loading/Loading"
import {CropImagePopup} from "./components/UploadImage/CropImagePopup"
import ArchiveEmployeePopup from "./components/Popups/ArchiveEmployeePopup"
import DeleteEmployeePopup from "./components/Popups/DeleteEmployeePopup"
import EditEmployeePopup from "./components/Popups/EditEmployeePopup"
import {LogoutPopup} from "../Auth/components/LogoutPopup"

import {receiveCurrentUser, receiveMyInfo} from "./actions/userActions"
import {receiveUserOnboarding} from "./actions/onboardingActions"

import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import {isTrue} from "../../utils/other.utils"

import {
    DESKTOP_WIDTH,
} from "../../constants/other"

import {
    ErrorRoute,
} from "../../routes"
import {useCustomHistory} from "../../hooks/useCustomHistory"
import OnboardingCard from "./components/Cards/OnboardingCard"
import WelcomePopup from "../../components/Greetings/Onboarding/WelcomePopup"
import {useMediaQuery} from "react-responsive"
import {getUserViewedAs} from "../../utils/localstorage.utils"
import {ONBOARDING_STATUS} from "../../constants/onboarding"
import {PERMISSIONS} from "../../constants/permissions"
import SalaryCard from "./components/Cards/SalaryCard"
import PerformanceReviewCard from "./components/Cards/PerformanceReviewCard"
import MultipleSalaryCard from "./components/Cards/MultipleSalaryCard"
import BonusesCard from "./components/Cards/BonusesCard"
import NewcoinsCard from "./components/Cards/NewcoinsCard"
import MonthTotalCard from "./components/Cards/MonthTotalCard"
import TimeOffLeftCard from "./components/Cards/TimeOffLeftCard"
import FirstDayCard from "./components/Cards/FirstDayCard"
import LastDayCard from "./components/Cards/LastDayCard"
import EnglishLevelCard from "./components/Cards/EnglishLevelCard"
import RolesCard from "./components/Cards/RolesCard"
import ProjectsCard from "./components/Cards/ProjectsCard"
import EquipmentsCard from "./components/Cards/EquipmentsCard"
import UsefulInfoCard from "./components/Cards/UsefulInfoCard"
import UploadPhotoPopup from "./components/Popups/UploadPhotoPopup"
import UserAvatar from "./components/Other/UserAvatar"
import UserInfo from "./components/Other/UserInfo"
import UserMenu from "./components/Other/CustomMenuItem"
import useCoinsBalance from "../../hooks/useCoinsBalance"


const Profile = ({match}) => {
    const isMyProfile = match.params?.id === undefined
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})
    const history = useCustomHistory()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [image, setImage] = useState(null)
    const [showCropImagePopup, setShowCropImagePopup] = useState(false)
    const [showDefaultImagePopup, setShowDefaultImagePopup] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [showDeleteUserPopup, setShowDeleteUserPopup] = useState(false)
    const [showArchiveUserPopup, setShowArchiveUserPopup] = useState(false)
    const [showEditUserPopup, setShowEditUserPopup] = useState(false)
    const [showLogoutPopup, setShowLogoutPopup] = useState(false)
    const [showWelcomePopup, setShowWelcomePopup] = useState(false)
    const [userMessage, setUserMessage] = useState(null)
    const [editImageButtonEnabled, setEditImageButtonEnabled] = useState(false)

    const auth = useSelector((state) => state.auth)
    const userViewedAs = getUserViewedAs()
    const user = useSelector((state) => isMyProfile ? state.profile.myInfo : state.profile.currentUser)
    const userId = useMemo(() => userViewedAs ? userViewedAs.user_id : isMyProfile ? auth.user.uid : match.params.id, [auth, user, isMyProfile, userViewedAs])

    const userInfo = useSelector((state) => state.profile.myInfo)
    const {hasPermission} = useSelector((state) => state.profile)
    const userOnboarding = useSelector((state) => state.profile.userOnboarding)
    const { balance } = useCoinsBalance({ userId })
    const newcoinsEnabled = useSelector((state) => state.settings.newcoinsEnabled)
    const [onboarding, setOnboarding] = useState(userOnboarding)

    const hasWriteUserPermission = isMyProfile
        ? hasPermission(PERMISSIONS.WRITE_OWN_USER)
        : hasPermission(PERMISSIONS.WRITE_USER)

    const readOnboardingPermission = isMyProfile
        ? hasPermission(PERMISSIONS.READ_OWN_ONBOARDING)
        : hasPermission(PERMISSIONS.READ_ONBOARDING)

    const readNewcoinsPermission = isMyProfile
        ? hasPermission(PERMISSIONS.READ_OWN_NEWCOINS)
        : hasPermission(PERMISSIONS.READ_NEWCOINS)

    const receiveUserInfo = async (id, isMyProfile, needUpdateUser) => {
        if(isMyProfile && (!user || needUpdateUser)){
            return dispatch(receiveMyInfo())
        } else {
            if (isMyProfile && user){
                return new Promise((resolve) => {
                    resolve("User info is already fetched")
                })
            } else {
                return dispatch(receiveCurrentUser(id))
            }
        }
    }

    useEffect(() => {
        if (auth.user) {
            let id = userId
            if (getUserViewedAs()) {
                id = getUserViewedAs().user_id
            }

            Promise.all([
                receiveUserInfo(id, isMyProfile),
                readOnboardingPermission && dispatch(receiveUserOnboarding(id)),
                readNewcoinsPermission && isTrue(newcoinsEnabled.value)
            ])
                .then(() => {
                    setLoading(false)
                })
                .catch(() => {
                    history.push(ErrorRoute.path,{errorInfo: "Not found"})
                })
        }
    }, [auth.user, userId, match.params.id, userInfo])

    useEffect(() => {
        setOnboarding(userOnboarding)
    }, [userOnboarding])


    return loading ? <Loading/> : (
        <div>
            <Helmet>
                <title>Profile - Newsoft Inside</title>
            </Helmet>
            <div className="profile">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message}
                            code={userMessage.code} type={userMessage?.type} duration={userMessage?.duration}/>
                    }
                </div>
                <div className="profile-wrapper">
                    <div className="profile-user">
                        <UserAvatar user={user} hasWriteUserPermission={hasWriteUserPermission} editImageButtonEnabled={editImageButtonEnabled} setEditImageButtonEnabled={setEditImageButtonEnabled} setShowCropImagePopup={setShowCropImagePopup} setShowDefaultImagePopup={setShowDefaultImagePopup} />
                        <UserInfo user={user} setUserMessage={setUserMessage}/>
                        <UserMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} user={user} history={history} isMyProfile={isMyProfile} hasPermission={hasPermission} setShowArchiveUserPopup={setShowArchiveUserPopup} setShowDeleteUserPopup={setShowDeleteUserPopup} setShowEditUserPopup={setShowEditUserPopup} setShowLogoutPopup={setShowLogoutPopup}/>
                    </div>
                    <div className="profile-info">
                        <Protected permissions={isMyProfile ? [PERMISSIONS.READ_OWN_REPORTS] : [PERMISSIONS.READ_REPORTS]}>
                            <MonthTotalCard user={user} isMyProfile={isMyProfile}/>
                        </Protected>

                        <Protected permissions={isMyProfile ? [PERMISSIONS.READ_OWN_TIME_OFF_BALANCE] : [PERMISSIONS.READ_TIME_OFF_BALANCE]}>
                            <TimeOffLeftCard user={user} />
                        </Protected>

                        <Protected permissions={isMyProfile ? [PERMISSIONS.READ_OWN_PERFORMANCE_REVIEW] : [PERMISSIONS.READ_PERFORMANCE_REVIEW]}>
                            <PerformanceReviewCard user={user}/>
                        </Protected>

                        <Protected permissions={isMyProfile ? [PERMISSIONS.READ_OWN_SALARY] : [PERMISSIONS.READ_SALARY]}>
                            <div className="profile-info-salary">
                                {user?.active_salaries?.length > 1
                                    ? <MultipleSalaryCard user={user} salaries={user.active_salaries}/>
                                    : <SalaryCard user={user} salary={user.active_salaries?.length === 1 && user.active_salaries[0]}/>
                                }
                            </div>
                        </Protected>

                        {(user.bonuses?.length !== 0 || hasPermission(PERMISSIONS.READ_BONUS)) && (
                            <Protected permissions={isMyProfile ? [PERMISSIONS.READ_OWN_BONUS] : [PERMISSIONS.READ_BONUS]}>
                                <BonusesCard user={user} bonuses={user.bonuses}/>
                            </Protected>
                        )}

                        <Protected permissions={isMyProfile ? [PERMISSIONS.READ_OWN_NEWCOINS] : [PERMISSIONS.READ_NEWCOINS]}>
                            <NewcoinsCard user={user} newcoins={balance}/>
                        </Protected>

                        <FirstDayCard user={user} isMyProfile={isMyProfile} setUserMessage={setUserMessage} receiveUserInfo={receiveUserInfo}/>

                        <LastDayCard user={user} isMyProfile={isMyProfile} setUserMessage={setUserMessage} receiveUserInfo={receiveUserInfo}/>

                        <Protected permissions={isMyProfile ? [PERMISSIONS.READ_OWN_ENGLISH_LEVEL] : [PERMISSIONS.READ_ENGLISH_LEVEL]}>
                            <EnglishLevelCard user={user} isMyProfile={isMyProfile} setUserMessage={setUserMessage} receiveUserInfo={receiveUserInfo}/>
                        </Protected>
                    </div>
                </div>

                <div className="profile-right">
                    {onboarding && onboarding?.onboarding_status !== ONBOARDING_STATUS.APPROVED ?
                        <OnboardingCard
                            onboarding={onboarding}
                            setOnboarding={setOnboarding}
                            isMyProfile={isMyProfile}
                            setUserMessage={setUserMessage}
                            setShowWelcomePopup={setShowWelcomePopup}
                        /> :
                        <UsefulInfoCard />
                    }

                    <Protected permissions={isMyProfile ? [PERMISSIONS.READ_OWN_ROLE] : [PERMISSIONS.READ_ROLE]}>
                        <RolesCard user={user} isMyProfile={isMyProfile} setUserMessage={setUserMessage} receiveUserInfo={receiveUserInfo}/>
                    </Protected>

                    <Protected permissions={isMyProfile ? [PERMISSIONS.READ_OWN_PROJECT] : [PERMISSIONS.READ_PROJECT]}>
                        <ProjectsCard user={user} setUserMessage={setUserMessage} />
                    </Protected>

                    <Protected permissions={isMyProfile ? [PERMISSIONS.READ_OWN_EQUIPMENT] : [PERMISSIONS.READ_EQUIPMENT]}>
                        <EquipmentsCard user={user} setUserMessage={setUserMessage}/>
                    </Protected>
                </div>
            </div>

            <Popup open={showCropImagePopup} onClose={() => {
                setShowCropImagePopup(false)
                setImage(null)
            }} modal>
                {close => 
                    <CropImagePopup
                        user_id={user.id}
                        receiveUserInfo={() => receiveUserInfo(user.id, isMyProfile, true)} 
                        image={image ? image : user.image_url}
                        setUserMessage={setUserMessage}
                        close={close} 
                    />
                }
            </Popup>

            <Popup open={showDefaultImagePopup} onClose={() => {
                setShowDefaultImagePopup(false)
                setImage(null)
            }} modal>
                {close =>
                    <UploadPhotoPopup
                        hasWriteUserPermission={hasWriteUserPermission}
                        editImageButtonEnabled={editImageButtonEnabled}
                        setShowCropImagePopup={setShowCropImagePopup}
                        setImage={setImage}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                }
            </Popup>

            <Popup open={showArchiveUserPopup} onClose={() => setShowArchiveUserPopup(false)} modal>
                {close => (<ArchiveEmployeePopup employee={user} setUserMessage={setUserMessage} close={close}/>)}
            </Popup>

            <Popup open={showDeleteUserPopup} onClose={() => setShowDeleteUserPopup(false)} modal>
                {close => (<DeleteEmployeePopup employee={user} setUserMessage={setUserMessage} close={close}/>)}
            </Popup>

            <Popup open={showEditUserPopup} closeOnDocumentClick={false} onClose={() => setShowEditUserPopup(false)} modal>
                {close => (
                    <EditEmployeePopup
                        employee={user}
                        setUserMessage={setUserMessage}
                        receiveUserInfo={() => receiveUserInfo(user.id, isMyProfile, true)}
                        close={close}
                    />)}
            </Popup>

            <Popup open={showLogoutPopup} onClose={() => setShowLogoutPopup(false)} modal>
                {close => (
                    <LogoutPopup close={close}/>
                )}                    
            </Popup>

            <Popup open={showWelcomePopup && isDesktop} onClose={() => setShowWelcomePopup(false)} modal>
                {close => (
                    <WelcomePopup
                        close={close}
                        firstName={user.first_name}
                    />)
                }
            </Popup>
        </div>)
}

export default Profile
