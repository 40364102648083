import React, {useEffect} from "react"

import Table from "../../../components/Table/Table"
import {usePaginatedQuery} from "../../../hooks/common/usePaginatedQuery"
import {useGetAuditLogsQuery} from "../../../api/auditLogs"
import AuditLogsListSidePanel from "./AuditLogsListSidePanel"


const getSource = (log) => {
    return log.user_name || log.user_id || "System"
}


const getAction = (log) => {
    return {
        created: "Created",
        updated: "Updated",
        archived: "Archived",
        deactivated: "Deactivated",
        claimed: "Claimed",
        assigned: "Assigned",
        unassigned: "Unassigned",
        approved: "Approved",
        declined: "Declined",
        cancelled: "Cancelled",
        submitted: "Submitted",
        completed: "Completed",
        deleted: "Deleted",
    }[log.event_type]
}

const getObject = (log) => {
    return {
        employee: "Employee",
        salary: "Salary",
        employee_position: "Employee Position",
        salary_overtime: "Overtime",
        bonus: "Bonus",
        performance_review: "Performance Review",
        time_record: "Time Record",
        time_off_request: "Time Off Requests",
        role: "Role",
        project: "Project",
        client: "Client",
        equipment: "Equipment",
        equipment_category: "Equipment Category",
        shop_order: "Shop Order",
        shop_product: "Shop Product",
        coupon: "Coupon",
        coins: "Coins",
        calendar: "Calendar",
        national_dayoffs: "National Day-off",
        global_settings: "Global Settings",
        user_settings: "User Settings",

    }[log.object_type]
}


export const AuditLogsList = ({ startDate, endDate, setUserMessage }) => {
    const { items: auditLogs, loading, hasMore, loadNextPage, changeParams } = usePaginatedQuery(
        useGetAuditLogsQuery,
        {
            defaultState: {
                params: {start_date: startDate, end_date: endDate}
            }
        }
    )

    useEffect(() => {
        changeParams({ start_date: startDate, end_date: endDate })
    }, [startDate, endDate])

    const columns = [
        {
            field: "id",
            hidden: true,
            isKey: true,
        },
        {
            field: "date",
            title: "Date",
            type: "datetime",
        },
        {
            field: "source",
            title: "Source",
            get: getSource,
        },
        {
            field: "event_type",
            title: "Action",
            get: getAction,
        },
        {
            field: "object_type",
            title: "Object",
            get: getObject,
        },
        {
            field: "object_id",
            title: "Object ID",
            type: "copy",
        },
        {
            field: "event",
            get: (rec) => JSON.stringify(rec.event),
            title: "Details",
            type: "copy",
        },
    ]

    return <Table
        items={auditLogs}
        columns={columns}
        loading={loading}
        hasMore={hasMore}
        loadNextPage={loadNextPage}
        setUserMessage={setUserMessage}
        variant="small"
        SidePanelComponent={AuditLogsListSidePanel}
    />
}

export default AuditLogsList
