import React, {useEffect} from "react"
import {Helmet} from "react-helmet"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import SearchForm from "../../toolkits/SearchForm/SearchForm"
import InfiniteScroll from "react-infinite-scroller"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {DESKTOP_WIDTH, PAGE_SIZE, PRODUCT_STATUS, PRODUCTS_SORT_OPTIONS} from "../../constants/other"
import {receiveNextPageProducts, receiveProducts} from "./actions/productsActions"
import "./index.scss"
import ProductsTable from "./components/ProductsTable"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import CustomLink from "../../toolkits/CustomLink"
import {CreateProductRoute} from "../../routes"
import {useMediaQuery} from "react-responsive"
import FilterPanel from "../../toolkits/FilterPanel"
import MultipleSelect from "../../toolkits/Multiselect"
import {receiveProductCategories} from "../CreateProduct/actions/createProductActions"
import DDFilter from "../../toolkits/DDFilter"

const Products = () => {
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1)
    const [sortBy, setSortBy] = useState(PRODUCTS_SORT_OPTIONS.NEWEST_FIRST)

    const {
        loading,
        tableLoading,
        products,
        total,
    } = useSelector((state) => state.products)

    const [searchKey, setSearchKey] = useState()
    const [userMessage, setUserMessage] = useState(null)
    const [statusFilter, setStatusFilter] = useState(PRODUCT_STATUS.ALL)
    const {categories} = useSelector((state) => state.createProduct)
    const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([])
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const getProducts = (page) => {
        page = page ? page : currentPage
        const params = {
            size: PAGE_SIZE,
            page,
            name: searchKey,
            status: statusFilter === PRODUCT_STATUS.ALL ? null : statusFilter,
            category_ids: selectedCategoriesIds.length > 0 ? selectedCategoriesIds.join(",") : null,
            order_by: sortBy.value,
        }

        dispatch(receiveProducts(params))
        setCurrentPage(page + 1)
    }

    const getNextProducts = () => {
        const params = {
            size: PAGE_SIZE,
            page: currentPage,
            name: searchKey,
            status: statusFilter === PRODUCT_STATUS.ALL ? null : statusFilter,
            category_ids: selectedCategoriesIds.length > 0 ? selectedCategoriesIds.join(",") : null,
            order_by: sortBy.value,
        }

        dispatch(receiveNextPageProducts(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        dispatch(receiveProductCategories())
        getProducts(1)
    }, [statusFilter, selectedCategoriesIds, sortBy])

    useEffect(() => {
        if (searchKey !== undefined) {
            getProducts(1)
        }
    }, [searchKey])

    return (
        <>
            <Helmet>
                <title> Products - Newsoft Inside </title>
            </Helmet>
            <div className="products">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className="products-header">
                    <div className="products-header-search">
                        <SearchForm onChange={setSearchKey} debounceMs={500} />
                        {isDesktop &&
                        <CustomLink to={{
                            pathname: CreateProductRoute.path,
                        }}>
                            <SmallButton>New Product</SmallButton>
                        </CustomLink>}
                    </div>
                    <div className="products-header-filters">
                        <FilterPanel
                            title={"Status"}
                            options={PRODUCT_STATUS}
                            selectedOption={statusFilter}
                            setOptionFilter={setStatusFilter}
                        />
                        <div className="products-header-filters-category">
                            <span className="t-s4 products-header-filters-category-title">Category:</span>
                            <MultipleSelect
                                loading={loading}
                                placeholder={"All"}
                                options={categories}
                                selectedOptionIds={selectedCategoriesIds}
                                setSelectedOptionIds={setSelectedCategoriesIds}
                            />
                        </div>
                        <div className="products-header-filters-sort">
                            <DDFilter
                                title={"Sort by"}
                                defaultValue={{label: sortBy.label, value: sortBy.value}}
                                options={(Object.values(PRODUCTS_SORT_OPTIONS)).map((o) => ({label: o.label, value: o.value}))}
                                onChange={(newSortBy) => setSortBy(newSortBy)}
                            />
                        </div>
                    </div>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={getNextProducts}
                    hasMore={!tableLoading && products.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    datalength={products.length}
                >
                    {loading ? <StyledCircularProgress/> :
                        <div className="products-content">
                            <ProductsTable
                                products={products}
                                setUserMessage={setUserMessage}
                                refreshTable={() => getProducts(1)}
                                isDesktop={isDesktop}
                            />
                        </div>
                    }
                </InfiniteScroll>
            </div>
        </>
    )
}

export default Products