import {getEquipment} from "../api"

import {
    GET_EQUIPMENT_REQUEST,
    GET_EQUIPMENT_SUCCESS,
    GET_EQUIPMENT_FAILURE,
    GET_NEXT_PAGE_EQUIPMENT_REQUEST,
} from "../const"


const request = () => ({type: GET_EQUIPMENT_REQUEST})

const nextPageRequest = () => ({type: GET_NEXT_PAGE_EQUIPMENT_REQUEST})

const success = (equipment) => ({
    type: GET_EQUIPMENT_SUCCESS,
    payload: {equipment},
})

const failure = () => ({type: GET_EQUIPMENT_FAILURE})


export const receiveEquipment = (params, cancelToken) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveEquipmentData} = await getEquipment(params, cancelToken)

        dispatch(success(receiveEquipmentData))
    } catch (e) {
        dispatch(failure(e))
    }
}

export const receiveNextPageEquipment = (params) => async (dispatch) => {
    dispatch(nextPageRequest())
    try {
        const {data: receiveNextPageEquipmentData} = await getEquipment(params)
        dispatch(success(receiveNextPageEquipmentData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
