import "./AuditLogsListSidePanel.scss"

import React from "react"


export const AuditLogsListSidePanel = ({ row }) => {
    return (
        <pre className="audit-logs-side-panel">
            {JSON.stringify(row.event, null, 2)}
        </pre>
    )
}

export default AuditLogsListSidePanel
