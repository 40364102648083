import {createApi} from "@reduxjs/toolkit/query/react"
import {baseQuery, DEFAULT_REFETCH_ON_MOUNT_INTERVAL} from "./base"


export const usersApi = createApi({
    reducerPath: "usersApi",
    baseQuery,
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    tagTypes: ["Users"],
    endpoints: (builder) => ({
        getUsersList: builder.query({
            query: (params) => ({
                path: "users/flat",
                params,
            }),
            providesTags: ["Users"],
        }),

    })
})

export const {
    useGetUsersListQuery,
} = usersApi
