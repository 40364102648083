import "./index.scss"

import React from "react"
import {Helmet} from "react-helmet"

import {Redirect, useHistory, useLocation} from "react-router-dom"

import NSLogoImage from "../../assets/images/logo_newsoft.svg"
import {ProfileRoute} from "../../routes"
import SmallButton from "../../toolkits/SmallButton/SmallButton"


const Error = () => {
    const location = useLocation()
    const history = useHistory()

    return (location.state ?
        <div className="error">
            <Helmet>
                <title> {location.state.errorInfo} </title>
            </Helmet>
            <img src={NSLogoImage} alt="Newsoft logo"/>
            <div className="error-info">
                <p className="t-h1">{location.state.errorInfo}</p>
            </div>
            <div className="error-return-button">
                <SmallButton
                    type="submit"
                    onClick={() => history.push("/")}
                >Go To Profile</SmallButton>
            </div>
        </div> : <Redirect to={{pathname: ProfileRoute.path}}/>
    )
}

export default Error
