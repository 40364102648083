import React from "react"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"
import {useDeleteEquipmentMutation} from "../../../api/equipment"


const DeleteEquipmentPopup = ({equipment, refreshTable, setUserMessage, close}) => {
    const [deleteEquipment] = useDeleteEquipmentMutation()

    const handleSubmit = () => {
        deleteEquipment(equipment.id)
            .unwrap()
            .then(() => {
                refreshTable()
                setUserMessage({message: "Equipment was successfully deleted!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to delete "${equipment.name}"?`} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteEquipmentPopup
