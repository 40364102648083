import UserImage from "../../../assets/images/user_photo.png"

import React from "react"
import Popup from "reactjs-popup"
import {useSelector} from "react-redux"

import EmployeeInfoPopup from "./EmployeeInfoPopup"

import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"

import {formatDate} from "../../../utils/datetime.utils"

import TelegramButton from "../../../toolkits/TelegramButton"
import CopyButton from "../../../components/Miscellaneous/CopyButton"
import {PERMISSIONS, USER_PROFILE_PERMISSIONS} from "../../../constants/permissions"
import {DD_MM_YYYY} from "../../../constants/other"

/**
 * @desc Cpmponent "Employees List".
 * @return {JSX.Element} - component for rendering a list with employees.
 */
const EmployeesList = ({users, profileClick, setUserMessage}) => {
    const {hasPermission} = useSelector((state) => state.profile)

    const extAccess = () => USER_PROFILE_PERMISSIONS.some((permission) => hasPermission(permission))

    /**
     * @desc [Variable] Variable that contain array of table elements with employees data.
     * @return {Array}
     */
    const usersList = users.map(user => {
        const triggerElements = (
            <td className="clickable" onClick={() => extAccess() && profileClick(user.id)}>
                <div className="custom-scrollable-table-employee">
                    <span className="custom-scrollable-table-employee-image">
                        <img src={user.image_url ? user.image_url : UserImage} alt="employee"/>
                    </span>
                    <span className="custom-scrollable-table-employee-name">
                        {user.first_name} {user.last_name}
                    </span>
                </div>
            </td>
        )

        const result = (
            <tr key={user.id} className="custom-scrollable-table-row-user">
                {extAccess() ? triggerElements :
                    <Popup trigger={triggerElements} key={user.id} modal>
                        {close => (
                            <EmployeeInfoPopup close={close} employee={user} setUserMessage={setUserMessage}/>
                        )}
                    </Popup>
                }
                <td>{user.job_position?.name}</td>
                <td>
                    <span className="aligned-tabs">
                        {formatDate(user.birthdate, !hasPermission(PERMISSIONS.WRITE_USER) ? "DD MMM" : DD_MM_YYYY)}
                    </span>
                </td>
                <td>
                    <span className="aligned-tabs">{user.phone_number}</span>
                    <TelegramButton phone_number={user.phone_number} color={"#5F6C72"}/>
                </td>
                <CopyButton value={user.email} type="Email" setUserMessage={setUserMessage}>
                    <td className="onboarding-label-td clickable">{user.email}
                        <CopyButton value={user.email} type="Email" setUserMessage={setUserMessage}/>
                        {user.active_onboarding && hasPermission(PERMISSIONS.READ_ONBOARDING) &&
                            <span className="onboarding-label t-b3">onboarding</span>
                        }
                    </td>
                </CopyButton>
            </tr>
        )

        return result 
    })

    return (
        <CustomScrollableTable>
            <table className="employees-content-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span-first">EMPLOYEE</span></th>
                        <th><span className="custom-scrollable-table-head-span">POSITION</span></th>
                        <th><span className="custom-scrollable-table-head-span">BIRTH DATE</span></th>
                        <th><span className="custom-scrollable-table-head-span">PHONE</span></th>
                        <th><span className="custom-scrollable-table-head-span">EMAIL</span></th>
                    </tr>
                </thead>
                <tbody>
                    {usersList.length > 0 ? usersList : <EmptyResult/>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default EmployeesList