/**
 * @desc Action types for handle employee reducer.
 * @const
 * @type {string}
 * @default
 */
export const GET_EMPLOYEES_REQUEST = "GET_EMPLOYEES_REQUEST"
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS"
export const GET_EMPLOYEES_FAILURE = "GET_EMPLOYEES_FAILURE"
export const GET_NEXT_PAGE_EMPLOYEES_REQUEST = "GET_NEXT_PAGE_EMPLOYEES_REQUEST"
