import React from "react"
import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import {useDeleteProductMutation} from "../../../api/products"

const DeleteProductPopup = ({product, refreshTable, setUserMessage, close}) => {
    const [deleteProduct] = useDeleteProductMutation()
    // DELETE https://api.dev.inside.newsoft-ns.com/api/v1/products/157
    const handleSubmit = () => {
        deleteProduct({ productId: product.id })
            .unwrap()
            .then(() => {
                refreshTable()
                setUserMessage({message: "Product was successfully deleted!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to delete ${product.name}?`} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteProductPopup