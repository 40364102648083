import {
    GET_ONBOARDING_REQUESTS_REQUEST,
    GET_ONBOARDING_REQUESTS_SUCCESS,
    GET_ONBOARDING_REQUESTS_FAILURE,
    GET_NEXT_PAGE_ONBOARDING_REQUESTS_REQUEST,
} from "../const"

import {
    getOnboardingRequests
} from "../api"


const requestPaging = () => ({ type: GET_NEXT_PAGE_ONBOARDING_REQUESTS_REQUEST })

const requestAll = () => ({ type: GET_ONBOARDING_REQUESTS_REQUEST })

const success = (onboardingRequests) => ({
    type: GET_ONBOARDING_REQUESTS_SUCCESS,
    payload: {onboardingRequests},
})

const failure = () => ({ type: GET_ONBOARDING_REQUESTS_FAILURE })


export const receiveOnboardingRequests = (params) => async (dispatch) => {
    dispatch(requestAll())
    try {
        const {data: receiveOnboardingRequestsData} = await getOnboardingRequests(params)

        dispatch(success(receiveOnboardingRequestsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageOnboardingRequests = (params) => async (dispatch) => {
    dispatch(requestPaging())
    try {
        const {data: receiveOnboardingRequestsData} = await getOnboardingRequests(params)

        dispatch(success(receiveOnboardingRequestsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

