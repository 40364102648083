import React, {useContext, useState} from "react"
import Protected from "../../../../components/Protected/Protected"
import {PERMISSIONS} from "../../../../constants/permissions"
import Popup from "reactjs-popup"
import UpdateRolesPopup from "../Popups/UpdateRolesPopup"
import PenIcon from "@mui/icons-material/Create"
import {useSelector} from "react-redux"
import {ThemeContext} from "../../../../styles/theme-context"

const RolesCard = ({user, isMyProfile, setUserMessage, receiveUserInfo}) => {
    const [showUpdateRolesPopup, setShowUpdateRolesPopup] = useState(false)
    const {hasPermission} = useSelector((state) => state.profile)
    const theme = useContext(ThemeContext)

    return (
        <>
            <div className={`profile-right-roles ${hasPermission(PERMISSIONS.READ_ROLE) ? "clickable" : ""} ${theme.profileCardHover}`}
                onClick={() => setShowUpdateRolesPopup(true)}>
                <span className="t-h3 profile-font-leading">Roles</span>
                <div className="t-h3 profile-right-roles-content">
                    {user.roles.map((x) => x.role_name.toUpperCase()).join(", ")}
                </div>

                <div className="profile-info-card-links">
                    <Protected permissions={[PERMISSIONS.WRITE_ROLE]}>
                        <PenIcon onClick={() => setShowUpdateRolesPopup(true)} className="profile-info-card-pen-icon clickable"/>
                    </Protected>
                </div>
            </div>

            <Popup open={showUpdateRolesPopup} closeOnDocumentClick={false} onClose={() => setShowUpdateRolesPopup(false)} modal>
                {close =>
                    <UpdateRolesPopup
                        user={user}
                        receiveUserInfo={() => receiveUserInfo(user.id, isMyProfile, true)}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                }
            </Popup>
        </>
    )
}

export default RolesCard