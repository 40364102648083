import React from "react"
import Popup from "reactjs-popup"

import Protected from "../../../components/Protected/Protected"
import InfoPopup from "../../../components/InfoPopup"
import EmployeesBoxPopup from "../../../components/EmployeesBoxPopup"
import CreateEditRolePopup from "./CreateEditRolePopup"
import DeleteRolePopup from "./DeleteRolePopup"
import UserRolePopup from "./UserRolePopup"

import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"


import {PERMISSIONS} from "../../../constants/permissions"


const RolesTable = ({data, setUserMessage, refreshTable}) => {
    const rolesList = data.map((role, i) => {
        const rolePermissions = role.permissions.map((perm) => perm.permission_name).join(", ")
        const roleUsers = role.users.map((user) => `${user.first_name} ${user.last_name}`).join(", ")

        return (
            <tr key={`${i} ${role.id}`} className="custom-scrollable-table-row">
                <td>
                    <Popup trigger={
                        <span className="cell-limited-text t-s1 clickable">{role.role_name}</span>
                    } modal>
                        {close => (
                            <InfoPopup
                                title="Role"
                                value={role.role_name}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                <td>
                    <Popup trigger={
                        <span className="clickable">{roleUsers}</span>
                    } modal>
                        {close => (
                            <EmployeesBoxPopup
                                title={`${role.role_name.toUpperCase()} Role`}
                                label="Assigned employees"
                                employees={role.users}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                <td>
                    <Popup trigger={<span
                        className="clickable">{rolePermissions}</span>}
                    modal>
                        {close => (
                            <UserRolePopup
                                role={role}
                                permissions={true}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                <td>
                    <Protected permissions={[PERMISSIONS.WRITE_ROLE]}>
                        <Popup trigger={<span className="custom-scrollable-table-link">copy as draft</span>}
                            closeOnDocumentClick={false} modal>
                            {close => (
                                <CreateEditRolePopup
                                    create={true}
                                    role={role}
                                    setUserMessage={setUserMessage}
                                    refreshTable={refreshTable}
                                    close={close}
                                />
                            )}
                        </Popup>
                        <Popup trigger={<span className="custom-scrollable-table-link">edit</span>}
                            closeOnDocumentClick={false} modal>
                            {close => (
                                <CreateEditRolePopup
                                    role={role}
                                    setUserMessage={setUserMessage}
                                    refreshTable={refreshTable}
                                    close={close}
                                />
                            )}
                        </Popup>

                        <Popup trigger={<span className="custom-scrollable-table-link">delete</span>} modal>
                            {close => (
                                <DeleteRolePopup
                                    role={role}
                                    setUserMessage={setUserMessage}
                                    refreshTable={refreshTable}
                                    close={close}
                                />
                            )}
                        </Popup>
                    </Protected>
                </td>
            </tr>
        )
    })

    return (
        <CustomScrollableTable>
            <table className="roles-content-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span">ROLE</span></th>
                        <th><span className="custom-scrollable-table-head-span">ASSIGN EMPLOYEES</span></th>
                        <th><span className="custom-scrollable-table-head-span">PERMISSIONS</span></th>
                        <Protected permissions={[PERMISSIONS.WRITE_ROLE]}>
                            <th><span className="custom-scrollable-table-head-span">ACTIONS</span></th>
                        </Protected>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? rolesList : <EmptyResult/>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default RolesTable
