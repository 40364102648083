import {
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAILURE,
    GET_PRODUCTS_REQUEST,
    GET_NEXT_PAGE_PRODUCTS_REQUEST,
} from "../const"


const initialState = {
    products: [],
    total: 0,
    tableLoading: true,
    loading: true,
}

const handlers = {
    [GET_NEXT_PAGE_PRODUCTS_REQUEST]: (state) => ({...state, tableLoading: true}),
    [GET_PRODUCTS_SUCCESS]: (state, {payload: {products}}) => ({
        ...state,
        total: products.total,
        products: [...state.products, ...products.items],
        tableLoading: false,
        loading: false,
    }),
    [GET_PRODUCTS_FAILURE]: (state) => ({...state, tableLoading: false, loading: false}),
    [GET_PRODUCTS_REQUEST]: (state) => ({...state, loading: true, products: [], total: 0}),
    DEFAULT: (state) => state,
}


const productsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default productsReducer
